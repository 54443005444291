import { createAction, handleActions } from 'redux-actions';
import { ApiResellerObject } from '../api/resellers';

export interface ResellerState {
    reseller?: ApiResellerObject,
};

export const setResellerStyle = (reseller: ApiResellerObject) => {
    document.body.style.setProperty('--primary-color', reseller.primary_color);
    document.body.style.setProperty('--secondary-color', reseller.secondary_color);
    document.body.style.setProperty('--secondary-opposite-color', reseller.secondary_opposite_color);
}

export const setReseller = createAction('setReseller');

export default handleActions<ResellerState>({
    setReseller: (state, data: any) => {
        setResellerStyle(data.payload);
        return {
            ...state,
            reseller: data.payload
        }
    },
}, {
    reseller: undefined,
});
