import { AxiosPromise } from 'axios';
import { each } from 'lodash';
import { request } from './index';
import { FilterObject } from '../components/AdvancedTable';

export interface ApiDebtorObject {
    id: number,
    number: string,
    name: string,
    address?: string,
    zipcode?: string,
    city?: string,
    country?: string,
    comment?: string,
    contact_name?: string,
    contact_telephone_number?: string,
    contact_email_address?: string,
    collect: boolean,
    use_courant: boolean,
    status?: string,
    customer_owner?: string,
    kvk_number?: string,

    open_invoices?: number,
    invoices_expired?: number,
    dso?: number,
    invoices_sum?: number,
    credit_limit?: number,
    template_id?: number,
    template?: string,
    subscription?: string,
    subscription_expired?: boolean,
}

export default {
    delete: (id: number | string): AxiosPromise => {
        return request.delete(`debtors/${id}`);
    },
    list: (filter?: Partial<FilterObject>, include?: string[], columnFilters?: any): AxiosPromise => {
        const query: any = [];

        if (filter) {
            each(filter, (v, k) => {
                query.push(`${k}=${v}`);
            });
        }

        let cf = '';
        if (columnFilters && columnFilters.collect && columnFilters.collect.length > 0) {
            cf += `&collect=${columnFilters.collect.join(',')}`;
        }
        if (columnFilters && columnFilters.credit_limit && columnFilters.credit_limit.length > 0) {
            cf += `&r9=${columnFilters.credit_limit.join(',')}`;
        }
        
        return request.get(`debtors?${query.join('&')}${include ? `&with=${include.join(',')}` : ''}${cf}`);
    },
    store: (data: any): AxiosPromise<ApiDebtorObject> => {
        return request.post(`debtors`, data);
    },
    patch: (id: number | string, data: any): AxiosPromise<ApiDebtorObject> => {
        return request.patch(`debtors/${id}`, data);
    },
    show: (id: number | string): AxiosPromise<ApiDebtorObject> => {
        return request.get(`debtors/${id}`);
    },
};
