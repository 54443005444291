import { AxiosPromise } from 'axios';
import { request } from './index';
import { ApiDebtorObject } from './debtors';
import { ApiInvoiceObject } from './invoices';
import { ApiInvoiceStatusObject } from './invoicestatuses';

export interface ApiRiMatchObject {
    id: number,
    customer_id: number,
    debtor_id: number,
    debtor: ApiDebtorObject,
    invoice_id: number,
    invoice: ApiInvoiceObject,
    saldo: number,
    scenario: number,
    prev_status?: ApiInvoiceStatusObject,
}

export default {
    list: (scenario?: number): AxiosPromise => {
        return request.get(`ri-matches?scenario=${scenario || ''}`);
    },
};
