import axios from 'axios';
import actionslist from './actionslist';
import auth from './auth';
import contacts from './contacts';
import contracts from './contracts';
import customers from './customers';
import debtors from './debtors';
import invoices from './invoices';
import status from './invoicestatuses';
import resellers from './resellers';
import rimatch from './rimatch';
import templates from './templates';
import users from './users';
import config from '../config.json';
import { redirect } from '../lib/utils';

export const getToken = () => {
    if (localStorage.getItem('token')) {
        const token = localStorage.getItem('token');
        
        if (token) {
            const access = JSON.parse(token);
            return `${access.access_token}:${access.api_key}`;
        }
    }
    
    return null;
}

axios.defaults.baseURL = config.baseUrl;
axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
};

axios.interceptors.request.use((config) => {
    if (localStorage.getItem('token')) {
        const token = localStorage.getItem('token');
        
        if (token) {
            const access = JSON.parse(token);
            config.headers.Authorization = `${access.access_token}:${access.api_key}`;
        }
    }

    const lang = localStorage.getItem('lang');
    config.headers['X-Language'] = lang;

    return config;
});

axios.interceptors.response.use((response: any) => {
    if (response.headers['x-refresh'] === 'now') {
        window.location.reload();
    }
    
    return response;
}, (error: any) => {
    if (window.location.href.indexOf('local') === -1 && error.message.indexOf(500) !== -1) {
        window.location.href = redirect('/error');
    }

    return Promise.reject(error);
});

export interface ApiPaginatedResponse {
    data: any,
    meta: any,
    links: any,
}

export const request = axios;

const api = {
    listActionsList: actionslist.list,
    updateActionsList: actionslist.update,
    updateFlexTemplates: actionslist.updateFlexTemplates,

    getAuth: auth.get,
    login: auth.login,
    resetPassword: auth.resetPassword,
    setNewPassword: auth.setNewPassword,
    setSelectedCustomer: auth.setSelectedCustomer,
    superLogin: auth.superLogin,
    verifySecretCode: auth.verify,
    
    deleteContract: contracts.delete,
    listContracts: contracts.list,
    patchContract: contracts.patch,
    showContract: contracts.show,
    storeContract: contracts.store,

    deleteContact: contacts.delete,
    listContacts: contacts.list,
    patchContact: contacts.patch,
    showContact: contacts.show,
    storeContact: contacts.store,

    deleteCustomer: customers.delete,
    newCustomerNumber: customers.getNewNumber,
    listCustomers: customers.list,
    patchCustomer: customers.patch,
    showCustomer: customers.show,
    storeCustomer: customers.store,
    addCustomerTimeline: customers.addTimeline,
    listCustomerTimeline: customers.listTimeline,

    deleteDebtor: debtors.delete,
    listDebtors: debtors.list,
    patchDebtor: debtors.patch,
    showDebtor: debtors.show,
    storeDebtor: debtors.store,

    listRiMatches: rimatch.list,

    addInvoiceNote: invoices.addNote,
    deleteInvoice: invoices.delete,
    listInvoices: invoices.list,
    getInvoiceIds: invoices.getInvoiceIds,
    listInvoiceDownloads: invoices.listDownloads,
    patchInvoice: invoices.patch,
    showInvoice: invoices.show,
    storeInvoice: invoices.store,
    deleteTimeline: invoices.deleteTimeline,
    updateInvoiceStatuses: invoices.updateStatuses,
    updateInvoiceFlex: invoices.updateFlex,

    debtorNote: invoices.debtorNote,
    listStatuses: status.list,

    getResellerByDomain: resellers.getByDomain,
    deleteReseller: resellers.delete,
    listResellers: resellers.list,
    patchReseller: resellers.patch,
    showReseller: resellers.show,
    storeReseller: resellers.store,

    deleteUser: users.delete,
    listUsers: users.list,
    patchUser: users.patch,
    sendUserInvite: users.sendInvite,
    showUser: users.show,
    storeUser: users.store,

    deleteTemplate: templates.delete,
    listTemplates: templates.list,
    patchTemplate: templates.patch,
    storeTemplate: templates.store,
    showTemplate: templates.show,
};

export default api;
