import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, Segment, Icon, SemanticICONS } from 'semantic-ui-react';
import styles from '../Timeline/styles.module.scss';
import { ApiInvoiceJournalObject } from '../../api/invoices';
import { ApiAuthObject } from '../../api/auth';
import { AppState } from '../../store';
import { money } from '../../lib/utils';

interface JournalProps {
    auth?: ApiAuthObject,
    data: ApiInvoiceJournalObject[],
}

interface JournalState {
    confirm: any
}

class Timeline extends React.Component<JournalProps, JournalState> {
    constructor (props: JournalProps) {
        super(props);

        this.state = {
            confirm: false,
        };
    }

    // render
    render = () => {
        const { auth } = this.props;
        
        return (
            <div className={styles.timeline}>
                <div className={styles.timelineLine}></div>
                {this.props.data.map((timeline, i) => (
                    <Segment className={styles.timelineItem} key={`timeline-${i}`}>
                        <div className={styles.timelineHeader}>
                            <Icon name="payment" style={{ fontSize: 30 }} />
                            <div className={styles.timelineMeta}>
                                {timeline.user.full_name} | {moment(timeline.created_at).format('DD/MM/YYYY')}
                            </div>
                        </div>
                        {timeline.from === 0 ? (
                            <p>{timeline.label}: {money(timeline.to)}</p>
                        ) : (
                            <p>{timeline.label}: {money(timeline.from === timeline.to ? timeline.from : timeline.from - timeline.to)}</p>
                        )}
                    </Segment>
                ))}
            </div>
        );
    }
}

export default connect((state: AppState) => ({
    auth: state.auth.user,
}))(Timeline);
