import React from 'react';
import { Header, Segment, Form, Checkbox, Grid, Table } from 'semantic-ui-react';
import Toastify from 'toastify';
import { Editor } from '@tinymce/tinymce-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import api from '../../api';
import { ApiTemplateObject } from '../../api/templates';
import FloatingButtons from '../../components/FloatingButtons';
import PageHeader from '../../components/Layout/PageHeader';

const placeholders = [
    'Klantnaam',
    'Klantcontactpersoon',
    'Klantcontactpersoontelefoon',
    'Klantcontactpersoonemail',
    'Ibanrekeningnummer',
    'Klantnaambankrekening',
    'Biccode',
    'Debiteurnummer',
    'Debiteurnaam',
    'Debiteuradres',
    'Debiteurpostcode',
    'Debiteurstad',
    'Debiteurland',
    'Debiteurcontactpersoon',
    'Debiteurcontactpersoontelefoon',
    'Debiteurcontactpersoonemail',
    'Debiteurklanteigenaar',
    'Verzenddatum',
    'Factuurnummer',
    'Factuurdatum',
    'Factuurvervaldatum',
    'Factuurvaluta',
    'Hoofdsom',
    'Rente',
    'Incassokosten',
    'Totaalbedrag',
    'Totaalincasso',
    'Administratiekosten',
    'Totaaladministratie',
    'ROTabel',
    'GebruikerID',
    'CreditToevoeging',
    'CreditTabel',
]

interface TemplatesEditViewProps {
    customerId?: number,
    templateId?: number,
    isFlex?: boolean,
    onExit: () => void,
}

interface TemplatesEditViewState {
    template: ApiTemplateObject,
    isLoading: boolean,
    errors?: {
        [key: string]: any,
    },
}

class TemplatesEditView extends React.Component<TemplatesEditViewProps & WithTranslation, TemplatesEditViewState> {
    constructor(props: any) {
        super(props);
    
        this.state = {
            template: {
                id: 0,
                customer_id: 0,
                type: '',
                name: '',
                content: '',
                has_header: true,
                has_footer: true,
                is_flex: true,
                flex_type: 'debit',
            },
            isLoading: false,
            errors: undefined,
        };
    }

    /**
     * Mount
     */
    componentDidMount = () => {
        this.fetch();
    }

    /**
     * Copy value
     */
    copy = (str: string) => {
        const { t } = this.props;
        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        Toastify.success(t('templates.copied'))
    }

    /**
     * Fetch
     */
    fetch = () => {
        const { templateId, customerId } = this.props;
        
        if (templateId) {
            this.setState({ isLoading: true });
            api.showTemplate(templateId, customerId).then(({ data }) => {
                this.setState({
                    template: data,
                    isLoading: false,
                });
            });
        }
    }

    /**
     * Handle input
     */
    handleInput = (e: any, field: 'content' | 'content_en') => {
        const { template } = this.state;
        template[field] = e.target.getContent();
        this.setState({ template });
    }

    handleName = (e: any, { name, value }: { name: string, value: string }) => {
        const { template } = this.state;
        this.setState({ template: { ...template, [name]: value } });
    }

    /**
     * Save
     */
    save = (exit?: boolean) => {
        const { template } = this.state;
        this.setState({ isLoading: true, errors: undefined });

        if (!template.id) {
            api.storeTemplate(template, this.props.customerId)
                .then(({ data }) => this.success(data, exit))
                .catch(this.onError);
        } else {
            api.patchTemplate(template.id, template, this.props.customerId)
                .then(({ data }) => this.success(data, exit))
                .catch(this.onError);
        }
    }

    /**
     * Success
     */
    success = (data: ApiTemplateObject, exit?: boolean) => {
        const { t } = this.props;
        this.setState({
            template: data,
            isLoading: false,
        });
        Toastify.success(t('templates.saved'));

        if (exit) {
            this.props.onExit();
        }
    }

    /**
     * Error
     */
    onError = (error: any) => {
        const { t } = this.props;
        Toastify.error(t('templates.something_went_wrong'));

        this.setState({
            isLoading: false,
            errors: error.response.data.errors,
        });
    }

    // render
    render = () => {
        const { customerId, isFlex, t } = this.props;
        const { template, isLoading } = this.state;

        if (!template.id && !isFlex) {
            return null;
        }

        return (
            <div className="pageContainer">
                {!customerId && (
                    <PageHeader title={`${t('templates.edit')}: ${template.name}`} />
                )}
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={11}>
                            <Form onSubmit={() => this.save()}>
                                <Segment loading={isLoading}>
                                    {customerId && (
                                        <>
                                        {isFlex ? (
                                            <Header as="h3">
                                                {!template.id ? t('templates.new_flex') : `${t('templates.edit_flex')}: ${template.name}`}
                                            </Header>
                                        ) : (
                                            <Header as="h3">{t('templates.edit')}: {template.name}</Header>
                                        )}
                                        <Form.Group style={{ marginLeft: 0 }}>
                                            <Checkbox
                                                toggle
                                                name="has_header"
                                                label={t('templates.include_header')}
                                                checked={template.has_header}
                                                onChange={(e: any, data: any) => this.setState({
                                                    template: { ...template, has_header: data.checked },
                                                })}
                                            />
                                        </Form.Group>
                                        <Form.Group style={{ marginLeft: 0 }}>
                                            <Checkbox
                                                toggle
                                                name="has_footer"
                                                label={t('templates.include_footer')}
                                                checked={template.has_footer}
                                                onChange={(e: any, data: any) => this.setState({
                                                    template: { ...template, has_footer: data.checked },
                                                })}
                                            />
                                        </Form.Group>
                                        </>
                                    )}
                                    {customerId && isFlex && (<>
                                        <Form.Input
                                            name="name"
                                            required
                                            label={t('users.name')}
                                            value={template.name}
                                            onChange={(e, data: any) => this.handleName(e, data)}
                                        />
                                        <Form.Input
                                            name="subject"
                                            label={t('templates.subject')}
                                            value={template.subject}
                                            onChange={(e, data: any) => this.handleName(e, data)}
                                        />
                                        <Form.Input
                                            name="sender_name"
                                            label={t('templates.sender')}
                                            value={template.sender_name || ''}
                                            onChange={(e, data: any) => this.handleName(e, data)}
                                        />
                                        <Form.Input
                                            name="sender"
                                            label={t('templates.email_sender')}
                                            value={template.sender || ''}
                                            onChange={(e, data: any) => this.handleName(e, data)}
                                        />
                                        <Form.Select
                                            label={t('templates.sendtype')}
                                            options={[{
                                                key: `ftt-1`,
                                                value: 'debit',
                                                text: t('templates.debtors'),
                                            }, {
                                                key: `ftt-2`,
                                                value: 'incasso',
                                                text: t('templates.incasso'),
                                            }]}
                                            value={template.flex_type}
                                            onChange={(e: any, { value }: any) => this.setState({
                                                template: { ...template, flex_type: value },
                                            })}
                                        />
                                    </>)}
                                    <Editor
                                        apiKey="8vdxs5mwq7h7mt2ue991vtn94hdjv88w6q2gxydykw2yi3xl"
                                        // initialValue={template.content}
                                        value={template.content}
                                        init={{
                                            paste_as_text: true,
                                            height: 400,
                                            plugins: 'link image code paste',
                                            toolbar: 'code | undo redo | bold italic underline | bullist numlist | alignleft aligncenter alignright alignjustify | link unlink ',
                                        }}
                                        onChange={(e) => this.handleInput(e, 'content')}
                                    />
                                </Segment>
                                <Segment loading={isLoading}>
                                    <Header as="h3">Engelse vertaling</Header>
                                    <Editor
                                        apiKey="8vdxs5mwq7h7mt2ue991vtn94hdjv88w6q2gxydykw2yi3xl"
                                        // initialValue={template.content}
                                        value={template.content_en || ''}
                                        init={{
                                            paste_as_text: true,
                                            height: 400,
                                            plugins: 'link image code paste',
                                            toolbar: 'code | undo redo | bold italic underline | bullist numlist | alignleft aligncenter alignright alignjustify | link unlink ',
                                        }}
                                        onChange={(e) => this.handleInput(e, 'content_en')}
                                    />
                                </Segment>
                            </Form>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Segment style={{ maxHeight: '65vh', overflow: 'auto' }}>
                                <Table selectable>
                                    <Table.Body>
                                        {placeholders.map((p, i) => (
                                            <Table.Row key={`pl-${i}`}>
                                                <Table.Cell
                                                    onClick={() => this.copy(`@@${p}`)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    {p}
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <FloatingButtons
                    onCancel={this.props.onExit}
                    onSave={() => this.save()}
                    onSaveExit={() => this.save(true)}
                />
            </div>
        );
    }
}

export default withTranslation('common')(TemplatesEditView);
