import React from 'react';
import { find } from 'lodash';
import { Checkbox, Table, Segment, Dropdown, Icon, DropdownItemProps, Menu, Input, Confirm, TableCellProps, Popup } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Pagination from '../DefaultPagination';
import DomainLink from '../DomainLink';
import styles from './styles.module.scss';
const { Body, Header, Row, Cell, HeaderCell, Footer } = Table;

interface HeaderObject {
    filter?: {id: number, name: string}[],
    id: string,
    onFilter?: (values: any[], column: string) => void,
    title: any,
    sortable?: string,
    showIf?: boolean,
}

interface FooterObject {
    id: string,
    content: any,
    showIf?: boolean,
}

export interface FilterObject {
    order: string,
    orderBy: string,
    page: number,
    query: string,
}

interface AdvancedTableProps {
    isLoading?: boolean,
    items: any[],
    name: string,
    showFilter?: boolean,
    title?: string,
    segmentProps?: any,
    extraFilter?: any,
    afterFilter?: any,
    header: HeaderObject[],
    footer?: FooterObject[],
    renderRow: (item: any, index?: number) => ({
        cells: {
            header: string,
            render: any,
            cellProps?: TableCellProps
            actions?: any[],
            showIf?: boolean,
        }[],
        actions?: any[],
        color?: string | boolean,
    }),
    onFilter?: (filter?: any) => void,
    pagination?: any,
}

interface AdvancedTableState {
    columnFilter: {
        [key: string]: any,
    },
    filter: FilterObject
}

export class AdvancedTableAction extends React.Component<DropdownItemProps, { open: boolean }> {
    constructor(props: DropdownItemProps) {
        super(props);
        this.state = {
            open: false,
        };
    }

    onConfirm = () => {
        if (this.props.onConfirm) {
            this.setState({ open: true });
        }
    }

    render = () => {
        const { iconName, text, to } = this.props;

        if (this.props.divider) {
            return <Dropdown.Divider />
        }

        return (
            <>
            <Dropdown.Item onClick={this.props.onClick || this.onConfirm}>
                {iconName && <Icon name={iconName} />}
                {to ? <DomainLink default to={to}>
                    {text}
                </DomainLink> : text}
            </Dropdown.Item>
            <Confirm
                open={this.state.open}
                header="Weet u het zeker?"
                onCancel={() => this.setState({ open: false })}
                onConfirm={() => {
                    this.setState({ open: false });
                    this.props.onConfirm()
                }}
                content={this.props.confirmContent}
                cancelButton="Annuleren"
                confirmButton="Ik weet het zeker"
            />
          </>
        )
    }
};

let searchTimer: any;

class AdvancedTable extends React.Component<AdvancedTableProps & WithTranslation, AdvancedTableState> {
    private columnFilterTimer: any;

    constructor(props: AdvancedTableProps & WithTranslation) {
        super(props);

        this.state = {
            filter: {
                order: 'asc',
                orderBy: '',
                page: 1,
                query: '',
            },
            columnFilter: {},
        };
    }

    updateFilterOnChange = (name: string) => {
        let filter: any = localStorage.getItem(`filter-${name}`);
        
        if (filter) {
            filter = JSON.parse(filter);
            filter.query = '';
            this.updateFilter(filter);
        } else {
            this.updateFilter(this.state.filter);
        }
    }

    componentDidMount = () => {
        if (this.props.onFilter) {
            this.updateFilterOnChange(this.props.name);
        }
    }

    componentWillReceiveProps = (props: AdvancedTableProps) => {
        if (props.onFilter && props.name !== this.props.name) {
            this.updateFilterOnChange(props.name);
        }
    }

    handleColumnFilter = (column: string, value: any) => {
        clearTimeout(this.columnFilterTimer);
        const { header } = this.props;
        const { columnFilter } = this.state;
        const currentColumn = find(header, { id: column }) as HeaderObject;

        if (!columnFilter[column]) {
            columnFilter[column] = [value];
        } else {
            if (columnFilter[column].indexOf(value) === -1) {
                columnFilter[column].push(value);
            } else {
                columnFilter[column].splice(columnFilter[column].indexOf(value), 1);
            }
        }

        this.setState({ columnFilter });

        this.columnFilterTimer = setTimeout(() => {
            currentColumn.onFilter && currentColumn.onFilter(columnFilter[column] || [], column);
        }, 500);
    }

    handleSearch = (e: any, data: any) => {
        clearTimeout(searchTimer);
        this.updateFilter({
            ...this.state.filter,
            page: 1,
            query: data.value,
        }, false);

        searchTimer = setTimeout(() => {
            if (this.props.onFilter) {
                this.props.onFilter(this.state.filter);
            }
        }, 300);
    }

    loadPage = (page: number) => {
        this.updateFilter({ page });
    }

    sortColumn = (header: HeaderObject) => {
        if (header.sortable) {
            let { order, orderBy } = this.state.filter;

            if (header.sortable !== orderBy) {
                order = 'asc';
            } else {
                order = order === 'asc' ? 'desc' : 'asc';
            }

            this.updateFilter({
                order,
                orderBy: header.sortable,
            });
        }
    }

    updateFilter = (filter: Partial<FilterObject>, update: boolean = true) => {
        const newFilter = {
            ...this.state.filter,
            ...filter,
        };

        localStorage.setItem(`filter-${this.props.name}`, JSON.stringify(newFilter));

        this.setState({
            filter: newFilter,
        }, () => {
            if (update && this.props.onFilter) {
                this.props.onFilter(newFilter);
            }
        });
    }

    renderHeader = (header: HeaderObject, index: number) => {
        if (header.showIf === false) {
            return null;
        }
        const { items } = this.props;
        const { columnFilter } = this.state;
        const { order, orderBy } = this.state.filter;
        let cellProps = {};

        if (items.length) {
            const data = this.props.renderRow(items[0]);
            cellProps = data.cells[index].cellProps || {};
        }
        
        const icon = columnFilter[header.id] && columnFilter[header.id].length > 0 ? (
            <Icon color="black" name="filter" className={styles.headerCellFilter} />
        ) : (
            <Icon style={{ color: '#d1d1d1' }} name="filter" className={styles.headerCellFilter} />
        );

        return (
            <HeaderCell
                className={styles.headerCell}
                key={`header-${header.id}`}
                {...cellProps}
                onClick={() => this.sortColumn(header)}
                style={{ cursor: header.sortable ? 'pointer' : 'default' }}
            >
                {header.filter && header.onFilter && (
                    <Popup
                        on="click"
                        trigger={icon}
                    >
                        <Popup.Content>
                            {header.filter.map((f: any) => (
                                <div key={`${header.id}-filter-${f.id}`} className={styles.headerCellFilterItem}>
                                    <Checkbox
                                        checked={columnFilter[header.id] && columnFilter[header.id].indexOf(f.id) !== -1}
                                        label={f.name}
                                        value={f.id}
                                        onChange={(e: any, data: any) => this.handleColumnFilter(header.id, data.value)}
                                    />
                                </div>
                            ))}
                        </Popup.Content>
                    </Popup>
                )}
                {header.title}
                {header.sortable && orderBy !== header.sortable && <Icon name="sort" color="grey" />}
                {header.sortable && orderBy === header.sortable && order === 'asc' && <Icon name="caret up" />}
                {header.sortable && orderBy === header.sortable && order === 'desc' && <Icon name="caret down" />}
            </HeaderCell>
        );
    }

    renderFooter = (header: HeaderObject, index: number) => {
        if (header.showIf === false) {
            return null;
        }
        const { items, footer } = this.props;
        let cellProps = {};

        if (items.length) {
            const data = this.props.renderRow(items[0]);
            cellProps = data.cells[index].cellProps || {};
        }

        const footCell = find(footer, { id: header.id });

        return (
            <HeaderCell
                key={`footer-${header.id}`}
                {...cellProps}
            >
                <b>{footCell && footCell.showIf !== false ? footCell.content : ''}</b>
            </HeaderCell>
        );
    }

    renderRow = (item: any, index: number) => {
        const { header, renderRow } = this.props;
        const data = renderRow(item, index);
        
        const output = header.map((o, i) => {
            const cell = find(data.cells, { header: o.id });

            if (cell) {
                if (cell.showIf === false) {
                    return null;
                }

                return (
                    <Cell key={`cell-${item.id}-${i}`} {...cell.cellProps}>
                        {cell.render}
                    </Cell>
                );
            }
            
            return <Cell key={`cell-${item.id}-${i}`} />;
        });

        if (data.actions && data.actions.length > 0) {
            output.push((
                <Cell collapsing key={`cell-${item.id}-actions`}>
                    <Dropdown simple icon="ellipsis vertical">
                        <Dropdown.Menu direction="left">
                            {data.actions}
                        </Dropdown.Menu>
                    </Dropdown>
                </Cell>
            ));
        }

        return (
            <Row
                key={`row-${item.id}`}
                error={data.color && data.color === 'error' ? true : false}
                negative={data.color && data.color === 'negative' ? true : false}
                warning={data.color && data.color === 'warning' ? true : false}
                positive={data.color && data.color === 'positive' ? true : false}
            >
                {output}
            </Row>
        );
    }

    // --- render
    render() {
        const { header, items, isLoading, pagination, onFilter, children, showFilter, title, t } = this.props;
        const { query } = this.state.filter;

        return (
            <>
            {children}
            <Segment loading={isLoading} {...this.props.segmentProps}>
                {title && <Header as="h3">{title}</Header>}
                {showFilter !== false && <Menu>
                    <Menu.Item style={{ flex: 1}}>
                        <Input
                            icon="search"
                            iconPosition="left"
                            name="q"
                            placeholder={`${t('general.search')}...`}
                            transparent
                            value={query}
                            onChange={this.handleSearch}
                        />
                    </Menu.Item>
                    {this.props.extraFilter}
                </Menu>}
                {this.props.afterFilter}
                <Table striped selectable>
                    <Header>
                        <Row>
                            {header.map(this.renderHeader)}
                            <HeaderCell className={styles.headerCell} />
                        </Row>
                    </Header>
                    <Body>
                        {items.length <= 0  ? (
                            <Row>
                                <Cell colSpan={header.length + 1}>
                                    <em>{t('general.no_results')}</em>
                                </Cell>
                            </Row>
                        ) : items.map(this.renderRow)}
                    </Body>
                    {this.props.footer && (
                        <Footer>
                            <Row>
                                {header.map(this.renderFooter)}
                                <HeaderCell />
                            </Row>
                        </Footer>
                    )}
                </Table>
                {pagination !== false && onFilter && <Pagination data={pagination} onChange={this.loadPage} />}
            </Segment>
            </>
        );
    }
}

export default withTranslation('common')(AdvancedTable);
