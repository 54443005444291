import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Segment, Divider, Table } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import PageHeader from '../../components/Layout/PageHeader';
import { request } from '../../api';
import DomainLink from '../../components/DomainLink';
import { AppState } from '../../store';
import { ApiAuthObject } from '../../api/auth';
import { ApiCustomerTimelineObject } from '../../api/customers';
import { money, number } from '../../lib/utils';

const initChartOptions = {
    credits: {
        enabled: false,
    },
    chart: {
        backgroundColor: 'transparent',
        type: 'column',
        zoomType: 'x',
    },
    title: {
        text: '',
    },
    plotOptions: {
    },
    tooltip: {
        headerFormatter: '<b>{point.x}</b><br/>',
        pointFormatter: function () {
            const self: any = this;
            const y = self.series.name === 'Aantal' ? number(self.y) : money(self.y);
            return `<span style="color:${self.color}">●</span> ${self.series.name}: <b>${y}</b><br/>`;
        },
        shared: true,
    },
    xAxis: {
        categories: [],
    },
    yAxis: [{
        id: 1,
        title: {
            text: 'Aantal'
        },
    }, {
        id: 2,
        opposite: true,
        title: {
            text: 'Bedrag'
        },
    }],
    series: []
};

interface SalesDashboardViewProps extends RouteComponentProps {
    auth?: ApiAuthObject,
}

interface SalesDashboardViewState {
    chartOptions: any,
    invoiceStatusOptions: any[],
    selectedInvoiceStatusses: any[],
    actions: ApiCustomerTimelineObject[],
    from: string,
    till: string,
}

class SalesDashboardView extends React.Component<SalesDashboardViewProps & WithTranslation, SalesDashboardViewState> {
    private dsoTimer: any;
    private searchTimer: any = 0;

    constructor(props: SalesDashboardViewProps & WithTranslation) {
        super(props);
    
        this.state = {
            chartOptions: initChartOptions,
            invoiceStatusOptions: [],
            selectedInvoiceStatusses: [],
            actions: [],
            from: '',
            till: '',
        };
    }

    componentDidMount = (): void => {
        this.fetch();
    }

    fetch = () => {
        request.get(`dashboard/sales`).then(({ data }) => {
            this.setState({
                chartOptions: {
                    ...initChartOptions,
                    xAxis: {
                        categories: data.categories,
                    },
                    series: [{
                        name: 'Aantal',
                        data: data.series[0].data,
                        color: 'rgb(124, 181, 236)',
                    }, {
                        name: 'Bedrag',
                        data: data.series[1].data,
                        color: 'rgb(41, 111, 217)',
                        yAxis: 2,
                    }],
                },
                actions: data.actions,
            });
        });
    }

    // render
    render = () => {
        const { actions } = this.state;

        return (
            <div className="pageContainer dashboard" style={{ position: 'relative' }}>
                <PageHeader title="Dashboard" />

                <Divider horizontal style={{ marginBottom: 20 }}>Bedrag per product/dienst</Divider>
                <Segment>
                    <HighchartsReact
                        containerProps={{ style: { width: '100%' } }}
                        highcharts={Highcharts}
                        options={this.state.chartOptions}
                    />
                </Segment>

                <Divider horizontal style={{ marginTop: 40, marginBottom: 20 }}>Mijn openstaande acties</Divider>
                <Segment>
                    <Table striped selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell collapsing>Datum</Table.HeaderCell>
                                <Table.HeaderCell collapsing>Klant</Table.HeaderCell>
                                <Table.HeaderCell>Onderwerp</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {actions.map((action: any) => (
                                <Table.Row key={`td-${action.id}`}>
                                    <Table.Cell collapsing>
                                        {action.date.split('-').reverse().join('-')}
                                    </Table.Cell>
                                    <Table.Cell collapsing>
                                        <DomainLink to={`/customers/${action.customer_id}/edit`}>
                                            {action.customer.name}
                                        </DomainLink>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {action.subject}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Segment>
            </div>
        );
    }
}

export default withTranslation('common')(connect((state: AppState) => ({
    auth: state.auth.user,
}))(SalesDashboardView));
