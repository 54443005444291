import React from 'react';
import { Segment, Menu } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { request } from '../../api';
import { currencies } from '../../lib/constants';
import { money } from '../../lib/utils';

interface AmountPerPeriodProps {
    history?: any,
    currency?: string,
    globalFilter: {
        query: string,
        trigger: number,
        r9: boolean,
    }
}

interface AmountPerPeriodState {
    chartOptions: any,
    status?: any,
    format: string,
    year: number,
    years: number[],
}

class AmountPerPeriod extends React.Component<AmountPerPeriodProps & WithTranslation, AmountPerPeriodState> {
    constructor(props: any) {
        super(props);
   
        // const self = this;
        const lc = localStorage.getItem('global-currency');

        this.state = {
            status: undefined,
            year: new Date().getFullYear(),
            years: [],
            format: 'm',
            chartOptions: {
                credits: {
                    enabled: false,
                },
                chart: {
                    type: 'column',
                    zoomType: 'x',
                },
                title: {
                    text: ''
                },
                tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    // pointFormat: (currencies[props.currency]||lc||'€')+' {point.y:.2f}',
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true,
                            // format: (currencies[props.currency]||lc||'€')+' {point.y:.2f}',
                        }
                    },
                    // series: {
                    //     cursor: 'pointer',
                    //     point: {
                    //         events: {
                    //             click: function (event: any) {
                    //                 const data = this as any;
                    //                 if (self.props.history) {
                    //                     self.props.history.push(redirect(`/invoices?status=${self.state.status[data.category]}`));
                    //                 };
                    //             },
                    //         },
                    //     }
                    // },
                },
                xAxis: {
                    categories: [],
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                },
                series: []
            },
        };
    }

    componentDidMount = () => {
        this.fetch();
    }

    componentWillReceiveProps = (props: AmountPerPeriodProps) => {
        if (props.currency !== this.props.currency) {
            this.fetch(props.currency);
        }
        if (props.globalFilter.trigger !== this.props.globalFilter.trigger) {
            this.fetch();
        }
    }
    
    fetch = (c?: string) => {
        const { props } = this;
        const currency = c || this.props.currency;
        const { year, format } = this.state;

        const q = this.props.globalFilter.query;
        const r9 = this.props.globalFilter.r9;

        request.get(`dashboard/amount-per-period?year=${year}&format=${format}&currency=${currency}&q=${q}${r9 ? `&r9=1` : ''}`).then(({ data }) => {
            this.setState({
                status: data.status,
                years: data.years,
                chartOptions: {
                    tooltip: {
                        headerFormat: '<b>{point.x}</b><br/>',
                        pointFormatter: function () {
                            const self: any = this;
                            return money(self.y, 2, currencies[props.currency || 'EUR']);
                        },
                    },
                    plotOptions: {
                        column: {
                            dataLabels: {
                                enabled: true,
                                formatter: function () {
                                    const self: any = this;
                                    return money(self.point.y, 2, currencies[props.currency || 'EUR']);
                                },
                            }
                        },
                    },
                    xAxis: {
                        categories: data.categories,
                    },
                    series: [{
                        name: props.t('invoices.invoice_amount'),
                        data: data.data,
                        color: 'rgb(124, 181, 236)',
                    }],
                },
            })
        });
    }

    // render
    render = () => {
        const { t } = this.props;
        const { chartOptions, format, years, year } = this.state;

        return (
            <div>
                <Menu compact>
                    <Menu.Item
                        style={format === 'Y' ? { border: 'solid 1px #dd0000' } : {}}
                        onClick={() => this.setState({ format: 'Y' }, this.fetch)}
                    >
                        {t('dashboard.year')}
                    </Menu.Item>
                    <Menu.Item
                        style={format === 'm' ? { border: 'solid 1px #dd0000' } : {}}
                        onClick={() => this.setState({ format: 'm' }, this.fetch)}
                    >
                        {t('dashboard.month')}
                    </Menu.Item>
                    <Menu.Item
                        style={format === 'Y-m-d' ? { border: 'solid 1px #dd0000' } : {}}
                        onClick={() => this.setState({ format: 'Y-m-d' }, this.fetch)}
                    >
                        {t('dashboard.day')}
                    </Menu.Item>
                </Menu>
                {format !== 'Y' && <Menu compact style={{ marginLeft: 15 }}>
                    {years.map((y) => (
                        <Menu.Item
                            style={year === y ? { border: 'solid 1px #dd0000' } : {}}
                            onClick={() => this.setState({ year: y }, this.fetch)}
                        >
                            {y}
                        </Menu.Item>
                    ))}
                </Menu>}
                <Segment>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                    />
                </Segment>
            </div>
        );
    }
}

export default withTranslation('common')(AmountPerPeriod);
