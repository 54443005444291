import qs from 'qs';
import { AxiosPromise } from 'axios';
import { each } from 'lodash';
import { request } from './index';
import { ApiInvoiceObject } from './invoices';
import { FilterObject } from '../components/AdvancedTable';

export default {
    list: (pending: boolean, filter?: Partial<FilterObject>, columnFilter?: any, expired?: boolean, all?: boolean, flex?: boolean): AxiosPromise => {
        const query: any = [];

        if (filter) {
            each(filter, (v, k) => {
                query.push(`${k}=${v}`);
            });
        }
        
        return request.get(`actions-list?type=${flex ? 'flex' : (pending ? 'pending' : 'action')}&${query.join('&')}&${qs.stringify(columnFilter)}&expired=${expired === true ? 1 : 0}&all=${all === true ? 1 : 0}`);
    },
    update: (invoices: ApiInvoiceObject[], del?: boolean, doAll?: boolean, pending?: boolean): AxiosPromise => {
        return request.post(`actions-list`, {
            invoices,
            doAll,
            delete: del,
            pending,
        });
    },
    updateFlexTemplates: (invoices: ApiInvoiceObject[], del?: boolean): AxiosPromise => {
        return request.post(`actions-list/flex`, {
            invoices,
            delete: del,
        });
    }
};
