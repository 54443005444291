import qs from 'qs';
import { AxiosPromise } from 'axios';
import { each } from 'lodash';
import { ApiDebtorObject } from './debtors';
import { request } from './index';
import { ApiInvoiceStatusObject } from './invoicestatuses';
import { ApiUserObject } from './users';
import { FilterObject } from '../components/AdvancedTable';

export interface ApiInvoiceJournalObject {
    id: number,
    invoice_id: number,
    user_id: number,
    user: ApiUserObject,
    type: number,
    from: number,
    to: number,
    label: string,
    created_at: string,
}

export interface ApiInvoiceObject {
    id: number,
    debtor_id: number,
    debtor?: ApiDebtorObject,
    is_processing: boolean,
    number: string,
    date: string,
    deadline?: string,
    information?: string,
    amount: number,
    total_amount?: number,
    currency: string,
    comment?: string,
    status?: ApiInvoiceStatusObject,
    invoice_status_id?: number,
    lastStatus?: ApiInvoiceStatusObject,
    last_status_id?: number,
    last_action_at?: string,
    expires_at?: string,
    interest?: number,
    incasso_costs?: number,
    total_incasso_costs?: number,
    is_sommatie: boolean,
    had_sommatie: boolean,
    timeline?: ApiTimelineObject[],
    journal?: ApiInvoiceJournalObject[],
    color?: string,
    flex_complete: boolean,
    calculate_incasso?: boolean,
    calculate_interest?: boolean,
    send_flex?: boolean,
    dossier_url: string,
    contract_number?: string,
}

export interface ApiTimelineObject {
    id: number,
    user_id: number,
    user: ApiUserObject,
    type: string,
    icon: string,
    content: string,
    created_at: string,
    has_file: boolean,
    filename?: string,
    download_url?: string,
    invoice_id: number,
    interest?: number,
    incasso_costs?: number,
}

export interface ApiInvoiceLetterObject {
    id: number,
    invoice:  ApiInvoiceObject,
    downloaded_at?: string,
    status: ApiInvoiceStatusObject,
    created_at: string,
    download_url: string,
    hash: string,
}

export default {
    addNote: (id: number, note: string, file?: File): AxiosPromise => {
        const data = new FormData();
        data.append('note', note);
        if (file) {
            data.append('file', file);
        }
        return request.post(`invoices/${id}/add-note`, data);
    },
    delete: (id: number | string): AxiosPromise => {
        return request.delete(`invoices/${id}`);
    },
    deleteTimeline: (invoiceId: number | string, timelineId: number | string): AxiosPromise => {
        return request.delete(`invoices/${invoiceId}/timeline/${timelineId}`);
    },
    list: (
        filter?: Partial<FilterObject>,
        include?: string[],
        open?: boolean,
        columnFilter?: any,
        debtorId?: number,
        dso?: string,
        df?: string,
        currency?: string,
        r9?: string,
    ): AxiosPromise => {
        const query: any = [`open=${open === true ? 1 : 0}`];

        if (filter) {
            each(filter, (v, k) => {
                query.push(`${k}=${v}`);
            });
        }

        if (debtorId) {
            query.push(`debtor_id=${debtorId}`)
        }
        
        return request.get(`invoices?${query.join('&')}${include ? `&with=${include.join(',')}` : ''}&${qs.stringify(columnFilter)}${dso ? `&dso=${dso}` : ''}${df ? `&df=${df}` : ''}${currency ? `&currency=${currency}` : ''}${r9 ? `&r9=1` : ''}`);
    },
    getInvoiceIds: (
        filter?: Partial<FilterObject>,
        open?: boolean,
        columnFilter?: any,
        debtorId?: number,
        dso?: string,
        df?: string,
        currency?: string,
    ): AxiosPromise => {
        const query: any = [`open=${open === true ? 1 : 0}`];

        if (filter) {
            each(filter, (v, k) => {
                query.push(`${k}=${v}`);
            });
        }

        if (debtorId) {
            query.push(`debtor_id=${debtorId}`)
        }
        
        return request.get(`invoices?${query.join('&')}&${qs.stringify(columnFilter)}${dso ? `&dso=${dso}` : ''}${df ? `&df=${df}` : ''}${currency ? `&currency=${currency}` : ''}&ids=1`);
    },
    debtorNote: (ids: number[], note: string, file?: File): AxiosPromise => {
        const data = new FormData();
        data.append('note', note);
        data.append('ids', JSON.stringify(ids));
        if (file) {
            data.append('file', file);
        }
        return request.post(`invoices/add-notes`, data);
    },
    listDownloads: (filter?: Partial<FilterObject>): AxiosPromise => {
        const query: any = [];

        if (filter) {
            each(filter, (v, k) => {
                query.push(`${k}=${v}`);
            });
        }

        return request.get(`invoices/downloads?with=debtor&${query.join('&')}`);
    },
    store: (data: any): AxiosPromise<ApiInvoiceObject> => {
        return request.post(`invoices?with=timeline`, data);
    },
    patch: (id: number | string, data: any): AxiosPromise<ApiInvoiceObject> => {
        return request.patch(`invoices/${id}?with=timeline,journal,debtor`, data);
    },
    show: (id: number | string, debtor?: boolean): AxiosPromise<ApiInvoiceObject> => {
        return request.get(`invoices/${id}?with=timeline,journal${debtor ? ',debtor' : ''}`);
    },
    updateStatuses: (list: ApiInvoiceObject[]): AxiosPromise => {
        const invoices: any = {};
        
        list.map((o) => {
            invoices[o.id] = o.last_status_id;
            return o;
        });

        return request.post('invoices/update-statuses', { invoices });
    },
    updateFlex: (list: ApiInvoiceObject[], templateId: number): AxiosPromise => {
        const invoices: any = {};
        
        list.map((o) => {
            invoices[o.id] = o.send_flex;
            return o;
        });

        return request.post('invoices/update-flex', { invoices, templateId });
    }
};
