import qs from 'qs';
import { findIndex } from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Menu, Checkbox, Popup, Table, Dropdown, Icon, Modal, Form } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Toastify from 'toastify';
import api, { getToken } from '../../api';
import { ApiInvoiceObject } from '../../api/invoices';
import AdvancedTable, { AdvancedTableAction, FilterObject } from '../../components/AdvancedTable';
import DomainLink from '../../components/DomainLink';
import PageHeader from '../../components/Layout/PageHeader';
import { money } from '../../lib/utils';
import { ApiDebtorObject } from '../../api/debtors';
import { ApiAuthObject } from '../../api/auth';
import { AppState } from '../../store';
import { ApiInvoiceStatusObject } from '../../api/invoicestatuses';
import FloatingButtons from '../../components/FloatingButtons';
import AddNote from './AddNote';
import { ApiTemplateObject } from '../../api/templates';
const config = require('../../config.json');

interface InvoicesListViewProps {
    auth: ApiAuthObject;
    debtor?: Partial<ApiDebtorObject>;
}

interface InvoicesListViewState {
    availableColumnFilters: {
        [key: string]: any,
    },
    columnFilters: {
        [key: string]: any,
    },
    editState: boolean,
    editFlexSend: boolean | number,
    invoices: ApiInvoiceObject[],
    statuses: ApiInvoiceStatusObject[],
    statusOptions: any,
    pagination?: any,
    isLoading: boolean,
    open: boolean,
    tableFilter: any,
    changeAll?: number,
    showNoteModal: boolean,
    templateOptions: any[],
    totals: {
        amount: string,
        total_amount: string,
        interest: string,
        incasso_costs: string,
    },
}

class InvoicesListView extends React.Component<InvoicesListViewProps & WithTranslation, InvoicesListViewState> {
    constructor(props: any) {
        super(props);
    
        this.state = {
            availableColumnFilters: {},
            columnFilters: {},
            editState: false,
            editFlexSend: false,
            statuses: [],
            statusOptions: [],
            invoices: [],
            pagination: undefined,
            isLoading: false,
            open: true,
            tableFilter: undefined,
            changeAll: undefined,
            showNoteModal: false,
            templateOptions: [],
            totals: {
                amount: '0',
                total_amount: '0',
                interest: '0',
                incasso_costs: '0',
            },
        };
    }

    componentDidMount = () => {
        const { auth } = this.props;
        const { columnFilters } = this.state;
        const search = qs.parse(window.location.search.substring(1));
        
        if (search.status && search.status >= 0) {
            columnFilters['status'] = [search.status];

            this.setState({
                open: false,
                columnFilters,
            });
        }

        api.listStatuses(auth && auth.selected_customer && auth.selected_customer.license_type === 'flex').then(({ data }) => {
            const statusOptions = data.map((s: any) => ({
                id: `status-${s.id}`,
                text: s.name,
                value: s.id,
            }));

            this.setState({
                statuses: data,
                statusOptions,
            })
        });

        if (auth.selected_customer && auth.selected_customer.has_flex_template) {
            api.listTemplates(auth.selected_customer_id, true).then(({ data }) => {
                const templateOptions: any[] = [];
                data.data.map((t: ApiTemplateObject) => {
                    templateOptions.push({
                        key: `to-${t.id}`,
                        value: t.id,
                        text: t.name,
                    });
                });

                this.setState({
                    templateOptions,
                });
            });
        }
    }

    /**
     * Fetch
     */
    fetch = (filter?: FilterObject) => {
        const { debtor } = this.props;
        const { columnFilters, tableFilter } = this.state;
        const search = qs.parse(window.location.search.substring(1));
        this.setState({ isLoading: true, tableFilter: filter || tableFilter });

        api.listInvoices(filter || tableFilter, ['debtor'], this.state.open, columnFilters, debtor ? debtor.id : undefined, search && search.dso, search && search.df, search && search.currency, search && search.r9).then(({ data }) => {
            this.setState({
                availableColumnFilters: data.filters,
                invoices: data.data,
                pagination: data.meta,
                isLoading: false,
                totals: data.totals,
            });
        });
    }

    handleNote = (note: string, upload?: File) => {
        const { debtor, t } = this.props;
        const { columnFilters, tableFilter } = this.state;
        const search = qs.parse(window.location.search.substring(1));

        api.getInvoiceIds(tableFilter, this.state.open, columnFilters, debtor ? debtor.id : undefined, search && search.dso, search && search.df, search && search.currency).then(({ data }) => {
            api.debtorNote(data, note, upload).then(() => {
                this.setState({ showNoteModal: false });
                Toastify.success(t('invoices.notes_added'));
            });
        });
    }

    /**
     * Delete invoice
     */
    deleteInvoice = (id: number) => {
        const { t } = this.props;
        api.deleteInvoice(id).then(() => {
            Toastify.success(t('invoices.deleted'));
            this.fetch();
        });
    }

    /**
     * Set filter for column
     */
    filterColumn = (column: string, values: any[]) => {
        const { columnFilters } = this.state;
        columnFilters[column] = values;
        
        this.setState({
            columnFilters
        }, this.fetch);
    }

    handleStatusChange = (value: number, invoice: ApiInvoiceObject) => {
        const { invoices } = this.state;
        const index = findIndex(invoices, { id: invoice.id });
        invoices[index].last_status_id = value;
        this.setState({ invoices });
    }

    changeAll = (value: number) => {
        const { invoices } = this.state;

        invoices.map((o) => {
            o.last_status_id = value;
            return o;
        });

        this.setState({
            invoices,
            changeAll: value
        });
    }

    saveStatuses = () => {
        const { t } = this.props;
        api.updateInvoiceStatuses(this.state.invoices).then(() => {
            this.setState({
                editState: false 
            }, this.fetch);

            Toastify.success(t('invoices.status_saved'));
        });
    }

    handleCheckox = (item: ApiInvoiceObject, checked: boolean, name: string) => {
        const { invoices } = this.state;
        const index = findIndex(invoices, { id: item.id });
        if (index !== -1) {
            invoices[index].send_flex = checked;
            this.setState({ invoices });
        }
    }

    saveFlex = () => {
        const { t } = this.props;

        if (this.state.editFlexSend === true) {
            alert(t('invoices.no_template'));
        } else {
            api.updateInvoiceFlex(this.state.invoices, this.state.editFlexSend as number).then(() => {
                this.setState({
                    editFlexSend: false 
                }, this.fetch);

                Toastify.success(t('invoices.flex_saved'));
            });
        }
    }

    /**
     * Toggle all invoices
     */
     toggleAll = (state: boolean) => {
        const { invoices } = this.state;
        const newInvoices = invoices.map((o) => {
            o.send_flex = state;
            return o;
        })
        this.setState({ invoices: newInvoices });
    }

    renderCosts = (item: any, trigger?: number) => {
        const { t } = this.props;
        
        return <Popup
            trigger={<abbr>{money(trigger || 0, 2, item.currency || '€')}</abbr>}
        >
            <Popup.Header>{t('actionlist.specification')}</Popup.Header>
            <Popup.Content>
                <Table>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>{t('invoices.amount')}</Table.Cell>
                            <Table.Cell textAlign="right">
                                {money(item.amount || 0, 2, item.currency || '€')}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ borderTop: 'solid 2px #000' }}>{t('invoices.interest')}</Table.Cell>
                            <Table.Cell style={{ borderTop: 'solid 2px #000' }} textAlign="right">
                                {money(item.interest || 0, 2, item.currency || '€')}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{t('invoices.incassocosts')}</Table.Cell>
                            <Table.Cell textAlign="right">
                                {money(item.incasso_costs || 0, 2, item.currency || '€')}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{ borderTop: 'solid 2px #000' }}>RI Totaal</Table.Cell>
                            <Table.Cell style={{ borderTop: 'solid 2px #000' }} textAlign="right">
                                {money(parseFloat(item.incasso_costs || 0) + parseFloat(item.interest || 0), 2, item.currency || '€')}
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Popup.Content>
        </Popup>
    }

    // render
    render = () => {
        const { auth, debtor, t } = this.props;
        const { editState, pagination, invoices, isLoading, availableColumnFilters, totals, statusOptions, editFlexSend, templateOptions } = this.state;

        return (
            <div className="pageContainer">
                <PageHeader title={debtor ? undefined : t('invoices.title')}>
                    {auth.role_id !== 6 && auth.role_id !== 8 && (<>
                        <DomainLink to={`/invoices/create${debtor ? `?debtor_id=${debtor.id}` : ''}`}>
                            <Popup
                                trigger={<Button primary icon="plus" />}
                                content={t('invoices.add_invoice')}
                                inverted
                                size="tiny"
                            />
                        </DomainLink>              

                        {editState ? (<>
                            <FloatingButtons
                                onCancel={() => this.setState({ editState: false })}
                                onSave={this.saveStatuses}
                                btnTextSave={t('invoices.save_invoice_status')}
                                zIndex={102}
                                hidePush={true}
                            >
                                <Dropdown
                                    placeholder={t('invoices.edit_all_status')}
                                    options={statusOptions}
                                    selection
                                    selectOnBlur={false}
                                    onChange={(e: any, { value }) => this.changeAll(value as number)}
                                    value={this.state.changeAll}
                                    style={{ marginRight: 10 }}
                                />
                            </FloatingButtons>
                        </>) : (
                            <Popup
                                trigger={<Button primary onClick={() => this.setState({ editState: true })} icon="sign in" />}
                                content={t('invoices.edit_status')}
                                inverted
                                size="tiny"
                            />
                        )}
                        {editFlexSend !== false ? (<>
                            <FloatingButtons
                                onCancel={() => this.setState({ editFlexSend: false })}
                                btnTextSave={t('invoices.save_flex_settings')}
                                onSave={this.saveFlex}
                                zIndex={102}
                                hidePush={true}
                            >
                                <Dropdown
                                    options={templateOptions}
                                    selection
                                    search
                                    placeholder={t('invoices.select_flex')}
                                    selectOnBlur={false}
                                    value={editFlexSend}
                                    onChange={(e, { value }: any) => this.setState({ editFlexSend: value })}
                                    style={{ marginRight: 10 }}
                                />
                            </FloatingButtons>
                        </>) : debtor && auth.selected_customer && auth.selected_customer.has_flex_template ? (
                            <Popup
                                trigger={<Button primary onClick={() => this.setState({ editFlexSend: true })} icon="envelope open" />}
                                content={t('invoices.set_flex')}
                                inverted
                                size="tiny"
                            />
                        ) : null}

                        {debtor && (
                            <Popup
                                trigger={<Button
                                    icon="file outline"
                                    primary
                                    onClick={() => this.setState({ showNoteModal: true })}
                                />}
                                content={t('invoices.add_general_note')}
                                inverted
                                size="tiny"
                            />
                        )}
                    </>)}
                    
                    <a className="ui basic button" href={`${config.baseUrl}invoices/export?export=1&open=${this.state.open ? 1 : 0}&${qs.stringify(this.state.tableFilter)}&${qs.stringify(this.state.columnFilters)}&_token=${getToken()}&debtor_id=${debtor ? debtor.id : ''}`} target="_blank" rel="noopener noreferrer">{t('general.export')}</a>
                </PageHeader>

                <AdvancedTable
                    title={debtor ? t('invoices.title') : undefined}
                    name="invoice-index"
                    items={invoices}
                    header={[{
                        id: 'check',
                        title: editFlexSend !== false ? <Checkbox
                        checked={invoices.filter(o => o.send_flex).length === invoices.length}
                        onChange={(e: any, { checked }) => this.toggleAll(checked as boolean)}
                    /> : '',
                    }, {
                        id: 'debtor',
                        title: t('invoices.debtor'),
                        sortable: 'debtor',
                    }, {
                        id: 'number',
                        title: t('invoices.number'),
                        sortable: 'number',
                    }, {
                        id: 'date',
                        title: t('invoices.date'),
                        sortable: 'date',
                    }, {
                        id: 'expires',
                        title: t('invoices.expiry_date'),
                        sortable: 'expires_at',
                    }, {
                        id: 'totalAmount',
                        title: t('invoices.invoice_amount'),
                        sortable: 'total_amount',
                    }, {
                        id: 'amount',
                        title: t('invoices.invoice_open_amount'),
                        sortable: 'amount',
                    }, {
                        id: 'lastStatus',
                        title: t('invoices.current_status'),
                        sortable: 'last_status_id',
                        filter: availableColumnFilters.status,
                        onFilter: (values) => this.filterColumn('status', values),
                    }]}
                    renderRow={(item: ApiInvoiceObject) => {
                        let amountComp = money(item.amount || 0, 2, item.currency);
                        if (item.had_sommatie) {
                            amountComp = this.renderCosts(item, item.total_incasso_costs);
                        }

                        let lastStatus = item.lastStatus ? (<div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* {item.flex_complete && <Popup trigger={<Icon name="exclamation triangle" color="orange" />} content="Einde flow bereikt" inverted size="tiny" />} */}
                            <div style={{ flex: 1 }}>
                                {item.lastStatus.name}<br />
                                <small>{moment(item.last_action_at).format('DD/MM/YYYY')}</small>
                            </div>
                        </div>) : '-';

                        if (editState) {
                            lastStatus = (<>
                                <Dropdown
                                    options={statusOptions}
                                    selection
                                    selectOnBlur={false}
                                    onChange={(e: any, { value }) => this.handleStatusChange(value as number, item)}
                                    value={item.last_status_id}
                                /><br />
                                <small>{t('invoices.current_status')}: {item.lastStatus && item.lastStatus.name}</small>
                            </>)
                        }

                        return {
                            cells: [{
                                header: 'check',
                                render: editFlexSend !== false ? <Checkbox
                                    checked={item.send_flex}
                                    onChange={(e, { checked }: any) => this.handleCheckox(item, checked, 'send_flex')}
                                /> : (item.send_flex && <Popup
                                    trigger={<Icon name="file outline" color="orange" />}
                                    inverted
                                    size="tiny"
                                    content="Flex template"
                                />),
                                cellProps: { collapsing: true },
                            }, {
                                header: 'debtor',
                                render: (<>
                                    <DomainLink primary to={`/debtors/${item.debtor_id}/edit`}>
                                        {item.debtor ? `${item.debtor.number} - ${item.debtor.name}` : ''}
                                    </DomainLink>
                                    {!debtor && (<>
                                        <br />
                                        <small>{item.debtor ? item.debtor.status || '' : ''}</small>
                                    </>)}
                                </>),
                            }, {
                                header: 'number',
                                render: auth.role_id === 6
                                    ? item.number
                                    : (<>
                                        <DomainLink primary to={`/invoices/${item.id}/edit`}>{item.number}</DomainLink>
                                        {item.contract_number && (<>
                                            <br />
                                            <small>{item.contract_number}</small>
                                        </>)}
                                    </>),
                                cellProps: { collapsing: true }
                            }, {
                                header: 'date',
                                render: moment(item.date).format('DD/MM/YYYY'),
                            }, {
                                header: 'expires',
                                render: item.expires_at ? moment(item.expires_at).format('DD/MM/YYYY') : '-',
                            }, {
                                header: 'amount',
                                render: amountComp,
                                cellProps: { textAlign: 'right' }
                            }, {
                                header: 'totalAmount',
                                render: money(item.total_amount || 0, 2, item.currency),
                                cellProps: { textAlign: 'right' }
                            }, {
                                header: 'lastStatus',
                                render: lastStatus,
                                cellProps: { collapsing: true }
                            }],
                            actions: auth.role_id === 6 || auth.role_id === 8 ? [] : [
                                <AdvancedTableAction 
                                    key={`action-1-${item.id}`}
                                    to={`/invoices/${item.id}/edit`}
                                    iconName="pencil"
                                    text={t('general.edit')}
                                />,
                                auth.super_login && <><AdvancedTableAction key={`action-2-${item.id}`} divider />
                                <AdvancedTableAction 
                                    key={`action-6-${item.id}`}
                                    iconName="trash alternate outline"
                                    text={t('general.delete')}
                                    onConfirm={() => this.deleteInvoice(item.id)}
                                    confirmContent={t('invoices.confirm_delete')}
                                /></>
                            ]
                        };
                    }}
                    footer={[{
                        id: 'amount',
                        content: this.renderCosts(totals, parseFloat(totals.amount || '0') + parseFloat(totals.incasso_costs || '0') + parseFloat(totals.interest || '0')),//money(totals.amount),
                    }, {
                        id: 'totalAmount',
                        content: money(totals.total_amount),
                    }]}
                    onFilter={this.fetch}
                    isLoading={isLoading}
                    pagination={pagination}
                    extraFilter={<Menu.Item>
                        <Checkbox
                            toggle
                            label="Alleen debiteuren met openstaande vorderingen"
                            checked={this.state.open}
                            onChange={(e: any, data: any) => this.setState({ open: data.checked }, this.fetch)}
                        />
                    </Menu.Item>}
                />
                <Modal
                    open={this.state.showNoteModal}
                    onClose={() => this.setState({ showNoteModal: false })}
                    size="small"
                    closeIcon
                >
                    <Modal.Header>
                        {t('invoices.add_general_note_for')} {pagination && pagination.total} {t('invoices.title').toLocaleLowerCase()}
                    </Modal.Header>
                    <Modal.Content>
                        <Form onSubmit={() => false}>
                            <AddNote onAdd={this.handleNote} />
                        </Form>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default withTranslation('common')(connect((state: AppState) => ({
    auth: state.auth.user,
}), {})(InvoicesListView));
