import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import SocketIOClient from 'socket.io-client';
import { ApiAuthObject } from '../../api/auth';
import config from '../../config.json';
import { AppState } from '../../store';

interface SocketIoProps {
    auth?: ApiAuthObject;
}

const SocketIo: FC<SocketIoProps> = ({ auth }): null => {
    useEffect(() => {
        if (!auth) return;
        const socket = (SocketIOClient as any)
        .connect(config.socketUrl, { query: `cid=${auth.selected_customer_id}&uid=${auth.id}` });

        socket.on('message', ({ action, detail}: { action: string, detail: any }) => {
            dispatchEvent(new CustomEvent(`socket_${action}`, { detail }));
        });

        return () => {
            socket.disconnect();
        }
    }, [auth]);

    return null;
}

export default connect((state: AppState) => ({
    auth: state.auth.user,
}))(SocketIo);
