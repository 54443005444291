import React from 'react';
import { Input } from 'semantic-ui-react';
import styles from './styles.module.scss';

interface MoneyInputProps {
    value: any;
    label: any,
    readOnly?: boolean,
    name: string,
    disabled?: boolean,
    onChange: (e: any, data: any) => void
}

interface MoneyInputState {
    input: any[],
}

class MoneyInput extends React.Component<MoneyInputProps, MoneyInputState> {
    constructor (props: MoneyInputProps) {
        super(props);

        this.state = {
            input: [],
        }
    }

    /**
     * Mount
     */
    componentDidMount = () => {
        const { value } = this.props;
        this.setState({ input: (value + '').split('.') });
    }

    /**
     * Receive
     */
    componentWillReceiveProps = (props: MoneyInputProps) => {
        const { value } = props;
        this.setState({ input: (value + '').split('.') });
    }

    /**
     * Handle input
     */
    handleInput = (e: any, data: any, i: number) => {
        const { input } = this.state;
        input[i] = data.value;
        this.setState({ input });
        this.props.onChange(e, { name: this.props.name, value: input.join('.') });
    }

    render = () => {
        const { label, disabled, readOnly } = this.props;
        const { input } = this.state;

        return (
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Input
                    label={label}
                    name="value"
                    onChange={(e: any, data: any) => this.handleInput(e, data, 0)}
                    required
                    value={input[0] || 0}
                    className={styles.inputLeft}
                    disabled={disabled === true}
                    readOnly={readOnly}
                />
                <span style={{ marginLeft: 5, marginRight: 5 }}>,</span>
                <Input
                    name="comma"
                    onChange={(e: any, data: any) => this.handleInput(e, data, 1)}
                    required
                    value={input[1] || '0'}
                    style={{ width: 80 }}
                    disabled={disabled === true}
                />
            </div>
        )
    }
}

export default MoneyInput
