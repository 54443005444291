import qs from 'qs';
import { find, each, findIndex } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Icon, Checkbox, Dropdown, Popup, Menu, Input, Table, Label } from 'semantic-ui-react';
import Toastify from 'toastify';
import api, { getToken } from '../../api';
import { ApiInvoiceObject } from '../../api/invoices';
import { ApiInvoiceStatusObject } from '../../api/invoicestatuses';
import AdvancedTable, { AdvancedTableAction, FilterObject } from '../../components/AdvancedTable';
import DomainLink from '../../components/DomainLink';
import FloatingButtons from '../../components/FloatingButtons';
import PageHeader from '../../components/Layout/PageHeader';
import { money, redirect } from '../../lib/utils';
import { ApiAuthObject } from '../../api/auth';
import { AppState } from '../../store';
import { ApiDebtorObject } from '../../api/debtors';
const config = require('../../config.json');

interface ActionListProps {
    auth?: ApiAuthObject;
}

interface ActionsListViewState {
    availableColumnFilters: {
        [key: string]: any,
    },
    enabled: {
        [key: number]: any,
    },
    columnFilters: {
        [key: string]: any,
    },
    showFlex: boolean,
    expiredDeadline: boolean,
    invoices: ApiInvoiceObject[],
    isLoading: boolean,
    pagination: any,
    statuses: ApiInvoiceStatusObject[],
    statusOptions: any,
    tableFilter?: any,
    pending: boolean,
    selectAll: boolean,
    changeAll?: number,
    editInline: number,
    totals: {
        amount: number,
    },
}

class ActionsListView extends React.Component<ActionListProps & WithTranslation, ActionsListViewState> {
    constructor(props: ActionListProps & WithTranslation) {
        super(props);
    
        this.state = {
            availableColumnFilters: {},
            enabled: {},
            columnFilters: {},
            showFlex: false,
            invoices: [],
            expiredDeadline: false,
            isLoading: false,
            pagination: undefined,
            statuses: [],
            statusOptions: [],
            tableFilter: undefined,
            pending: window.location.search.indexOf('?agenda') !== -1,
            selectAll: false,
            changeAll: undefined,
            editInline: 0,
            totals: {
                amount: 0,
            },
        };
    }

    /**
     * Mount
     */
    componentDidMount = () => {
        const { auth } = this.props;

        api.listStatuses(auth && auth.selected_customer && auth.selected_customer.license_type === 'flex').then(({ data }) => {
            const statusOptions = data.map((s: any) => ({
                id: `status-${s.id}`,
                text: s.name,
                value: s.id,
            }));

            this.setState({
                statuses: data,
                statusOptions,
            })
        });
    }

    changeAll = (value: number) => {
        const { enabled, pending, invoices } = this.state;

        each(enabled, (o, i) => {
            const id = parseInt(i);
            enabled[id][pending ? 'last_status_id' : 'invoice_status_id'] = value;
            const index = findIndex(invoices, { id });
            invoices[index][pending ? 'last_status_id' : 'invoice_status_id'] = value;
            return o;
        });

        this.setState({
            enabled,
            invoices,
            changeAll: value
        });
    }

    /**
     * Do actions
     */
    doActions = (del?: boolean) => {
        const { t } = this.props;
        const { enabled, selectAll, pending, showFlex } = this.state;
        let update: ApiInvoiceObject[] = Object.values(enabled).filter(o => o.state === true);
        this.setState({ isLoading: true });

        if (selectAll) {
            update = [];
            const { columnFilters, pending, tableFilter, expiredDeadline } = this.state;
            api.listActionsList(pending, tableFilter, columnFilters, expiredDeadline, true, showFlex).then(({ data }) => {
                data.data.map((o: ApiInvoiceObject) => {
                    update.push(o);
                    return o;
                });

                if (showFlex) {
                    api.updateFlexTemplates(update, del).then(() => {
                        this.setState({
                            enabled: {},
                            changeAll: undefined,
                        });
                        this.fetch();
                        Toastify.success(del === true ? t('actionlist.flex_reset') : t('actionlist.flex_sent'));
                    });
                } else {
                    api.updateActionsList(update, del, selectAll, pending).then(({ data }) => {
                        this.setState({
                            invoices: data.data,
                            pagination: data.meta,
                            isLoading: false,
                            enabled: {},
                            changeAll: undefined,
                        });
                    });
        
                    Toastify.success(del === true ? t('actionlist.deleted') : t('actionlist.in_progress'));
                }
            });
        } else {
            if (pending) {
                update = update.map((o: ApiInvoiceObject) => {
                    o.invoice_status_id = o.last_status_id;
                    return o;
                });
            }

            if (showFlex) {
                api.updateFlexTemplates(update, del).then(({ data }) => {
                    this.setState({
                        invoices: data.data,
                        pagination: data.meta,
                        isLoading: false,
                        enabled: {},
                        changeAll: undefined,
                    });
                    Toastify.success(del === true ? t('actionlist.flex_reset') : t('actionlist.flex_sent'));
                });
            } else {
                api.updateActionsList(update, del, selectAll, pending).then(({ data }) => {
                    this.setState({
                        invoices: data.data,
                        pagination: data.meta,
                        isLoading: false,
                        enabled: {},
                        changeAll: undefined,
                    });
                });

                Toastify.success(del === true ? t('actionlist.deleted') : t('actionlist.in_progress'));
            }
        }
    }

    /**
     * Enable update
     */
    enableUpdate = (invoiceId: number, state: boolean) => {
        const { enabled, invoices } = this.state;
        const invoice = find(invoices, { id: invoiceId }) as ApiInvoiceObject;

        enabled[invoiceId] = {
            ...invoice,
            state,
        };
        this.setState({ enabled });
    }

    /**
     * Fetch
     */
    fetch = (filter?: FilterObject) => {
        const { columnFilters, pending, tableFilter, expiredDeadline, showFlex } = this.state;
        this.setState({ isLoading: true, tableFilter: filter || tableFilter });

        api.listActionsList(pending, filter || tableFilter, columnFilters, expiredDeadline, false, showFlex).then(({ data }) => {
            this.setState({
                availableColumnFilters: data.filters,
                invoices: data.data,
                pagination: data.meta,
                isLoading: false,
                totals: data.totals,
                changeAll: undefined,
            });
        });
    }

    /**
     * Set filter for column
     */
    filterColumn = (column: string, values: any[]) => {
        const { columnFilters } = this.state;
        columnFilters[column] = values;
        
        this.setState({
            columnFilters
        }, this.fetch);
    }

    /**
     * Handle status change
     */
    handleStatusChange = (statusId: number, index?: number) => {
        const { invoices, pending, enabled } = this.state;
        
        if (index !== undefined) {
            invoices[index][pending ? 'last_status_id' : 'invoice_status_id'] = statusId;
            if (enabled[invoices[index].id]) {
                enabled[invoices[index].id] = {
                    ...invoices[index],
                    state: enabled[invoices[index].id].state,
                };
            }
            this.setState({ invoices });
        }
    }
    
    handleDebtorStatusChange = (newStatus: any, index?: number) => {
        const { invoices } = this.state;
        
        if (index !== undefined && invoices[index] && invoices[index].debtor) {
            invoices[index].debtor = {
                ...invoices[index].debtor,
                status: newStatus,
            } as ApiDebtorObject;
            this.setState({ invoices });
        }
    }

    /**
     * Handle deadline change
     */
    handleDeadlineChange = (deadline: string, index?: number) => {
        const { invoices, enabled } = this.state;
        
        if (index !== undefined) {
            invoices[index].deadline = deadline;

            if (enabled[invoices[index].id]) {
                enabled[invoices[index].id] = {
                    ...enabled[invoices[index].id],
                    deadline,
                };
            }

            this.setState({ invoices, enabled });
        }
    }

    /**
     * Toggle all invoices
     */
    toggleAll = (state: boolean) => {
        const { enabled, invoices } = this.state;
        invoices.map(o => enabled[o.id] = ({
            state: o.is_processing ? false : state,
            ...o,
        }));
        this.setState({ enabled });
    }

    /**
     * Toggle pending list
     */
    togglePending = (isPending: boolean, showFlex?: boolean) => {
        if (showFlex !== undefined) {
            this.setState({
                showFlex,
                enabled: {},
            });//, this.fetch);
        } else {
            this.setState({
                pending: isPending,
                showFlex: false,
                enabled: {},
            }); //, this.fetch);
        }
    }

    editInline = (debtorId: number) => {
        this.setState({
            editInline: debtorId,
        }, () => {
            const input = document.getElementById(`inline-deb-${debtorId}`);
            if (input) input.focus();
        });
    }

    saveDebtorStatus = (debtor: ApiDebtorObject) => {
        api.patchDebtor(debtor.id, debtor).then(() => {
            this.setState({ editInline: 0 });
        });
    }

    // render
    render = () => {
        const { auth, t } = this.props;
        const { availableColumnFilters, enabled, invoices, isLoading, pagination, statusOptions, pending, statuses, totals, selectAll, showFlex } = this.state;
        const enabledCount = Object.values(enabled).filter(o => o.state === true).length;

        const headers = [];
        headers.push({
            id: 'check',
            title: <Checkbox
                checked={enabledCount === invoices.length}
                onChange={(e: any, { checked }) => this.toggleAll(checked as boolean)}
            />
        });
        headers.push({
            id: 'debtor',
            title: t('invoices.debtor'),
            sortable: 'debtor',
        });
        headers.push({
            id: 'number',
            title: t('invoices.number'),
            sortable: 'number',
        });
        headers.push({
            id: 'date',
            title: t('general.date'),
            sortable: 'date',
        });
        headers.push({
            id: 'expires',
            title: t('general.expiry_date'),
            sortable: 'expires_at',
        });
        headers.push({
            id: 'amount',
            title: t('invoices.open'),
            sortable: 'amount',
        });
        if (!showFlex && pending === false) {
            headers.push({
                id: 'lastStatus',
                title: showFlex ? 'Template' : t('invoices.current_status'),
            });
        }
        headers.push({
            id: 'status',
            title: showFlex || pending ? t('invoices.current_status') : t('actionlist.nextstatus'),
            filter: availableColumnFilters.status,
            onFilter: (values: any) => this.filterColumn('status', values),
        });

        if (showFlex) {
            headers.push({
                id: 'template',
                title: 'Template',
            });
        }

        headers.push({
            id: 'deadline',
            title: 'Deadline',
            sortable: 'deadline',
            showIf: pending,
        });
        headers.push({
            id: 'debtorStatus',
            title: t('debtors.status'),
            sortable: 'debtor.status',
        });
        headers.push({
            id: 'customerOwner',
            title: t('debtors.owner'),
            filter: availableColumnFilters.owners,
            onFilter: (values: any) => this.filterColumn('owners', values),
        });
        
        return (
            <div className="pageContainer pageContainer--fluid">
                {showFlex ? (
                    <PageHeader title="Flex templates" />
                ) : (
                    <PageHeader title={t('actionlist.title')}>
                        <a className="ui basic button" href={`${config.baseUrl}actions-list/export?export=1&type=${pending ? 'pending' : ''}&${qs.stringify(this.state.tableFilter)}&_token=${getToken()}`} target="_blank" rel="noopener noreferrer">{t('general.export')}</a>
                    </PageHeader>
                )}
                <Menu tabular attached="top">
                    <Menu.Item
                        content={t('actionlist.actionlist')}
                        active={!showFlex && !pending}
                        onClick={() => this.togglePending(false)}
                    />
                    <Menu.Item
                        content={t('actionlist.agendalist')}
                        active={!showFlex && pending}
                        onClick={() => this.togglePending(true)}
                    />
                    {auth && auth.selected_customer && auth.selected_customer.has_flex_template && (
                        <Menu.Item
                            active={showFlex}
                            content="Flex templates"
                            onClick={() => this.togglePending(pending, true)}
                        />
                    )}
                    {auth && auth.downloads > 0 && (
                    <Menu.Item
                        as={Link}
                        to={redirect('/letters')}
                    >
                        {t('actionlist.downloadlist')}
                        <Label color="red" size="tiny">{auth.downloads}</Label>
                    </Menu.Item>)}
                </Menu>
                <AdvancedTable
                    name={`${showFlex ? 'flex' : pending ? 'agenda' : 'actions'}-index`}
                    items={invoices}
                    segmentProps={{ attached: 'bottom', style: { width: '100%', overflow: 'auto' }}}
                    header={headers}
                    renderRow={(item: ApiInvoiceObject, index?: number) => {
                        const selectedStatus = find(statuses, { id: showFlex || pending ? item.last_status_id : item.invoice_status_id });
                        
                        let amountComp = money(item.amount || 0, 2, item.currency);
                        if (item.had_sommatie) {
                            amountComp = <Popup
                                trigger={<span>{money(item.amount || 0, 2, item.currency)}</span>}
                            >
                                <Popup.Header>{t('actionlist.specification')}</Popup.Header>
                                <Popup.Content>
                                    <Table>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>{t('invoices.amount')}</Table.Cell>
                                                <Table.Cell textAlign="right">
                                                    {money(item.amount || 0, 2, item.currency)}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{t('invoices.interest')}</Table.Cell>
                                                <Table.Cell textAlign="right">
                                                    {money(item.interest || 0, 2, item.currency)}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>{t('invoices.incassocosts')}</Table.Cell>
                                                <Table.Cell textAlign="right">
                                                    {money(item.incasso_costs || 0, 2, item.currency)}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </Popup.Content>
                            </Popup>
                        }

                        return {
                            // color: pending ? item.color : undefined,
                            color: showFlex ? (item.debtor && !item.debtor.template_id ? 'error' : '') : item.color,
                            cells: [{
                                header: 'check',
                                render: item.is_processing ? (
                                    // <Popup
                                    //     trigger={<Loader active inline size="small" />}
                                    //     content="Wordt verwerkt"
                                    //     inverted
                                    // />
                                    null
                                ) : !selectAll ? <Checkbox
                                        checked={enabled[item.id] && enabled[item.id].state === true}
                                        onChange={(e: any, { checked }) => this.enableUpdate(item.id, checked as boolean)}
                                    />
                                : null,
                                cellProps: { collapsing: true },
                            }, {
                                header: 'debtor',
                                render: <DomainLink primary to={`/debtors/${item.debtor_id}/edit`}>
                                    {item.debtor && item.debtor.use_courant && <Popup trigger={<Icon style={{ marginRight: 5, color: '#ff8000' }} name="list" />} inverted size="tiny" content="Rekening courant template" />}
                                    {item.debtor ? `${item.debtor.number} - ${item.debtor.name}` : ''}
                                </DomainLink>,
                            }, {
                                header: 'number',
                                render: <>
                                    <DomainLink primary to={`/invoices/${item.id}/edit`}>{item.number}</DomainLink>
                                    {item.contract_number && (<>
                                        <br />
                                        <small>{item.contract_number}</small>
                                    </>)}
                                </>,
                                cellProps: { collapsing: true }
                            }, {
                                header: 'date',
                                render: moment(item.date).format('DD/MM/YYYY'),
                            }, {
                                header: 'expires',
                                render: item.expires_at ? moment(item.expires_at).format('DD/MM/YYYY') : '-',
                            }, {
                                header: 'amount',
                                render: amountComp,
                                cellProps: { textAlign: 'right', collapsing: true }
                            }, {
                                header: showFlex ? 'template' : 'lastStatus',
                                render: showFlex ? (item.debtor ? item.debtor.template : '') : (item.lastStatus ? (<div style={{ display: 'flex', alignItems: 'center' }}>
                                    {item.flex_complete && <Popup trigger={<Icon name="exclamation triangle" color="orange" />} content="Einde flow bereikt" inverted size="tiny" />}
                                    <div style={{ flex: 1 }}>
                                        {item.lastStatus.name}<br />
                                        <small>{moment(item.last_action_at).format('DD/MM/YYYY')}</small>
                                    </div>
                                </div>) : '-'),
                                cellProps: { collapsing: true }
                            }, {
                                header: 'deadline',
                                render: item.deadline ? (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Input
                                            type="date"
                                            value={item.deadline || ''}
                                            onChange={(e: any, { value }) => this.handleDeadlineChange(value as string, index)}
                                        />
                                        {item.color && item.color === 'error' && <Icon name="exclamation circle" color="red" style={{ marginLeft: 5 }} />}
                                        {item.color && item.color === 'warning' && <Icon name="exclamation triangle" color="orange" style={{ marginLeft: 5 }} />}
                                    </div>
                                 ) : '-',
                                showIf: pending,
                            }, {
                                header: 'status',
                                render: !showFlex && !pending && selectedStatus && selectedStatus.type === 'status' ? (
                                    <div>
                                        <Input
                                            type="date"
                                            value={item.deadline || ''}
                                            onChange={(e: any, { value }) => this.handleDeadlineChange(value as string, index)}
                                        />
                                    </div>
                                ) : (<>
                                    {showFlex ? <span>{item.lastStatus && item.lastStatus.name}</span> : (<Dropdown
                                        disabled={!enabled[item.id] || enabled[item.id].state !== true}
                                        options={statusOptions}
                                        selection
                                        selectOnBlur={false}
                                        onChange={(e: any, { value }) => this.handleStatusChange(value as number, index)}
                                        value={pending ? item.last_status_id : item.invoice_status_id}
                                    />)}
                                    {!showFlex && pending && <small style={{ display: 'block', marginTop: 2, marginLeft: 3 }}>{moment(item.last_action_at).format('DD/MM/YYYY')}</small>}
                                </>),
                                cellProps: { collapsing: true }
                            }, {
                                header: 'debtorStatus',
                                render: item.debtor
                                    ? (this.state.editInline !== item.debtor.id
                                        ? item.debtor.status
                                        : <Input
                                            value={item.debtor.status || ''}
                                            id={`inline-deb-${item.debtor.id}`}
                                            onChange={(e: any, { value }) => this.handleDebtorStatusChange(value, index)}
                                            onBlur={() => item.debtor && this.saveDebtorStatus(item.debtor)}
                                        />)
                                    : '',
                                cellProps: {
                                    onClick: () => this.editInline(item.debtor ? item.debtor.id : 0),
                                }
                            }, {
                                header: 'customerOwner',
                                render: item.debtor
                                    ? (item.debtor.customer_owner || '')
                                    : '',
                            }],
                            actions: showFlex ? [
                                <AdvancedTableAction 
                                    key={`action-1-${item.id}`}
                                    iconName="search"
                                    text={<a href={`${config.baseUrl}actions-list/flex/${item.id}/preview?_token=${getToken()}`} target="_blank">
                                        Voorbeeld
                                    </a>}
                                />,
                            ] : [],
                        }
                    }}
                    footer={[{
                        id: 'amount',
                        content: money(totals.amount),
                    }]}
                    onFilter={this.fetch}
                    isLoading={isLoading}
                    pagination={pagination}
                    extraFilter={<Menu.Item>
                        <Checkbox
                            toggle
                            label={t('actionlist.only_expired')}
                            checked={this.state.expiredDeadline}
                            onChange={(e: any, data: any) => this.setState({ expiredDeadline: data.checked }, this.fetch)}
                        />
                    </Menu.Item>}
                    afterFilter={pagination && pagination.total > 0 && !pending && (
                        <div style={{ paddingTop: 5, paddingBottom: 15 }}>
                            <Checkbox
                                label={`${t('actionlist.select_all')} (${pagination.total})`}
                                onChange={(e: any, data: any) => this.setState({ selectAll: data.checked })}
                                checked={this.state.selectAll}
                            />
                        </div>
                    )}
                />
                {(enabledCount > 0 || this.state.selectAll) && <FloatingButtons
                    btnText={`${showFlex ? 'Flex template' : 'Actie'}${(this.state.selectAll ? pagination.total : enabledCount) === 1 ? '' : 's'} ${showFlex ? 'verzenden' : 'uitvoeren'} (${this.state.selectAll ? pagination.total : enabledCount})`}
                    onSaveExit={() => this.doActions(false)}
                    btnTextSave={showFlex ? t('actionlist.reset_selected') : t('actionlist.delete_selected')}
                    onSave={() => this.doActions(true)}
                >
                    {!showFlex && !this.state.selectAll && <Dropdown
                        placeholder={t('actionlist.edit_all')}
                        options={statusOptions}
                        selection
                        selectOnBlur={false}
                        onChange={(e: any, { value }) => this.changeAll(value as number)}
                        value={this.state.changeAll}
                        style={{ marginRight: 10 }}
                    />}
                </FloatingButtons>}
            </div>
        );
    }
}

export default withTranslation('common')(connect((state: AppState) => ({
    auth: state.auth.user,
}))(ActionsListView));
