import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Toastify from 'toastify';
import api from '../../api';
import { ApiAuthObject } from '../../api/auth';
import { ApiCustomerObject, licenseTypes } from '../../api/customers';
import AdvancedTable, { AdvancedTableAction, FilterObject } from '../../components/AdvancedTable';
import DomainLink from '../../components/DomainLink';
import PageHeader from '../../components/Layout/PageHeader';
import { AppState } from '../../store';

interface CustomersListViewProps {
    auth?: ApiAuthObject;
}

interface CustomersListViewState {
    customers: ApiCustomerObject[],
    pagination?: any,
    isLoading: boolean,
    activeFilter: number[],
    filter?: FilterObject,
}

class CustomersListView extends React.Component<CustomersListViewProps & WithTranslation, CustomersListViewState> {
    constructor(props: any) {
        super(props);
    
        this.state = {
            customers: [],
            pagination: undefined,
            isLoading: false,
            activeFilter: [1],
            filter: undefined,
        };
    }

    /**
     * Mount
     */
    componentDidMount = () => {
        // this.fetch();
    }

    /**
     * Fetch
     */
    fetch = (filter?: FilterObject) => {
        const { activeFilter } = this.state;
        this.setState({ isLoading: true });
        if (filter) {
            this.setState({ filter });
        }

        api.listCustomers(filter || this.state.filter, ['reseller'], false, activeFilter).then(({ data }) => {
            this.setState({
                customers: data.data,
                pagination: data.meta,
                isLoading: false,
            });
        });
    }

    /**
     * Delete customer
     */
    deleteCustomer = (id: number) => {
        const { t } = this.props;
        api.deleteCustomer(id).then(() => {
            Toastify.success(t('customers.deleted'));
            this.fetch();
        });
    }

    // render
    render = () => {
        const { auth, t } = this.props;
        const { pagination, customers, isLoading } = this.state;

        return (
            <div className="pageContainer">
                <PageHeader title="Klanten">
                    {auth && [1, 2, 7].includes(auth.role_id) && (<DomainLink to="/customers/create">
                        <Button primary>{t('customers.add_customer')}</Button>
                    </DomainLink>)}
                </PageHeader>

                <AdvancedTable
                    name="customer-index"
                    items={customers}
                    header={[{
                        id: 'type',
                        title: 'Type',
                    }, {
                        id: 'name',
                        title: t('customers.name'),
                        sortable: 'name',
                    }, {
                        id: 'reseller',
                        title: 'White Label',
                        showIf: auth && ![2, 7].includes(auth.role_id),
                    }, {
                        id: 'license_type',
                        title: t('customers.license_type'),
                    }, {
                        id: 'status',
                        title: 'Status',
                        filter: [{ name: t('customers.active'), id: 1 }, { name: t('customers.inactive'), id: 0 }],
                        onFilter: (values) => this.setState({ activeFilter: values }, this.fetch),
                    }]}
                    renderRow={(item: ApiCustomerObject) => ({
                        cells: [{
                            header: 'type',
                            render: item.type === 1 ? 'Klant' : 'Prospect',
                        }, {
                            header: 'name',
                            render: <DomainLink primary to={`/customers/${item.id}/edit`}>{item.name}</DomainLink>,
                        }, {
                            header: 'reseller',
                            render: item.reseller ? <DomainLink primary to={`/resellers/${item.reseller_id}/edit`}>
                                {item.reseller.name}
                            </DomainLink> : '-',
                            showIf: auth && ![2, 7].includes(auth.role_id),
                        }, {
                            header: 'license_type',
                            render: licenseTypes[item.license_type],
                        }, {
                            header: 'type',
                            render: item.type === 1 ? 'Klant' : 'Prospect',
                        }, {
                            header: 'status',
                            render: item.is_active ? t('customers.active') : t('customers.inactive'),
                        }],
                        actions: [
                            <AdvancedTableAction 
                                key={`action-1-${item.id}`}
                                to={`/customers/${item.id}/edit`}
                                iconName="pencil"
                                text={t('general.edit')}
                            />,
                            <AdvancedTableAction key={`action-2-${item.id}`} divider />,
                            <AdvancedTableAction 
                                key={`action-6-${item.id}`}
                                iconName="trash alternate outline"
                                text={t('general.delete')}
                                onConfirm={() => this.deleteCustomer(item.id)}
                                confirmContent={t('customers.confirm_delete')}
                            />,
                        ]
                    })}
                    onFilter={this.fetch}
                    isLoading={isLoading}
                    pagination={pagination}
                />
            </div>
        );
    }
}

export default withTranslation('common')(connect((state: AppState) => ({
   auth: state.auth.user,
}))(CustomersListView));
