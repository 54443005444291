import { findIndex } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { Progress, Segment } from 'semantic-ui-react';
import { request } from '../../api';
import { number } from '../../lib/utils';
import styles from './styles.module.scss';

const Jobs: FC = (): JSX.Element | null => {
    const [ jobs, setJobs ] = useState<any[]>([]);

    const fetch = () => {
        request.get('job-status').then(({ data }) => {
            setJobs(data);
        });
    }
    useEffect(() => fetch(), []);

    useEffect(() => {
        const updateJob = ({ detail }: any): void => {
            let newJobs: any[] = [ ...jobs ];
            const index = findIndex(jobs, { id: detail.id });
            
            if (index === -1 && detail.status !== 2) {
                newJobs.push(detail);
            } else {
                if (detail.status === 2) {
                    newJobs = newJobs.filter((o, i) => i !== index);
                } else {
                    newJobs[index] = detail;
                }
            }
            setJobs(newJobs);
        }

        window.addEventListener('socket_job', updateJob, true);
        return () => window.removeEventListener('socket_job', updateJob, true);
    }, [jobs]);

    if (jobs.length <= 0) {
        return null;
    }

    return (
        <div className={styles.container}>
            {jobs.map((job) => (
                <Segment key={`job-${job.id}`}>
                    <div>
                        <span
                            className={[
                                styles.status,
                                job.status === 0 ? styles.pending : '',
                                job.status === 1 ? styles.inProgress : '',
                                job.status === 2 ? styles.done : '',
                                job.status > 2 ? styles.error : '',
                            ].join(' ')}
                        />
                        <p>{job.action}</p>
                        <span>{number(job.current)} / {number(job.total)}</span>
                    </div>
                    <Progress
                        size="tiny"
                        percent={job.total > 0 ? Math.ceil((job.current / job.total) * 100) : 0}
                        style={{ margin: 0 }}
                    />
                </Segment>
            ))}
        </div>
    );
}

export default Jobs;
