import { AxiosPromise } from 'axios';
import { request } from './index';

export interface ApiInvoiceStatusObject {
    id: number,
    type: string,
    license_type: string,
    name: string,
}

export default {
    list: (all?: boolean): AxiosPromise => {
        return request.get(`invoice-statuses?all=${all ? 1 : 0}`);
    },
};
