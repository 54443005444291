import debounce from 'lodash/debounce';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, Form, Grid, Header, Icon, Segment, SemanticICONS, Table } from 'semantic-ui-react';
import Toastify from 'toastify';
import api, { request } from '../../api';
import { ApiAuthObject } from '../../api/auth';
import { AppState } from '../../store';
import styles from '../../components/Timeline/styles.module.scss';
import moment from 'moment';
import { ApiCustomerTimelineObject } from '../../api/customers';
import { money } from '../../lib/utils';
import MoneyInput from '../../components/MoneyInput';
import { ApiContactObject } from '../../api/contacts';
import { FilterObject } from '../../components/AdvancedTable';

interface DossierViewProps {
    auth: ApiAuthObject,
    customerId: number,
}

interface DossierViewState {
    confirm: any,
    dossier: any[],
    isLoading: boolean,
    note: Partial<ApiCustomerTimelineObject>,
    upload?: File,
    contacts: ApiContactObject[],
    create: boolean,
}

class DossierView extends React.Component<DossierViewProps, DossierViewState> {
    private calendarTimer: any;
    private suggest: any;

    constructor(props: any) {
        super(props);

        this.state = {
            confirm: false,
            dossier: [],
            note: { type: 'Notitie' },
            isLoading: false,
            upload: undefined,
            contacts: [],
            create: false,
        };
    }

    /**
     * Mount
     */
    componentDidMount = () => {
        api.listContacts({ customer_id: this.props.customerId } as Partial<FilterObject>).then(({ data }) => {
            this.setState({
                contacts: data.data,
            });
        });

        this.fetch();

        this.suggest = debounce((query: string) => {
            const { customerId } = this.props;
            request.get(`customers/${customerId}/suggest-product?q=${query}`).then(({ data }) => {
                if (data === '') return;
                this.setState({
                    note: {
                        ...this.state.note,
                        product: data,
                    }
                })
                const input = document.querySelector('#product-suggest') as HTMLInputElement;
                if (input) input.setSelectionRange(query.length, data.length);
            });
        }, 500);
    }

    /**
     * Fetch
     */
    fetch = () => {
        const { customerId } = this.props;
        
        api.listCustomerTimeline(customerId).then(({ data }) => {
            this.setState({
                dossier: data,
            });
        })
    }

    handleInput = (e: any, { name, value }: any): void => {
        this.setState({
            note: {
                ...this.state.note,
                [name]: value,
            }
        });
    }

    /**
     * Delete item
     */
     deleteTimeline = (timeline: ApiCustomerTimelineObject) => {
        // api.deleteCustomerTimeline(timeline.id).then(() => {
        //     this.setState({ confirm: false });
        // });
    }

    addNote = () => {
        const { customerId } = this.props;
        const { note, upload } = this.state;
        
        api.addCustomerTimeline(customerId, note, upload).then(() => {
            Toastify.success(`Dossier ${note.id ? 'gewijzigd' : 'toegevoegd'}`);

            this.setState({
                note: {},
                upload: undefined,
                create: false,
            }, this.fetch);
        });
    }

    handleProduct = (e: any, { value }: any): void => {
        this.setState({
            note: {
                ...this.state.note,
                product: value,
            }
        });
        this.suggest(value);
    }

    // render
    render = () => {
        const { auth } = this.props;
        const { dossier, note, isLoading } = this.state;

        return (
            <div className="pageContainer">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={7}>
                            <Segment>
                                <FullCalendar
                                    plugins={[ dayGridPlugin, interactionPlugin ]}
                                    initialView="dayGridMonth"
                                    locale="nl"
                                    height="auto"
                                    // dayHeaderFormat={{
                                    //     weekday: 'long',
                                    // }}
                                    events={(info, successCallback) => {
                                        clearTimeout(this.calendarTimer);
                                        this.calendarTimer = setTimeout(() => {
                                            request.get(`customers/${this.props.customerId}/dossier?startDate=${moment(info.startStr).format('YYYY-MM-DD')}&endDate=${moment(info.endStr).format('YYYY-MM-DD')}`).then(({ data }) => {
                                                successCallback(data);
                                            });
                                        }, 500);
                                    }}
                                    dateClick={(info) => {
                                        this.setState({
                                            note: {
                                                ...this.state.note,
                                                date: info.dateStr,
                                                type: 'Afspraak',
                                            },
                                            create: true,
                                        });
                                    }}
                                    selectable
                                    eventClick={(info) => {
                                        info.jsEvent.preventDefault();
                                        // dof for date info.event.start
                                    }}
                                />
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={9}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ marginBottom: -10 }}>
                                    <Header as="h3">
                                        Dossier
                                    </Header>
                                </div>
                                <Button
                                    primary
                                    content="Item toevoegen"
                                    onClick={() => this.setState({ create: true })}
                                />
                            </div>
                            {this.state.create && <Segment>
                                <Form onSubmit={() => this.addNote()} loading={isLoading}>
                                    <Form.Select
                                        label="Type"
                                        required
                                        name="type"
                                        options={['Notitie', 'Afspraak', 'Offerte', 'Deal', 'No-deal', 'Contract'].map((o) => ({
                                            key: o,
                                            text: o,
                                            value: o,
                                        }))}
                                        value={note.type || 'Notitie'}
                                        onChange={this.handleInput}
                                    />
                                    <Form.Input
                                        label="Onderwerp"
                                        required
                                        name="subject"
                                        value={note.subject || ''}
                                        onChange={this.handleInput}
                                    />
                                    <Form.TextArea
                                        label="Inhoud"
                                        rows={3}
                                        name="content"
                                        value={note.content || ''}
                                        onChange={this.handleInput}
                                    />
                                    <Form.Input
                                        label="Product/Dienst"
                                        name="product"
                                        value={note.product || ''}
                                        onChange={this.handleProduct}
                                        id="product-suggest"
                                    />
                                    <Form.Input
                                        label="Datum"
                                        name="date"
                                        type="date"
                                        value={note.date || ''}
                                        onChange={this.handleInput}
                                    />
                                    <Form.Select
                                        label="Contactpersoon"
                                        name="contact_id"
                                        options={this.state.contacts.map((o) => ({
                                            key: o.id,
                                            text: o.name,
                                            value: o.id,
                                        }))}
                                        placeholder="Selecteer een contactpersoon"
                                        value={note.contact_id}
                                        onChange={this.handleInput}
                                    />
                                    <Form.Field>
                                        <label>Bedrag</label>
                                        <MoneyInput
                                            label={{ basic: true, content: 'EUR' }}
                                            name="amount"
                                            onChange={this.handleInput}
                                            value={note.amount || 0.00}
                                        />
                                    </Form.Field>
                                    <Form.Input
                                        fluid
                                        type="file"
                                        label="Bijlage"
                                        onChange={(e: any) => this.setState({ upload: e.target.files[0] })}
                                    />
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            basic
                                            content="Annuleren"
                                            onClick={() => this.setState({ create: false })}
                                            type="button"
                                        />
                                        <Button primary type="submit">
                                            {note.id ? 'Wijzigen' : 'Toevoegen'}
                                        </Button>
                                    </div>
                                </Form>
                            </Segment>}

                            <div className={styles.timeline}>
                                <div className={styles.timelineLine}></div>
                                {dossier.map((timeline, i) => (
                                    <Segment className={styles.timelineItem} key={`timeline-${i}`}>
                                        <div className={styles.timelineHeader}>
                                            <Icon name={timeline.icon as SemanticICONS} style={{ fontSize: 30 }} />
                                            <div className={styles.timelineMeta}>
                                                <b>{timeline.subject}</b>
                                                {timeline.user.full_name} | {moment(timeline.created_at).format('DD/MM/YYYY')}
                                            </div>
                                            {timeline.has_file && timeline.download_url && (
                                                <a className="ui basic icon button" href={timeline.download_url} target="_blank" style={{ marginTop: -11, position: 'relative' }} rel="noopener noreferrer">
                                                    <Icon name="download" />
                                                </a>
                                            )}
                                            {auth && timeline.user_id === auth.id && (<>
                                                <Button
                                                    basic
                                                    icon="pencil"
                                                    onClick={() => this.setState({ create: true, note:  timeline })}
                                                    style={{ position: 'relative', top: -5 }}
                                                    type="button"
                                                />
                                                <Button
                                                    basic
                                                    icon="trash"
                                                    style={{ boxShadow: 'none', position: 'relative', top: -4 }}
                                                    onClick={() => this.setState({ confirm: timeline })}
                                                    type="button"
                                                />
                                                <Confirm
                                                    open={this.state.confirm ? true : false}
                                                    header="Weet u het zeker?"
                                                    onCancel={() => this.setState({ confirm: false })}
                                                    onConfirm={() => this.deleteTimeline(this.state.confirm)}
                                                    content="Weet u zeker dat u item wilt verwijderen?"
                                                    cancelButton="Annuleren"
                                                    confirmButton="Ik weet het zeker"
                                                />
                                            </>)}
                                        </div>
                                        {timeline.content ? <p>{timeline.content}</p> : null}

                                        <Table size="small" celled striped className={styles.smallTable}>
                                            <tbody>
                                                {timeline.date && (
                                                    <Table.Row>
                                                        <Table.Cell collapsing><b>Datum</b></Table.Cell>
                                                        <Table.Cell>
                                                            {moment(timeline.date).format('DD/MM/YYYY')}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )}
                                                {timeline.product && (
                                                    <Table.Row>
                                                        <Table.Cell collapsing><b>Product/Dienst</b></Table.Cell>
                                                        <Table.Cell>
                                                            {timeline.product}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )}
                                                {timeline.amount > 0 && (
                                                    <Table.Row>
                                                        <Table.Cell collapsing><b>Bedrag</b></Table.Cell>
                                                        <Table.Cell>
                                                            {money(timeline.amount)}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )}
                                                {timeline.contact && (
                                                    <Table.Row>
                                                        <Table.Cell collapsing><b>Contactpersoon</b></Table.Cell>
                                                        <Table.Cell>
                                                            {timeline.contact ? timeline.contact.name : ''}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )}
                                                {timeline.filename && (
                                                    <Table.Row>
                                                        <Table.Cell collapsing><b>Bestand</b></Table.Cell>
                                                        <Table.Cell>
                                                            {timeline.filename}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Segment>
                                ))}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default connect((state: AppState) => ({
    auth: state.auth.user,
 }))(DossierView);
 