import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import de from "./translations/de.json";
import gb from "./translations/gb.json";
import nl from "./translations/nl.json";
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';
import './index.scss';
import 'semantic-ui-css/semantic.min.css';

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'nl',
    resources: {
        de: {
            common: de,
        },
        gb: {
            common: gb,
        },
        nl: {
            common: nl,
        },
    }
});

ReactDOM.render(
    <Suspense fallback="loading">
        <Provider store={store}>
            <I18nextProvider i18n={i18next}>
                <App />
            </I18nextProvider>
        </Provider>
    </Suspense>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
