import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { getToken, request } from '../../api';
import moment from 'moment';
import AdvancedTable, { FilterObject } from '../../components/AdvancedTable';
import DomainLink from '../../components/DomainLink';
import PageHeader from '../../components/Layout/PageHeader';
import { money, number } from '../../lib/utils';
import { AppState } from '../../store';
import { ApiAuthObject } from '../../api/auth';
import { Input, Menu } from 'semantic-ui-react';
const config = require('../../config.json');

interface DebtorSubscriptiontViewProps {
    auth: ApiAuthObject,
}

interface DebtorSubscriptiontViewState {
    items: any[],
    isLoading: boolean,
    tableFilter?: any,
    till: string,
    totals: {
        amount: number,
    },
}

let subTimer: any;

class DebtorSubscriptiontView extends React.Component<DebtorSubscriptiontViewProps & WithTranslation, DebtorSubscriptiontViewState> {
    constructor(props: any) {
        super(props);
    
        this.state = {
            items: [],
            till: moment().format('YYYY-MM-DD'),
            isLoading: false,
            totals: {
                amount: 0,
            }
        };
    }

    /**
     * Fetch
     */
    fetch = (filter?: FilterObject) => {
        const f = filter || this.state.tableFilter;
        this.setState({ isLoading: true, tableFilter: f });

        request.get(`debtors/subscriptions?${qs.stringify(f)}&&till=${this.state.till}`).then(({ data }) => {
            this.setState({
                items: data.items,
                isLoading: false,
                totals: data.totals,
            });
        });
    }

    updateFilter = () => {
        clearTimeout(subTimer);
        subTimer = setTimeout(() => {
            if (this.state.till) {
                this.fetch();
            }
        }, 500);
    }

    // render
    render = () => {
        const { t } = this.props;
        const { items, isLoading, totals } = this.state;

        return (
            <div className="pageContainer">
                <PageHeader title="Contracten">
                    <a className="ui basic button" href={`${config.baseUrl}debtors/subscriptions?export=1&${qs.stringify(this.state.tableFilter)}&till=${this.state.till}&_token=${getToken()}`} target="_blank" rel="noopener noreferrer">{t('general.export')}</a>
                </PageHeader>

                <Menu style={{ marginBottom: 32 }}>
                    <Menu.Item>
                        <Input
                            placeholder="Datum"
                            transparent
                            icon="calendar"
                            iconPosition="left"
                            value={this.state.till}
                            type="date"
                            onChange={(e, data) => this.setState({ till: data.value }, this.updateFilter)}
                        />
                    </Menu.Item>
                </Menu>

                <AdvancedTable
                    name="debtorsub-index"
                    items={items}
                    header={[{
                        id: 'contract',
                        title: 'Contract',
                        sortable: 'contract',
                    }, {
                        id: 'number',
                        title: t('debtors.number'),
                        sortable: 'number',
                    }, {
                        id: 'name',
                        title: t('debtors.name'),
                        sortable: 'name',
                    }, {
                        id: 'days',
                        title: '# Dagen openstaand',
                        sortable: 'days',
                    }, {
                        id: 'amount',
                        title: 'Totaal openstaand',
                        sortable: 'amount',
                    }]}
                    renderRow={(item: any, index?: number) => ({
                        cells: [{
                            header: 'contract',
                            render: item[0],
                            cellProps: { collapsing: true }
                        }, {
                            header: 'number',
                            render: item[1],
                            cellProps: { collapsing: true }
                        }, {
                            header: 'name',
                            render: <DomainLink primary to={`/debtors/${item[5].id}/edit`}>{item[2]}</DomainLink>,
                        }, {
                            header: 'days',
                            render: number(item[3] || 0),
                            cellProps: { collapsing: true, textAlign: 'right' },
                        }, {
                            header: 'amount',
                            render: money(item[4] || 0),
                            cellProps: { collapsing: true, textAlign: 'right' },
                        }],
                        // actions: []
                    })}
                    footer={[{
                        id: 'amount',
                        content: money(totals.amount),
                    }]}
                    onFilter={this.fetch}
                    isLoading={isLoading}
                />
            </div>
        );
    }
}

export default withTranslation('common')(connect((state: AppState) => ({
    auth: state.auth.user,
}), {})(DebtorSubscriptiontView));
