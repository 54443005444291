import { createAction, handleActions } from 'redux-actions';
import { ApiAuthObject } from '../api/auth';
import { request } from '../api/index';

export interface AuthState {
    user?: ApiAuthObject,
};

export const logout = createAction('logout');
export const setAuth = createAction('setAuth');

export default handleActions<AuthState>({
    logout: (state) => {
        request.post('auth/logout').then(({ data }) => {
            if (data.status && data.status === 'logout') {
                localStorage.removeItem('token');
            } else {
                localStorage.setItem('token', JSON.stringify(data));
                window.location.href = '/cmsi360/users';
            }
        });
        
        return {
            ...state,
            user: undefined,
        }
    },
    setAuth: (state, data: any) => ({
        ...state,
        user: data.payload
    }),
}, {
    user: undefined,
});
