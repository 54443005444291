import { AxiosPromise } from 'axios';
import { each } from 'lodash';
import { request } from './index';
import { FilterObject } from '../components/AdvancedTable';

export interface ApiResellerObject {
    id: number,
    name: string,
    domain: string,
    primary_color: string,
    secondary_color: string,
    secondary_opposite_color: string,
    start_image?: string,
    logo?: string,
}

export default {
    getByDomain: (domain: string): AxiosPromise<ApiResellerObject> => {
        return request.get(`resellers/domain/${domain}`);
    },
    list: (filter?: Partial<FilterObject>): AxiosPromise => {
        const query: any = [];

        if (filter) {
            each(filter, (v, k) => {
                query.push(`${k}=${v}`);
            });
        }

        return request.get(`resellers?${query.join('&')}`);
    },
    store: (data: any): AxiosPromise<ApiResellerObject> => {
        return request.post(`resellers`, data);
    },
    patch: (id: number | string, data: any): AxiosPromise<ApiResellerObject> => {
        return request.post(`resellers/${id}`, data);
    },
    show: (id: number | string): AxiosPromise<ApiResellerObject> => {
        return request.get(`resellers/${id}`);
    },
    delete: (id: number | string): AxiosPromise => {
        return request.delete(`resellers/${id}`);
    },
};
