import React from 'react';
import { Button } from 'semantic-ui-react';
import Toastify from 'toastify';
import { WithTranslation, withTranslation } from 'react-i18next';
import api from '../../api';
import { ApiTemplateObject } from '../../api/templates';
import AdvancedTable, { AdvancedTableAction } from '../../components/AdvancedTable';
import PageHeader from '../../components/Layout/PageHeader';
import TemplatesEditView from './edit';

interface TemplatesListViewProps {
    customerId?: number,
    isFlex?: boolean,
}

interface TemplatesListViewState {
    templates: ApiTemplateObject[],
    pagination?: any,
    isLoading: boolean,
    edit: boolean | number,
}

class TemplatesListView extends React.Component<TemplatesListViewProps & WithTranslation, TemplatesListViewState> {
    constructor(props: TemplatesListViewProps & WithTranslation) {
        super(props);
    
        this.state = {
            templates: [],
            pagination: undefined,
            isLoading: false,
            edit: false,
        };
    }

    /**
     * Mount
     */
    componentDidMount = () => {
        this.fetch();
    }

    /**
     * Fetch
     */
    fetch = () => {
        const { isFlex, customerId } = this.props;
        this.setState({ isLoading: true });

        api.listTemplates(isFlex && customerId ? customerId : undefined, isFlex).then(({ data }) => {
            this.setState({
                templates: data.data,
                pagination: data.meta,
                isLoading: false,
            });
        });
    }

    deleteTemplate = (templateId: number) => {
        const { t } = this.props;

        api.deleteTemplate(templateId).then(() => {
            this.fetch();
            Toastify.success(t('templates.deleted'));
        })
    }

    // render
    render = () => {
        const { customerId, isFlex, t } = this.props;
        const { templates, isLoading, edit } = this.state;

        return edit !== false ? (
            <TemplatesEditView
                templateId={edit === true ? undefined : edit as number}
                customerId={customerId}
                isFlex={isFlex}
                onExit={() =>this.setState({ edit: false })}
            />
        ) : (
            <div className="pageContainer">
                {!customerId && <PageHeader title={t('templates.title')} />}
                {customerId && isFlex && <Button primary onClick={() => this.setState({ edit: true })}>Nieuw template</Button>}
                <AdvancedTable
                    name={`template-index${customerId ? '-customer' : ''}`}
                    items={templates}
                    header={[{
                        id: 'name',
                        title: t('users.name'),
                    }]}
                    renderRow={(item: ApiTemplateObject) => ({
                        cells: [{
                            header: 'name',
                            render: <a className="primary" href="/" onClick={(e: any) => {
                                e.preventDefault()
                                this.setState({ edit: item.id });
                            }}>{item.name}</a>,
                        }],
                        actions: [
                            <AdvancedTableAction 
                                key={`action-1-${item.id}`}
                                onClick={() => this.setState({ edit: item.id })}
                                iconName="pencil"
                                text={t('users.edit')}
                            />,
                            item.is_flex && <>
                                <AdvancedTableAction key={`action-2-${item.id}`} divider />
                                <AdvancedTableAction 
                                    key={`action-3-${item.id}`}
                                    iconName="trash alternate outline"
                                    text={t('users.delete')}
                                    onConfirm={() => this.deleteTemplate(item.id)}
                                    confirmContent={t('templates.confirm_delete')}
                                />
                            </>
                        ]
                    })}
                    isLoading={isLoading}
                    pagination={false}
                    showFilter={false}
                />
            </div>
        );
    }
}

export default withTranslation('common')(TemplatesListView);
