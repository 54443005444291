import React from 'react';
import { Button } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import DomainLink from '../DomainLink';

class FloatingButtons extends React.Component {
    // --- render
    render() {
        const { onCancel, onSave, onSaveExit, btnTextSave, btnText, zIndex, hidePush, t } = this.props;

        return (
            <>
                <div className={styles.container} style={{ zIndex: zIndex || 100 }}>
                    <div>
                        {onCancel && typeof onCancel === 'string' && <DomainLink to={onCancel} primary><Button primary>{t('general.cancel')}</Button></DomainLink>}
                        {onCancel && typeof onCancel === 'function' && <Button primary onClick={onCancel}>{t('general.cancel')}</Button>}
                    </div>
                    <div>
                        {this.props.children}
                        {onSave && <Button basic={onSaveExit !== undefined} primary={onSaveExit === undefined} onClick={onSave}>{btnTextSave || t('general.save')}</Button>}
                        {onSaveExit && <Button primary onClick={onSaveExit}>{btnText || t('general.saveexit')}</Button>}
                    </div>
                </div>
                {hidePush !== true && <div className={styles.push}></div>}
            </>
        );
    }
}

export default withTranslation('common')(FloatingButtons);
