import React from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import DomainLink from '../../components/DomainLink';

class ErrorView extends React.Component<WithTranslation> {
    // render
    render = () => {
        const { t } = this.props;

        return (
            <div style={{ maxWidth: 500, marginLeft: 'auto', marginRight: 'auto', marginTop: '8%', textAlign: 'center' }}>
                <Segment style={{ padding: 30 }}>
                    <Header as="h2">{t('error.e1')}</Header>
                    <p>{t('error.e2')}</p>
                    <p>{t('error.e3')}</p>
                    <p>
                        <DomainLink to="/">{t('error.e4')}</DomainLink>
                    </p>
                </Segment>
            </div>
        )
    }
}

export default withTranslation('common')(ErrorView);
