import React from 'react';
import qs from 'qs';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Segment, Form, Header, Message } from 'semantic-ui-react';
import background from '../../assets/start-image.jpg';
import styles from './styles.module.scss';
import api from '../../api';
import { ApiResellerObject } from '../../api/resellers';
import Logo from '../../components/Logo';
import { preloadImage } from '../../lib/utils';
import { setAuth } from '../../reducers/auth';
import { setReseller } from '../../reducers/resellers';
import { AppState } from '../../store';
import { ApiAuthObject } from '../../api/auth';
import { WithTranslation, withTranslation } from 'react-i18next';

interface LoginViewProps extends RouteComponentProps<{ domain: string }> {
    reseller?: ApiResellerObject,
    setAuth: (data: ApiAuthObject) => void,
    setReseller: (reseller: ApiResellerObject) => void,
}

interface LoginViewState {
    error: boolean,
    token: string,
    opacity: number,
    reset: boolean,
    isLoading: boolean,
    code: string,
    emailAddress: string,
    password: string,
    passwordConfirm: string,
    verify: string | boolean,
    message: string | boolean,
    setPassword: boolean,
    qr?: string,
}

class LoginView extends React.Component<LoginViewProps & WithTranslation, LoginViewState> {
    constructor(props: LoginViewProps & WithTranslation) {
        super(props);
        
        this.state = {
            token: '',
            error: false,
            opacity: 0,
            reset: false,
            isLoading: false,
            code: '',
            emailAddress: '',
            password: '',
            passwordConfirm: '',
            verify: false,
            message: false,
            setPassword: false,
            qr: undefined,
        };
    }

    /**
     * Mount
     */
    componentDidMount = () => {
        const query = qs.parse(window.location.search.substring(1));
        if (query.access_token && query.api_key) {
            localStorage.setItem('token', JSON.stringify(query));
            window.location.href = '/';
        }

        if (window.location.search.indexOf('signature') !== -1) {
            this.setState({ setPassword: true });
        }

        api.getResellerByDomain(this.props.match.params.domain).then(({ data }) => {
            this.props.setReseller(data);
            preloadImage(data.start_image || background, () => this.setState({ opacity: 1 }));
        }).catch(() => {
            api.showReseller(1).then(({ data }) => {
                this.props.setReseller(data);
                preloadImage(data.start_image || background, () => this.setState({ opacity: 1 }));
            });
        });
    }

    /**
     * Login
     */
    login = () => {
        this.setState({ setPassword: false, error: false, isLoading: true });

        if (this.state.reset) {
            this.resetPassword();
        } else {
            api.login(this.state.emailAddress, this.state.password).then(({ data }) => {
                if (data.verify !== false) {
                    this.setState({
                        token: JSON.stringify(data),
                        verify: data.verify,
                        isLoading: false,
                    });
                } else {
                    localStorage.setItem('token', JSON.stringify(data));
                    window.location.href = `/${data.reseller ? data.reseller.domain : 'cmsi360'}`;
                }
            }).catch(() => this.setState({ error: true, isLoading: false }));
        }
    }

    /**
     * Reset password
     */
    resetPassword = () => {
        api.resetPassword(this.state.emailAddress).then(() => {
            this.setState({
                emailAddress: '',
                password: '',
                error: false,
                isLoading: false,
                message: this.props.t('login.msg_reset') as string,
            });
        });
    }

    /**
     * Set new password
     */
    setNewPassword = () => {
        this.setState({ error: false, isLoading: true });

        if (this.state.password === '' || this.state.password !== this.state.passwordConfirm) {
            this.setState({ error: true, isLoading: false });
        } else {
            api.setNewPassword(this.state.password).then(({ data }) => {
                this.setState({
                    qr: data.qr,
                    isLoading: false,
                    emailAddress: data.email_address,
                    setPassword: data.qr ? true : false,
                });

                if (!data.qr) {
                    this.login();
                }
            }).catch(() => this.setState({ error: true, isLoading: false }));
        }
    }

    /**
     * Verify secret
     */
    verifyAndContinue = () => {
        this.setState({ isLoading: true });
        localStorage.setItem('token', this.state.token);

        api.verifySecretCode(this.state.code).then(({ data }) => {
            this.props.setAuth(data);
            if (data.reseller) {
                this.props.setReseller(data.reseller);
            }
            window.location.href = `/${data.reseller ? data.reseller.domain : 'cmsi360'}`;
        }).catch(() => this.setState({ error: true, isLoading: false }));
    }

    // render
    render = () => {
        const { reseller, t, i18n } = this.props;
        const { opacity, reset, emailAddress, password, error, verify, code, isLoading, message, setPassword, passwordConfirm, qr } = this.state;

        if (!reseller) {
            return null;
        }

        return (
            <div
                className={styles.container}
                style={{
                    backgroundImage: `url(${reseller.start_image || background})`,
                    opacity,
                }}
            >
                {setPassword && qr && (
                    <Segment className={styles.form} padded loading={isLoading}>
                        <Logo />
                        <Header style={{ marginTop: 10 }}>Geheime code</Header>
                        <p>{t('login.msg_qr')}</p>
                        <img src={qr} alt="" style={{ maxWidth: '200px', display: 'block', marginBottom: 10 }} />
                        <Form.Button onClick={this.login} primary>{t('login.qr_confirm')}</Form.Button>
                    </Segment>
                )}
                {setPassword && !qr && (
                    <Segment className={styles.form} padded loading={isLoading}>
                        <Logo />
                        <Header style={{ marginTop: 10 }}>{t('login.set_password')}</Header>
                        <p>{t('login.fill_in_password')}</p>
                        <Form onSubmit={this.setNewPassword}>
                            <Form.Input
                                error={error}
                                fluid
                                icon="lock"
                                iconPosition="left"
                                placeholder={t('login.password')}
                                value={password}
                                onChange={(e: any, data: any) => this.setState({ password: data.value })}
                                type="password"
                            />
                            <Form.Input
                                error={error}
                                fluid
                                icon="lock"
                                iconPosition="left"
                                placeholder={t('login.confirm_password')}
                                value={passwordConfirm}
                                onChange={(e: any, data: any) => this.setState({ passwordConfirm: data.value })}
                                type="password"
                            />
                            <div className={styles.buttons}>
                                <a className="primary" href={window.location.href} onClick={(e: any) => {
                                    e.preventDefault();
                                    this.setState({ setPassword: false, error: false });
                                }}>{t('login.cancel')}</a>
                                <Form.Button type="submit" primary>{t('login.save')}</Form.Button>
                            </div>
                        </Form>
                    </Segment>
                )}
                {verify && !setPassword && (
                    <Segment className={styles.form} padded loading={isLoading}>
                        <Logo />
                        <Header style={{ marginTop: 10 }}>{t('login.secret_code')}</Header>
                        <p>
                            {t('login.fill_in_secret_code_start')} {verify === 'email' ? t('login.fill_in_secret_code_email') : t('login.fill_in_secret_code_app')} {t('login.fill_in_secret_code_end')}
                        </p>
                        <Form onSubmit={this.verifyAndContinue}>
                            <Form.Input
                                error={error}
                                fluid
                                icon="lock"
                                iconPosition="left"
                                placeholder={t('login.secret_code')}
                                value={code}
                                onChange={(e: any, data: any) => this.setState({ code: data.value })}
                            />
                            <div className={styles.buttons}>
                                <a className="primary" href={window.location.href} onClick={(e: any) => {
                                    e.preventDefault();
                                    this.setState({ verify: false });
                                }}>{t('login.cancel')}</a>
                                <Form.Button type="submit" primary>{t('login.verify')}</Form.Button>
                            </div>
                        </Form>
                    </Segment>
                )}
                {!verify && !setPassword && (
                    <Segment className={styles.form} padded loading={isLoading}>
                        <Logo />
                        <Header style={{ marginTop: 10 }}>
                            {reset ? t('login.set_password') : t('login.title')}
                        </Header>
                        {message && <Message success>{message}</Message>}
                        {/* {!reset && <Message error>Door een storing bij Mandrill/Outlook is het in sommige gevallen niet mogelijk in te loggen. U verneemt zo spoedig mogelijk nader van ons. Excuses voor het ongemak.</Message>} */}
                        {reset && <p>{t('login.msg_request_reset')}</p>}
                        <Form onSubmit={this.login}>
                            <Form.Input
                                error={error}
                                fluid
                                icon="envelope"
                                iconPosition="left"
                                placeholder={t('login.email_address')}
                                value={emailAddress}
                                onChange={(e: any, data: any) => this.setState({ emailAddress: data.value })}
                            />
                            {reset ? (
                                <div className={styles.buttons}>
                                    <a className="primary" href={window.location.href} onClick={(e: any) => {
                                        e.preventDefault();
                                        this.setState({ reset: false, message: false });
                                    }}>{t('login.cancel')}</a>
                                    <Form.Button type="submit" primary>{t('login.login')}</Form.Button>
                                </div>
                            ) : (
                                <>
                                <Form.Input
                                    error={error}
                                    fluid
                                    icon="lock"
                                    iconPosition="left"
                                    placeholder={t('login.password')}
                                    type="password"
                                    value={password}
                                    onChange={(e: any, data: any) => this.setState({ password: data.value })}
                                />
                                <div className={styles.buttons}>
                                    <a className="primary" href={window.location.href} onClick={(e: any) => {
                                        e.preventDefault();
                                        this.setState({ reset: true });
                                    }}>{t('login.forgot_password')}</a>
                                    <Form.Button type="submit" primary>{t('login.title')}</Form.Button>
                                </div>
                                </>
                            )}
                        </Form>
                    </Segment>
                )}
            </div>
        );
    }
}

export default withTranslation('common')(withRouter(connect((state: AppState) => ({
    reseller: state.resellers.reseller,
}), {
    setAuth,
    setReseller,
})(LoginView)));
