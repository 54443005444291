import { AxiosPromise } from 'axios';
import { each } from 'lodash';
import { request } from './index';
import { ApiResellerObject } from './resellers';
import { FilterObject } from '../components/AdvancedTable';
import { ApiUserObject } from './users';
import { ApiContactObject } from './contacts';

export type LicenseType = 'deb' | 'inc' | 'deb-inc' | 'flex'; 
export const licenseTypes = {
    'deb': 'Debiteuren',
    'inc': 'Incassobeheer',
    'deb-inc': 'Debiteuren- en incassobeheer',
    'flex': 'Flex workflow',
}

export type IncassoType = 'costs' | 'perc' | 'wik'; 
export const incassoTypes = {
    'costs': 'Vast bedrag',
    'perc': 'Vast percentage',
    'wik': 'Flexibel percentage',
}

export interface ApiCustomerTimelineObject {
    id: number,
    date?: string,
    user_id?: number,
    user?: ApiUserObject,
    customer_id: number,
    customer?: ApiCustomerObject,
    contact_id?: number,
    contact?: ApiContactObject,
    type: string,
    product: string,
    subject: string,
    content: string,
    created_at: string,
    has_file: boolean,
    filename?: string,
    download_url?: string,
    amount: number,
}

export interface ApiCustomerObject {
    id: number,
    hash_id?: string,
    contracts: [],
    reseller_id?: number,
    reseller?: ApiResellerObject,
    number: number,
    license_type: LicenseType,
    incasso_type: IncassoType,
    incasso_value: number,
    name: string,
    street?: string,
    house_number?: string,
    house_number_suffix?: string,
    zipcode?: string,
    city?: string,
    country?: string,
    contact_name?: string,
    contact_telephone_number?: string,
    contact_email_address?: string,
    debit_sender?: string,
    debit_subject?: string,
    debit_subject_courant?: string,
    debit_display_name?: string,
    incasso_sender?: string,
    incasso_subject?: string,
    incasso_subject_courant?: string,
    incasso_display_name?: string,
    bank_iban?: string,
    bank_name?: string,
    bank_bic?: string,
    support_contact_id?: number,
    daily_sync: boolean,
    interest_rate: number,
    action_interval: number,
    combine_claim_box: boolean,
    is_active: boolean,
    support_name?: string,
    support_telephone_number?: string,
    support_email_address?: string,
    statuses: number[],
    intervals?: {
        [key: string]: any,
    },
    logo?: string,
    twinfield_fax?: string,
    twinfield_range?: string,
    send_cc: boolean,
    cc?: string,
    dso: number,
    currency: string,
    payment_provider?: string,
    payment_provider_key?: string,
    reset_incasso: boolean,
    interest_min?: number,
    interest_max?: number,
    admin_costs: number,
    account_owner_id?: number,
    owner_id?: number,
    type: number,
    has_flex_template?: boolean,
    default_lang: string,
    linked: number[],
}

export default {
    delete: (id: number | string): AxiosPromise => {
        return request.delete(`customers/${id}`);
    },
    getNewNumber: (): AxiosPromise<number> => {
        return request.get('customers/new-number');
    },
    list: (filter?: Partial<FilterObject>, include?: string[], all?: boolean, status?: number[]): AxiosPromise => {
        const query: any = [];

        if (filter) {
            each(filter, (v, k) => {
                query.push(`${k}=${v}`);
            });
        }

        if (status) {
            query.push(`status=${status.join(',')}`);
        }
        
        return request.get(`customers?${query.join('&')}${include ? `&with=${include.join(',')}` : ''}&all=${all ? 1 : 0}`);
    },
    store: (data: any): AxiosPromise<ApiCustomerObject> => {
        return request.post(`customers`, data);
    },
    patch: (id: number | string, data: any): AxiosPromise<ApiCustomerObject> => {
        return request.post(`customers/${id}`, data);
    },
    show: (id: number | string): AxiosPromise<ApiCustomerObject> => {
        return request.get(`customers/${id}`);
    },
    addTimeline: (id: number, note: Partial<ApiCustomerTimelineObject>, file?: File): AxiosPromise => {
        const data = new FormData();
        data.append('id', `${note.id || ''}`);
        data.append('date', `${note.date || ''}`);
        data.append('type', `${note.type || ''}`);
        data.append('subject', `${note.subject || ''}`);
        data.append('content', `${note.content || ''}`);
        data.append('amount', `${note.amount || ''}`);
        data.append('product', `${note.product || ''}`);
        data.append('contact_id', `${note.contact_id || ''}`);
        if (file) {
            data.append('file', file);
        }
        return request.post(`customers/${id}/add-note`, data);
    },
    listTimeline: (id: number): AxiosPromise => {
        return request.get(`customers/${id}/dossier`);
    }
};
