import React from 'react';
import Toastify from 'toastify';
import { Segment, Form, Button } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import api from '../../api';

interface AddNoteProps {
    onAdd?: (note: string, upload?: File) => void,
    invoiceId?: number,
}

interface AddNoteState {
    note: string,
    upload?: File,
}

class AddNote extends React.Component<AddNoteProps & WithTranslation, AddNoteState> {
    constructor(props: AddNoteProps & WithTranslation) {
        super(props);
    
        this.state = {
            note: '',
            upload: undefined,
        }
    }

    addNote = () => {
        const { invoiceId, onAdd, t } = this.props;
        const { note, upload } = this.state;
        if (!invoiceId) {
            if (onAdd) onAdd(note, upload);
            return;
        }

        api.addInvoiceNote(invoiceId, note, upload).then(() => {
            this.setState({
                note: '',
                upload: undefined,
            }, () => onAdd && onAdd(note, upload));

            Toastify.success(t('invoices.note_added'));
        });
    }

    render = () => {
        const { t } = this.props;

        return (
            <Segment>
                <Form.TextArea
                    label={t('invoices.note')}
                    rows={3}
                    value={this.state.note}
                    onChange={(e: any, data: any) => this.setState({ note: data.value })}
                />
                <Form.Input
                    fluid
                    type="file"
                    label={t('invoices.attachments')}
                    onChange={(e: any) => this.setState({ upload: e.target.files[0] })}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button primary onClick={this.addNote} type="button">{t('invoices.add')}</Button>
                </div>
            </Segment>
        );
    }
}

export default withTranslation('common')(AddNote);
