import React from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import { Divider, Menu, Input, Dropdown } from 'semantic-ui-react';
import api, { getToken } from '../../api';
import { AppState } from '../../store';
import { ApiAuthObject } from '../../api/auth';
const config = require('../../config.json');

interface VdpProps {
    auth?: ApiAuthObject,
}

interface VdpState {
    invoiceStatusOptions: any[],
    selectedInvoiceStatusses: any[],
    from: string,
    till: string,
    days: number,
}

class Vdp extends React.Component<VdpProps, VdpState>
{
    constructor(props: VdpProps) {
        super(props);
    
        this.state = {
            invoiceStatusOptions: [],
            selectedInvoiceStatusses: [],
            from: '',
            till: '',
            days: 60,
        };
    }

    componentDidMount = (): void => {
        api.listStatuses(true).then(({ data }) => {
            const invoiceStatusOptions = data.map((s: any) => ({
                id: `status-${s.id}`,
                text: s.name,
                value: s.id,
            }));

            this.setState({
                invoiceStatusOptions,
            })
        });
    }

    render = (): JSX.Element | null => {
        const { days, from, till, selectedInvoiceStatusses } = this.state;

        const exportDebStatus = qs.stringify({
            from, till, status: selectedInvoiceStatusses
        });
        const exportRi = qs.stringify({
            days
        });

        return this.props.auth && this.props.auth.super_login ? (<>
            <Divider horizontal style={{ marginTop: 32 }} />
            <Menu>
                <Dropdown
                    item
                    placeholder="Factuurstatus"
                    onChange={(e, data) => this.setState({
                        selectedInvoiceStatusses: data.value as any,
                    })}
                    multiple
                    options={this.state.invoiceStatusOptions}
                    value={this.state.selectedInvoiceStatusses}
                />
                <Menu.Item>
                    <Input
                        placeholder="Periode van"
                        transparent
                        icon="calendar"
                        iconPosition="left"
                        value={this.state.from}
                        type="date"
                        onChange={(e, data) => this.setState({ from: data.value })}
                    />
                </Menu.Item>
                <Menu.Item>
                    <Input
                        placeholder="Periode van"
                        transparent
                        icon="calendar"
                        iconPosition="left"
                        value={this.state.till}
                        type="date"
                        onChange={(e, data) => this.setState({ till: data.value })}
                    />
                </Menu.Item>
                <Menu.Item>
                    <a
                        href={`${config.baseUrl}dashboard/debstatus-export?${exportDebStatus}&_token=${getToken()}`}
                        className="ui basic button"
                        rel="noopener noreferer"
                        target="_blank"
                    >Exporteren</a>
                </Menu.Item>
                <Menu.Menu position='right'>
                    <Menu.Item>
                        Factuurstatus marap
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Menu style={{ marginBottom: 32 }}>
                <Menu.Item>
                    <Input
                        placeholder="Periode van"
                        transparent
                        icon="calendar"
                        iconPosition="left"
                        value={this.state.from}
                        type="date"
                        onChange={(e, data) => this.setState({ from: data.value })}
                    />
                </Menu.Item>
                <Menu.Item>
                    <Input
                        placeholder="Periode van"
                        transparent
                        icon="calendar"
                        iconPosition="left"
                        value={this.state.till}
                        type="date"
                        onChange={(e, data) => this.setState({ till: data.value })}
                    />
                </Menu.Item>
                <Menu.Item>
                    <a
                        href={`${config.baseUrl}dashboard/debvdp-export?${exportDebStatus}&_token=${getToken()}`}
                        className="ui basic button"
                        rel="noopener noreferer"
                        target="_blank"
                    >Exporteren</a>
                </Menu.Item>
                <Menu.Menu position='right'>
                    <Menu.Item>
                        Debiteurstatus marap
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
            <Menu style={{ marginBottom: 32 }}>
                <Menu.Item>
                    <Input
                        placeholder="Aantal dagen open"
                        transparent
                        value={this.state.days}
                        type="number"
                        onChange={(e, data) => this.setState({ days: parseInt(data.value) })}
                    />
                </Menu.Item>
                <Menu.Item>
                    <a
                        href={`${config.baseUrl}dashboard/ri-export?${exportRi}&_token=${getToken()}`}
                        className="ui basic button"
                        rel="noopener noreferer"
                        target="_blank"
                    >Exporteren</a>
                </Menu.Item>
                <Menu.Menu position='right'>
                    <Menu.Item>
                        RI &gt; 60
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
        </>) : null;
    }
}

export default connect((state: AppState) => ({
    auth: state.auth.user,
}))(Vdp);
