import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Segment, Form, Grid, Header, Checkbox, Message, Button, Icon } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Toastify from 'toastify';
import { AppState } from '../../store';
import api from '../../api';
import { redirect } from '../../lib/utils'; 
import { ApiDebtorObject } from '../../api/debtors';
import FloatingButtons from '../../components/FloatingButtons';
import PageHeader from '../../components/Layout/PageHeader';
import InvoicesList from '../InvoicesView';
import MoneyInput from '../../components/MoneyInput';
import { ApiAuthObject } from '../../api/auth';


interface DebtorsEditViewProps extends RouteComponentProps<{ id?: string }> {
    auth: ApiAuthObject,
}

interface DebtorsEditViewState {
    debtor: Partial<ApiDebtorObject>,
    isLoading: boolean,
    errors?: {
        [key: string]: any,
    },
}

class DebtorsEditView extends React.Component<DebtorsEditViewProps & WithTranslation, DebtorsEditViewState> {
    constructor(props: any) {
        super(props);
    
        this.state = {
            debtor: {
                number: '',
                name: '',
                address: '',
                zipcode: '',
                city: '',
                country: '',
                comment: '',
                contact_name: '',
                contact_telephone_number: '',
                contact_email_address: '',
                collect: true,
                use_courant: false,
                status: '',
                customer_owner: '',
                kvk_number: '',
            },
            isLoading: false,
            errors: undefined,
        };
    }

    /**
     * Mount
     */
    componentDidMount = () => {
        if (this.props.match.params.id) {
            this.fetch();
        }
    }

    /**
     * Fetch
     */
    fetch = () => {
        this.setState({ isLoading: true });

        if (this.props.match.params.id) {
            api.showDebtor(this.props.match.params.id).then(({ data }) => {
                this.setState({
                    debtor: data,
                    isLoading: false,
                });
            });
        }
    }

    /**
     * Handle input
     */
    handleInput = (e: any, data: any) => {
        const { t } = this.props;
        const { debtor } = this.state;
        const newDebtor = debtor as any;
        newDebtor[data.name] = data.checked === undefined ? data.value : data.checked;

        if (data.name === 'use_courant' && data.checked === true) {
            if (window.confirm(t('debtors.confirm_courant'))) {
                this.setState({
                    debtor: newDebtor,
                });
            }
        } else {
            this.setState({
                debtor: newDebtor,
            });
        }
    }

    /**
     * Save
     */
    save = (exit?: boolean) => {
        const { debtor } = this.state;
        this.setState({ isLoading: true, errors: undefined });
        
        if (this.props.match.params.id) {
            api.patchDebtor(this.props.match.params.id, debtor)
                .then(({ data }) => this.success(data, exit))
                .catch(this.onError);
        } else {
            api.storeDebtor(debtor).then(({ data }) => this.success(data, exit)).catch(this.onError);
        }
    }

    /**
     * Success
     */
    success = (data: ApiDebtorObject, exit?: boolean) => {
        const { t } = this.props;
        this.setState({
            debtor: data,
            isLoading: false,
        });
        Toastify.success(t('debtors.saved'));

        if (exit) {
            this.props.history.push(redirect('/debtors'));
        } else if (window.location.href.indexOf('create') !== -1) {
            this.props.history.push(redirect(`/debtors/${data.id}/edit`));
        }
    }

    /**
     * Error
     */
    onError = (error: any) => {
        const { t } = this.props;
        Toastify.error(t('debtors.something_went_wrong'));

        this.setState({
            isLoading: false,
            errors: error.response.data.errors,
        });
    }

    // render
    render = () => {
        const { auth, t } = this.props;
        const { debtor, isLoading, errors } = this.state;

        return (
            <div className="pageContainer">
                <PageHeader title={`${debtor.id ? t('debtors.info') : t('debtors.add_debtor')}`}>
                    <a className="primary" href="/" onClick={(e) => {
                        this.props.history.goBack();
                        return e.preventDefault();
                    }}>{t('general.back')}</a>
                </PageHeader>

                <Form onSubmit={() => this.save()} loading={isLoading}>
                    <Grid columns="equal">
                        <Grid.Row>
                            <Grid.Column>
                                <Segment>
                                    <Header as="h3">{t('customers.general')}</Header>
                                    <Form.Group style={{ marginLeft: 0 }}>
                                        <Checkbox
                                            toggle
                                            name="collect"
                                            label={t(`debtors.${debtor.collect ? 'do' : 'dont'}_collect`)}
                                            checked={debtor.collect}
                                            onChange={this.handleInput}
                                            readOnly={auth.role_id === 8}
                                        />
                                    </Form.Group>
                                    <Form.Group style={{ marginLeft: 0 }}>
                                        <Checkbox
                                            toggle
                                            name="use_courant"
                                            label={t('debtors.use_courant')}
                                            checked={debtor.use_courant}
                                            onChange={this.handleInput}
                                            readOnly={auth.role_id === 8}
                                        />
                                    </Form.Group>
                                    <Form.Input
                                        error={errors && errors.number !== undefined}
                                        label={t('debtors.number')}
                                        name="number"
                                        onChange={this.handleInput}
                                        required
                                        value={debtor.number}
                                        readOnly={debtor.id || auth.role_id === 6 || auth.role_id === 8 ? true : false}
                                    />
                                    <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 17 }}>
                                        <div style={{ flex: 1 }}>
                                            <Form.Input
                                                label="KvK nummer"
                                                name="kvk_number"
                                                onChange={this.handleInput}
                                                value={debtor.kvk_number}
                                                readOnly={auth.role_id === 6 || auth.role_id === 8}
                                            />
                                        </div>
                                        {debtor.kvk_number && (
                                            <div style={{ marginLeft: 8 }}>
                                                <a
                                                    className="ui basic button"
                                                    href={`https://www.kvk.nl/zoeken/?source=all&q=${debtor.kvk_number}`}
                                                    target="_blank"
                                                    style={{ paddingLeft: 10, paddingRight: 10 }}
                                                >
                                                    <Icon name="search" />
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                    {errors && errors.number && <p className="formError">{errors.number}</p>}
                                    <Form.Input
                                        error={errors && errors.name !== undefined}
                                        label={t('debtors.name')}
                                        required
                                        name="name"
                                        value={debtor.name}
                                        onChange={this.handleInput}
                                        readOnly={auth.role_id === 6 || auth.role_id === 8}
                                    />
                                    {errors && errors.name && <p className="formError">{errors.name}</p>}
                                    <Form.Input
                                        label={t('debtors.address')}
                                        name="address"
                                        value={debtor.address || ''}
                                        onChange={this.handleInput}
                                        readOnly={auth.role_id === 6 || auth.role_id === 8}
                                    />
                                    <div style={{ display: 'flex', marginBottom: 10 }}>
                                        <div style={{ width: '25%', marginRight: 5 }}>
                                            <Form.Input
                                                label={t('debtors.zipcode')}
                                                name="zipcode"
                                                value={debtor.zipcode || ''}
                                                onChange={this.handleInput}
                                                readOnly={auth.role_id === 6 || auth.role_id === 8}
                                            />
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <Form.Input
                                                label={t('debtors.city')}
                                                name="city"
                                                value={debtor.city || ''}
                                                onChange={this.handleInput}
                                                readOnly={auth.role_id === 6 || auth.role_id === 8}
                                            />
                                        </div>
                                    </div>
                                    <Form.Input
                                        label={t('debtors.country')}
                                        name="country"
                                        value={debtor.country || ''}
                                        onChange={this.handleInput}
                                        readOnly={auth.role_id === 6 || auth.role_id === 8}
                                    />
                                    {debtor.subscription_expired && (
                                        <div
                                            style={{ color: '#dd0000', position: 'absolute', top: 16, right: 16, fontSize: 20 }}
                                        >
                                            <i className="flag outline error icon" /> R9
                                        </div>
                                    )}
                                </Segment>
                                <p style={{ fontSize: 12, fontStyle: 'italic' }}>{t('general.fields_required1')} <span style={{ color: '#dd0000' }}>*</span> {t('general.fields_required2')}</p>
                            </Grid.Column>
                            <Grid.Column>
                                <Segment>
                                    <Header as="h3">{t('debtors.contact')}</Header>
                                    <Form.Input
                                        label={t('debtors.name')}
                                        name="contact_name"
                                        value={debtor.contact_name || ''}
                                        onChange={this.handleInput}
                                        readOnly={auth.role_id === 6 || auth.role_id === 8}
                                    />
                                    <Form.Input
                                        label={t('customers.contact_email')}
                                        name="contact_email_address"
                                        value={debtor.contact_email_address || ''}
                                        onChange={this.handleInput}
                                        readOnly={auth.role_id === 6 || auth.role_id === 8}
                                    />
                                    <Form.Input
                                        label={t('customers.contact_phone')}
                                        name="contact_telephone_number"
                                        value={debtor.contact_telephone_number || ''}
                                        onChange={this.handleInput}
                                        readOnly={auth.role_id === 6 || auth.role_id === 8}
                                    />
                                </Segment>
                                <Segment>
                                    <Form.Input
                                        label={t('debtors.status')}
                                        name="status"
                                        value={debtor.status || ''}
                                        onChange={this.handleInput}
                                        readOnly={auth.role_id === 6 || auth.role_id === 8}
                                    />
                                    <Form.TextArea
                                        label={t('debtors.comment')}
                                        name="comment"
                                        value={debtor.comment || ''}
                                        onChange={this.handleInput}
                                        rows={5}
                                        readOnly={auth.role_id === 6 || auth.role_id === 8}
                                    />
                                    <Form.Input
                                        label={t('debtors.owner')}
                                        name="customer_owner"
                                        value={debtor.customer_owner || ''}
                                        onChange={this.handleInput}
                                        readOnly={auth.role_id === 6 || auth.role_id === 8}
                                    />
                                    <Form.Field>
                                        <label>{t('debtors.creditlimit')}</label>
                                        <MoneyInput
                                            label={{ basic: true, content: auth && auth.selected_customer ? auth.selected_customer.currency : 'EUR' }}
                                            name="credit_limit"
                                            onChange={this.handleInput}
                                            value={debtor.credit_limit || 0.00}
                                            readOnly={auth.role_id === 6 || auth.role_id === 8}
                                        />
                                    </Form.Field>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
                {debtor.id && <InvoicesList debtor={debtor} />}
                {auth.role_id !== 6 && auth.role_id !== 8 && (
                    <FloatingButtons
                        onCancel="/debtors"
                        onSave={() => this.save()}
                        onSaveExit={() => this.save(true)}
                    />
                )}
            </div>
        );
    }
}

export default withTranslation('common')(connect((state: AppState) => ({
    auth: state.auth.user,
}))(DebtorsEditView));
