import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Icon, Menu, Label } from 'semantic-ui-react';
import api from '../../api';
import { ApiInvoiceLetterObject } from '../../api/invoices';
import AdvancedTable, { FilterObject } from '../../components/AdvancedTable';
import DomainLink from '../../components/DomainLink';
import PageHeader from '../../components/Layout/PageHeader';
import { money, redirect } from '../../lib/utils';
import { ApiAuthObject } from '../../api/auth';
import { AppState } from '../../store';
const config = require('../../config.json');

interface DownloadListProps {
    auth?: ApiAuthObject;
}

interface DownloadsListViewState {
    invoices: ApiInvoiceLetterObject[],
    pagination?: any,
    isLoading: boolean,
}

class DownloadsListView extends React.Component<DownloadListProps, DownloadsListViewState> {
    constructor(props: DownloadListProps) {
        super(props);
    
        this.state = {
            invoices: [],
            pagination: undefined,
            isLoading: false,
        };
    }

    /**
     * Fetch
     */
    fetch = (filter?: FilterObject) => {
        this.setState({ isLoading: true });

        api.listInvoiceDownloads(filter).then(({ data }) => {
            this.setState({
                invoices: data.data,
                pagination: data.meta,
                isLoading: false,
            });
        });
    }

    // render
    render = () => {
        const { auth } = this.props;
        const { pagination, invoices, isLoading } = this.state;

        return (
            <div className="pageContainer">
                <PageHeader title="Downloadlijst">
                    <a href={`${config.baseUrl}download-all-letters${invoices.length > 0 && invoices[0].hash}`} className="ui primary button" target="_blank" rel="noopener noreferrer">
                        Download alles
                    </a>
                </PageHeader>

                <Menu tabular attached="top">
                    <Menu.Item
                        as={Link}
                        to={redirect('/actions')}
                        content="Actielijst"
                    />
                    <Menu.Item
                        as={Link}
                        to={redirect('/actions?agenda')}
                        content="Agendalijst"
                    />
                    {auth && auth.downloads > 0 && (
                    <Menu.Item active>
                        DownloadLijst
                        <Label color="red" size="tiny">{auth.downloads}</Label>
                    </Menu.Item>)}
                </Menu>

                <AdvancedTable
                    name="download-index"
                    segmentProps={{ attached: 'bottom', style: { width: '100%', overflow: 'auto' }}}
                    items={invoices}
                    header={[{
                        id: 'download',
                        title: '',
                    }, {
                        id: 'debtor',
                        title: 'Debiteur',
                    }, {
                        id: 'number',
                        title: 'Factuurnummer',
                        sortable: 'number',
                    }, {
                        id: 'date',
                        title: 'Factuurdatum',
                        sortable: 'date',
                    }, {
                        id: 'expires',
                        title: 'Vervaldatum',
                        sortable: 'expires_at',
                    }, {
                        id: 'amount',
                        title: 'Factuurbedrag',
                        sortable: 'amount',
                    }, {
                        id: 'lastStatus',
                        title: 'Huidige status',
                        sortable: 'last_status_id',
                    }]}
                    renderRow={(item: ApiInvoiceLetterObject) => ({
                        cells: [{
                            header: 'download',
                            render: <a className="ui basic icon button" target="_blank" href={item.download_url} rel="noopener noreferrer">
                                <Icon name="download" />
                            </a>,
                            cellProps: { collapsing: true },
                        }, {
                            header: 'debtor',
                            render: <DomainLink primary to={`/debtors/${item.invoice.debtor_id}/edit`}>{item.invoice.debtor ? `${item.invoice.debtor.number} - ${item.invoice.debtor.name}` : ''}</DomainLink>,
                        }, {
                            header: 'number',
                            render: <DomainLink primary to={`/invoices/${item.invoice.id}/edit`}>{item.invoice.number}</DomainLink>,
                            cellProps: { collapsing: true }
                        }, {
                            header: 'date',
                            render: moment(item.invoice.date).format('DD/MM/YYYY'),
                        }, {
                            header: 'expires',
                            render: item.invoice.expires_at ? moment(item.invoice.expires_at).format('DD/MM/YYYY') : '-',
                        }, {
                            header: 'amount',
                            render: money(item.invoice.amount, 2, item.invoice.currency),
                            cellProps: { textAlign: 'right' }
                        }, {
                            header: 'lastStatus',
                            render: (<div>
                                {item.status.name}<br />
                                <small>{moment(item.created_at).format('DD/MM/YYYY')}</small>
                            </div>),
                            cellProps: { collapsing: true }
                        }],
                        actions: []
                    })}
                    onFilter={this.fetch}
                    isLoading={isLoading}
                    pagination={pagination}
                />
            </div>
        );
    }
}

export default connect((state: AppState) => ({
    auth: state.auth.user,
}))(DownloadsListView);
