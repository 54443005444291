import React from 'react';
import { Button } from 'semantic-ui-react';
import Toastify from 'toastify';
import api from '../../api';
import { ApiUserObject } from '../../api/users';
import AdvancedTable, { AdvancedTableAction, FilterObject } from '../../components/AdvancedTable';
import DomainLink from '../../components/DomainLink';
import PageHeader from '../../components/Layout/PageHeader';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AppState } from '../../store';

const roles: any = {
    1: 'Superadmin',
    2: 'White Label',
    3: 'Klant',
    4: 'Manager',
    5: 'CMSI',
    6: 'Flex',
    7: 'Sales',
}

interface UsersListViewState {
    users: ApiUserObject[],
    pagination?: any,
    isLoading: boolean,
}

class UsersListView extends React.Component<any, UsersListViewState> {
    constructor(props: any) {
        super(props);
    
        this.state = {
            users: [],
            pagination: undefined,
            isLoading: false,
        };
    }

    /**
     * Mount
     */
    componentDidMount = () => {
    }

    /**
     * Fetch
     */
    fetch = (filter?: FilterObject) => {
        this.setState({ isLoading: true });

        api.listUsers(filter, ['reseller']).then(({ data }) => {
            this.setState({
                users: data.data,
                pagination: data.meta,
                isLoading: false,
            });
        });
    }

    /**
     * Delete user
     */
    deleteUser = (id: number) => {
        const { t } = this.props;

        api.deleteUser(id).then(() => {
            Toastify.success(t('user_deleted'));
            this.fetch();
        });
    }

    // render
    render = () => {
        const { t, auth } = this.props;
        const { pagination, users, isLoading } = this.state;

        let headers = [{
            id: 'name',
            title: t('users.name'),
        }, {
            id: 'email_address',
            title: t('users.emailaddress'),
        }, {
            id: 'reseller',
            title: t('users.white_label'),
            showIf: auth && auth.role_id === 1,
        }, {
            id: 'role',
            title: t('users.role'),
        }];

        return (
            <div className="pageContainer">
                <PageHeader title={t('users.title')}>
                    <DomainLink to="/users/create">
                        <Button primary>{t('users.add_user')}</Button>
                    </DomainLink>
                </PageHeader>

                <AdvancedTable
                    name="user-index"
                    items={users}
                    header={headers}
                    renderRow={(item: ApiUserObject) => ({
                        cells: [{
                            header: 'name',
                            render: <DomainLink primary to={`/users/${item.id}/edit`}>{item.full_name}</DomainLink>,
                        }, {
                            header: 'email_address',
                            render: item.email_address,
                        }, {
                            header: 'reseller',
                            render: item.reseller ? <DomainLink primary to={`/resellers/${item.reseller_id}/edit`}>
                                {item.reseller.name}
                            </DomainLink> : '-',
                            showIf: auth && auth.role_id === 1,
                        }, {
                            header: 'role',
                            render: auth && auth.role_id === 2 && item.role_id === 2 ? 'Beheerder' : (roles[item.role_id] || '-'),
                        }],
                        actions: [
                            <AdvancedTableAction 
                                key={`action-1-${item.id}`}
                                to={`/users/${item.id}/edit`}
                                iconName="pencil"
                                text={t('users.edit')}
                            />,
                            <AdvancedTableAction key={`action-2-${item.id}`} divider />,
                            <AdvancedTableAction 
                                key={`action-3-${item.id}`}
                                iconName="envelope outline"
                                text={t('users.send_invite')}
                                onConfirm={() => api.sendUserInvite(item.id)}
                                confirmContent={`${t('users.confirm_invite')} ${item.email_address}?`}
                            />,
                            <AdvancedTableAction 
                                key={`action-4-${item.id}`}
                                iconName="lock"
                                text={t('users.login_as')}
                                onClick={() => api.superLogin(item.id)}
                            />,
                            <AdvancedTableAction key={`action-5-${item.id}`} divider />,
                            <AdvancedTableAction 
                                key={`action-6-${item.id}`}
                                iconName="trash alternate outline"
                                text={t('users.delete')}
                                onConfirm={() => this.deleteUser(item.id)}
                                confirmContent={t('users.confirm_delete')}
                            />,
                        ]
                    })}
                    onFilter={this.fetch}
                    isLoading={isLoading}
                    pagination={pagination}
                />
            </div>
        );
    }
}

export default connect((state: AppState) => ({
    auth: state.auth.user,
 }))(withTranslation('common')(UsersListView));
