import React from 'react';
import { Segment, Menu, Message } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { range } from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { request } from '../../api';

interface InvoiceStatusProps {
    history?: any,
    globalFilter: {
        query: string,
        trigger: number,
        r9: boolean,
    },
}

interface InvoiceStatusState {
    chartOptions: any,
    status?: any,
    format: string,
    year: number,
    years: number[],
}

class DsoStats extends React.Component<InvoiceStatusProps & WithTranslation, InvoiceStatusState> {
    constructor(props: any) {
        super(props);

        this.state = {
            status: undefined,
            year: new Date().getFullYear(),
            years: [],
            format: 'Y-m-d',
            chartOptions: {
                credits: {
                    enabled: false,
                },
                chart: {
                    type: 'line',
                    zoomType: 'x'
                },
                title: {
                    text: ''
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                },
                series: []
            },
        };
    }

    componentDidMount = () => {
        this.fetch();
    }

    fetch = () => {
        const { t, globalFilter } = this.props;
        const { format, year } = this.state;
        const r9 = globalFilter.r9;

        request.get(`dashboard/dso?year=${year}&format=${format}${r9 ? `&r9=1` : ''}`).then(({ data }) => {
            this.setState({
                years: data.years,
                chartOptions: {
                    xAxis: {
                        categories: data.categories,
                    },
                    series: [{
                        type: 'line',
                        data: range(0, Object.values(data.data.dso).length).map(o => data.dso),
                        name: t('dashboard.s4'),
                        color: 'rgb(124, 181, 236)',
                    }, {
                        name: 'DSO',
                        data: Object.values(data.data.dso).map((o: any) => Math.round(parseFloat(o))),
                        color: 'rgb(222, 0, 0)',
                    }, {
                        name: t('dashboard.due1'),
                        data: Object.values(data.data.due_dso_1).map((o: any) => Math.round(parseFloat(o))),
                        color: 'rgb(41, 111, 217)',
                    }, {
                        name: t('dashboard.due2'),
                        data: Object.values(data.data.due_dso_2).map((o: any) => Math.round(parseFloat(o))),
                        color: 'rgb(104, 41, 194)',
                    }, {
                        name: t('dashboard.due3'),
                        data: Object.values(data.data.due_dso_3).map((o: any) => Math.round(parseFloat(o))),
                        color: 'rgb(255, 128, 0)',
                    }],
                },
            });
        });
    }

    // render
    render = () => {
        const { t } = this.props;
        const { chartOptions, format, year, years } = this.state;

        return (
            <div>
                <Menu compact>
                    <Menu.Item
                        style={format === 'Y' ? { border: 'solid 1px #dd0000' } : {}}
                        onClick={() => this.setState({ format: 'Y' }, this.fetch)}
                    >
                        {t('dashboard.year')}
                    </Menu.Item>
                    <Menu.Item
                        style={format === 'm' ? { border: 'solid 1px #dd0000' } : {}}
                        onClick={() => this.setState({ format: 'm' }, this.fetch)}
                    >
                        {t('dashboard.month')}
                    </Menu.Item>
                    <Menu.Item
                        style={format === 'Y-m-d' ? { border: 'solid 1px #dd0000' } : {}}
                        onClick={() => this.setState({ format: 'Y-m-d' }, this.fetch)}
                    >
                        {t('dashboard.day')}
                    </Menu.Item>
                </Menu>
                {format !== 'Y' && <Menu compact style={{ marginLeft: 15 }}>
                    {years.map((y) => (
                        <Menu.Item
                            style={year === y ? { border: 'solid 1px #dd0000' } : {}}
                            onClick={() => this.setState({ year: y }, this.fetch)}
                        >
                            {y}
                        </Menu.Item>
                    ))}
                </Menu>}
                {this.props.globalFilter.query !== '' && (
                    <Message warning>
                        {t('dashboard.info')}
                    </Message>
                )}
                <Segment>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                    />
                </Segment>
            </div>
        );
    }
}

export default withTranslation('common')(DsoStats);
