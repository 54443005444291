import React from 'react';
import { Segment, Form, Header } from 'semantic-ui-react';
import Toastify from 'toastify';
import api from '../../api';
import { ApiContactObject } from '../../api/contacts';
import FloatingButtons from '../../components/FloatingButtons';

interface ContactEditViewProps {
    contactId?: number,
    customerId: number,
    onExit: () => void,
}

interface ContactEditViewState {
    contact: Partial<ApiContactObject>,
    isLoading: boolean,
    errors?: {
        [key: string]: any,
    },
}

class ContactEditView extends React.Component<ContactEditViewProps, ContactEditViewState> {
    constructor(props: any) {
        super(props);
    
        this.state = {
            contact: {
                customer_id: this.props.customerId,
                type: 'contact',
                name: '',
                email_address: '',
                telephone_number: '',
            },
            isLoading: false,
            errors: undefined,
        };
    }

    /**
     * Mount
     */
    componentDidMount = () => {
        if (this.props.contactId) {
            this.fetch();
        }
    }

    /**
     * Fetch
     */
    fetch = () => {
        this.setState({ isLoading: true });

        if (this.props.contactId) {
            api.showContact(this.props.contactId).then(({ data }) => {
                this.setState({
                    contact: data,
                    isLoading: false,
                });
            });
        }
    }

    /**
     * Handle input
     */
    handleInput = (e: any, data: any) => {
        const { contact } = this.state;
        const newContact = contact as any;
        newContact[data.name] = data.checked === undefined ? data.value : data.checked;

        this.setState({
            contact: newContact,
        });
    }

    /**
     * Save
     */
    save = (exit?: boolean) => {
        const { contact } = this.state;
        this.setState({ isLoading: true, errors: undefined });
        
        if (this.props.contactId) {
            api.patchContact(this.props.contactId, contact)
                .then(({ data }) => this.success(data, exit))
                .catch(this.onError);
        } else {
            api.storeContact(contact).then(({ data }) => this.success(data, exit)).catch(this.onError);
        }
    }

    /**
     * Success
     */
    success = (data: ApiContactObject, exit?: boolean) => {
        this.setState({
            contact: data,
            isLoading: false,
        });
        Toastify.success('Contactpersoon succesvol opgeslagen');

        if (exit) {
            this.props.onExit();
        }
    }

    /**
     * Error
     */
    onError = (error: any) => {
        Toastify.error('Er ging iets mis');

        this.setState({
            isLoading: false,
            errors: error.response.data.errors,
        });
    }

    // render
    render = () => {
        const { contact, isLoading, errors } = this.state;

        return (
            <div className="pageContainer">
                <Form onSubmit={() => this.save()} loading={isLoading}>
                    <Segment>
                        <Header as="h3">Contactpersoon {contact.id ? 'wijzigen' : 'toevoegen'}</Header>
                        <Form.Input
                            label="Naam"
                            name="name"
                            value={contact.name}
                            onChange={this.handleInput}
                        />
                        <Form.Input
                            label="Telefoonnummer"
                            name="telephone_number"
                            value={contact.telephone_number}
                            onChange={this.handleInput}
                        />
                        <Form.Input
                            label="E-mailadres"
                            name="email_address"
                            value={contact.email_address}
                            onChange={this.handleInput}
                        />
                    </Segment>
                </Form>
                <FloatingButtons
                    onCancel={this.props.onExit}
                    onSave={() => this.save()}
                    onSaveExit={() => this.save(true)}
                />
            </div>
        );
    }
}

export default ContactEditView;
