import React from 'react';
import { Header } from 'semantic-ui-react';
import styles from './styles.module.scss';

interface PageHeaderProps {
    title?: string,
}

class PageHeader extends React.Component<PageHeaderProps> {
    // --- render
    render() {
        const { children, title } = this.props;

        return (
            <div className={styles.container}>
                {title && <Header as="h1">{title}</Header>}
                {children && (
                    <div className={styles.actions}>
                        {children}
                    </div>
                )}
            </div>
        );
    }
}

export default PageHeader;
