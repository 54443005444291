import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApiResellerObject } from '../../api/resellers';
import { AppState } from '../../store';

interface LogoProps {
    children: any,
    className?: string,
    reseller?: ApiResellerObject,
    style?: {
        [key: string]: number | string,
    },
    primary?: boolean,
    default?: boolean,
    to: string,
}

class DomainLink extends React.Component<LogoProps> {
    // --- render
    render() {
        const { children, reseller, primary, className } = this.props;
        const style: any = this.props.style || {};
        const link = `/${(reseller && reseller.domain ? reseller.domain : 'cmsi360')}${this.props.to}`;

        return (
            <Link
                to={link}
                style={style}
                className={[
                    className,
                    primary && 'primary',
                    this.props.default && 'default',
                ].join(' ')}
            >
                {children || ''}
            </Link>
        );
    }
}

export default connect((state: AppState) => ({
    reseller: state.resellers.reseller,
}), {
})(DomainLink);
