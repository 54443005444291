import { createStore, combineReducers } from 'redux';
import auth, { AuthState } from './reducers/auth';
import resellers, { ResellerState } from './reducers/resellers';

export interface AppState {
    auth: AuthState,
    resellers: ResellerState,
}

export default createStore(combineReducers({
    auth,
    resellers,
}));
