import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Segment, Form, Grid, Header } from 'semantic-ui-react';
import Toastify from 'toastify';
import { each } from 'lodash';
import styles from './styles.module.scss';
import api from '../../api';
import { redirect, slugify } from '../../lib/utils'; 
import { ApiResellerObject } from '../../api/resellers';
import DomainLink from '../../components/DomainLink';
import FloatingButtons from '../../components/FloatingButtons';
import PageHeader from '../../components/Layout/PageHeader';

interface ResellersEditViewProps extends RouteComponentProps<{ id?: string }> {
}

interface ResellersEditViewState {
    reseller: Partial<ApiResellerObject>,
    isLoading: boolean,
    errors?: {
        [key: string]: any,
    },
    files: {
        logo?: any,
        start_image?: any,
    },
}

class ResellersEditView extends React.Component<ResellersEditViewProps, ResellersEditViewState> {
    constructor(props: any) {
        super(props);
    
        this.state = {
            reseller: {
                name: '',
                domain: '',
                primary_color: '#3797c7',
                secondary_color: '#333333',
            },
            isLoading: false,
            files: {
                logo: undefined,
                start_image: undefined,
            },
            errors: undefined,
        };
    }

    /**
     * Mount
     */
    componentDidMount = () => {
        if (this.props.match.params.id) {
            this.fetch();
        }
    }

    /**
     * Fetch
     */
    fetch = () => {
        this.setState({ isLoading: true });

        if (this.props.match.params.id) {
            api.showReseller(this.props.match.params.id).then(({ data }) => {
                this.setState({
                    reseller: data,
                    isLoading: false,
                });
            });
        }
    }

    /**
     * Handle input
     */
    handleInput = (e: any, data: any) => {
        const { reseller } = this.state;
        const newReseller = reseller as any;
        newReseller[data.name] = data.value;

        this.setState({
            reseller: newReseller,
        });
    }

    slugifyDomain = (field: 'domain' | 'name') => {
        const { reseller } = this.state;
        if (reseller.domain === '' || field === 'domain') {
            reseller.domain = slugify(reseller[field] as string);
            this.setState({ reseller });
        }
    }

    /**
     * Set media
     */
    setMedia = (e: any, field: 'logo' | 'start_image') => {
        const { reseller } = this.state;
        const reader = new FileReader();
        e.persist();

        reader.onload = (img: any) => {
            if (img && img.target) {
                reseller[field] = img.target.result;

                this.setState({
                    reseller: reseller,
                    files: {
                        ...this.state.files,
                        [field]: e.target.files[0],
                    }
                });
            }
        }

        reader.readAsDataURL(e.target.files[0]);
    }

    /**
     * Save
     */
    save = (exit?: boolean) => {
        const { reseller, files } = this.state;
        this.setState({ isLoading: true, errors: undefined });
        const fd = new FormData();

        each(reseller, (v: any, k) => {
            fd.append(k, v);
        });

        if (files.logo) {
            fd.append('logo', files.logo);
        }
        if (files.start_image) {
            fd.append('start_image', files.start_image);
        }

        if (this.props.match.params.id) {
            api.patchReseller(this.props.match.params.id, fd)
                .then(({ data }) => this.success(data, exit))
                .catch(this.onError);
        } else {
            api.storeReseller(fd).then(({ data }) => this.success(data, exit)).catch(this.onError);
        }
    }

    /**
     * Success
     */
    success = (data: ApiResellerObject, exit?: boolean) => {
        this.setState({
            reseller: data,
            isLoading: false,
        });
        Toastify.success('White Label succesvol opgeslagen');

        if (exit) {
            this.props.history.push(redirect('/resellers'));
        } else if (window.location.href.indexOf('create') !== -1) {
            this.props.history.push(redirect(`/resellers/${data.id}/edit`));
        }
    }

    /**
     * Error
     */
    onError = (error: any) => {
        Toastify.error('Er ging iets mis');

        this.setState({
            isLoading: false,
            errors: error.response.data.errors,
        });
    }

    // render
    render = () => {
        const { reseller, isLoading, errors } = this.state;

        return (
            <div className="pageContainer">
                <PageHeader title={`${reseller.id ? 'White Label wijzigen' : 'Nieuw White Label'}`}>
                    <DomainLink to="/resellers" primary>Terug naar overzicht</DomainLink>
                </PageHeader>

                <Grid>
                    <Grid.Row>
                        <Grid.Column width={10}>
                            <Segment loading={isLoading}>
                                <Form onSubmit={() => this.save()}>
                                    <Form.Input
                                        error={errors && errors.name !== undefined}
                                        label="Naam"
                                        required
                                        name="name"
                                        value={reseller.name}
                                        onChange={this.handleInput}
                                        onBlur={() => this.slugifyDomain('name')}
                                    />
                                    {errors && errors.name && <p className="formError">{errors.name}</p>}
                                    <Form.Input
                                        error={errors && errors.domain !== undefined}
                                        label="Domein"
                                        required
                                        name="domain"
                                        value={reseller.domain}
                                        onChange={this.handleInput}
                                        onBlur={() => this.slugifyDomain('domain')}
                                    />
                                    {errors && errors.domain && <p className="formError">{errors.domain}</p>}
                                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                                        <div style={{ flex: 1 }}>
                                            <Form.Input
                                                error={errors && errors.primary_color !== undefined}
                                                label="Knoppen kleur"
                                                required
                                                name="primary_color"
                                                value={reseller.primary_color}
                                                onChange={this.handleInput}
                                            />
                                        </div>
                                        <Form.Input
                                            type="color"
                                            required
                                            name="primary_color"
                                            value={reseller.primary_color}
                                            onChange={this.handleInput}
                                            style={{ height: 30, width: 80, cursor: 'pointer' }}
                                        />
                                    </div>
                                    {errors && errors.primary_color && <p className="formError">{errors.primary_color}</p>}
                                    <div style={{ display: 'flex', alignItems: 'flex-end', marginTop: 15 }}>
                                        <div style={{ flex: 1 }}>
                                            <Form.Input
                                                error={errors && errors.secondary_color !== undefined}
                                                label="Balkkleur"
                                                required
                                                name="secondary_color"
                                                value={reseller.secondary_color}
                                                onChange={this.handleInput}
                                            />
                                        </div>
                                        <Form.Input
                                            type="color"
                                            required
                                            name="secondary_color"
                                            value={reseller.secondary_color}
                                            onChange={this.handleInput}
                                            style={{ height: 30, width: 80, cursor: 'pointer' }}
                                        />
                                    </div>
                                    {errors && errors.secondary_color && <p className="formError">{errors.secondary_color}</p>}
                                </Form>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Segment>
                                <Header as="h4">Logo</Header>
                                <div className={styles.editLogo}>
                                    {reseller.logo && <img src={reseller.logo} alt="" />}
                                </div>
                                <Form.Input
                                    type="file"
                                    accept="image/*"
                                    fluid
                                    onChange={(e: any) => this.setMedia(e, 'logo')}
                                />
                            </Segment>
                            <Segment>
                                <Header as="h4">Startafbeelding</Header>
                                <div className={styles.editStartImage}>
                                    {reseller.start_image && <img src={reseller.start_image} alt="" />}
                                </div>
                                <Form.Input
                                    type="file"
                                    accept="image/*"
                                    fluid
                                    onChange={(e: any) => this.setMedia(e, 'start_image')}
                                />
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <FloatingButtons
                    onCancel="/resellers"
                    onSave={() => this.save()}
                    onSaveExit={() => this.save(true)}
                />
            </div>
        );
    }
}

export default ResellersEditView;
