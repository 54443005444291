import { AxiosPromise } from 'axios';
import { each } from 'lodash';
import { request } from './index';

export interface ApiContractObject {
    id: number,
    customer_id: number,
    starts_at: string | Date,
    ends_at: string | Date,
    number_of_invoices: number,
    number_of_reminders: number,
    number_of_collections: number,
    actual_number_of_invoices: number,
    actual_number_of_reminders: number,
    actual_number_of_collections: number,
}

export default {
    delete: (id: number | string): AxiosPromise => {
        return request.delete(`contracts/${id}`);
    },
    list: (page: number, filter?: any, include?: string[]): AxiosPromise => {
        const query: any = [];

        if (filter) {
            each(filter, (v, k) => {
                query.push(`${k}=${v}`);
            });
        }

        return request.get(`contracts?page=${page}&${query.join('&')}${include ? `&with=${include.join(',')}` : ''}`);
    },
    store: (data: any): AxiosPromise<ApiContractObject> => {
        return request.post(`contracts`, data);
    },
    patch: (id: number | string, data: any): AxiosPromise<ApiContractObject> => {
        return request.patch(`contracts/${id}`, data);
    },
    show: (id: number | string): AxiosPromise<ApiContractObject> => {
        return request.get(`contracts/${id}`);
    },
};
