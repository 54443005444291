import React from 'react';
import { connect } from 'react-redux';
import styles from './styles.module.scss';
import { ApiResellerObject } from '../../api/resellers';
import logo from '../../assets/logo.svg';
import DomainLink from '../../components/DomainLink';
import { AppState } from '../../store';
import { preloadImage } from '../../lib/utils';

interface LogoProps {
    className?: string,
    reseller?: ApiResellerObject,
    style?: {
        [key: string]: number | string,
    },
}

interface LogoState {
    width: number,
}

class Logo extends React.Component<LogoProps, LogoState> {
    constructor(props: LogoProps) {
        super(props);

        this.state = {
            width: 120,
        };
    }

    /**
     * Mount
     */
    componentDidMount = () => {
        const { reseller } = this.props;
        
        preloadImage(reseller && reseller.logo ? reseller.logo : logo, (img: any) => {
            this.setState({
                width: (40 / img.height) * img.width,
            });
        });
    }

    // --- render
    render() {
        const { reseller } = this.props;
        const { width } = this.state;
        const style: any = this.props.style || {};
        
        return (
            <div className={styles.container}>
                <DomainLink to="/" style={{ display: 'inline-block' }}>
                    <div
                        className={styles.base}
                        style={{
                            ...style,
                            backgroundImage: `url(${reseller && reseller.logo ? reseller.logo : logo})`,
                            width,
                        }}
                    >
                        {/* <img src={reseller && reseller.logo ? reseller.logo : logo} alt="" /> */}
                    </div>
                </DomainLink>
            </div>
        );
    }
}

export default connect((state: AppState) => ({
    reseller: state.resellers.reseller,
}), {
})(Logo);
