import wNumb from 'wnumb';
import { currencies } from '../lib/constants';

/**
 * Format money
 */
export const money = (str: number | string, decimals: number = 2, currency?: string) => {
    let c = currency;

    if (!c) {
        const lc = localStorage.getItem('global-currency');

        if (lc) {
            c = lc;
        } else {
            c = 'default';
        }
    }

    const format = wNumb({
        decimals,
        thousand: '.',
        mark: ',',
        prefix: `${currencies[c] || '€'} `,
    });

    return format.to(parseFloat(str as string));
};

/**
 * Format number
 */
export function number(str: number | string, decimals: number = 0, thousand = '.', mark = ',')
{
    const format = wNumb({
        decimals,
        thousand,
        mark,
    });

    return format.to(decimals === 0 ? parseInt(str as string) : parseFloat(str as string));
};

/**
 * Preload an image and do callback
 */
export const preloadImage = (src?: string, callback?: Function): void => {
    const img = new Image();
    img.onload = () => {
        if (callback) {
            callback(img);
        }
    };
    img.src = src || '';
}

/**
 * redirect
 */
export const redirect = (url: string): string => {
    const path = window.location.href.split('/')[3];
    return `/${path}${url}`;
}

/**
 * slugify
 */
export const slugify = (str: string): string => {
    return str.toString().toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
}
