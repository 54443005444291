import React from 'react';
import { find } from 'lodash';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Segment, Form, Input, Grid, Header, Menu, Label, Checkbox, Button, Divider } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Toastify from 'toastify';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import ContactsListView from './contacts';
import TemplatesListView from '../TemplatesView';
import DossierView from './Dossier';
import api from '../../api';
import { redirect } from '../../lib/utils'; 
import { ApiCustomerObject } from '../../api/customers';
import { ApiResellerObject } from '../../api/resellers';
import DomainLink from '../../components/DomainLink';
import FloatingButtons from '../../components/FloatingButtons';
import PageHeader from '../../components/Layout/PageHeader';
import { ApiInvoiceStatusObject } from '../../api/invoicestatuses';
import styles from '../ResellersView/styles.module.scss';
import { AppState } from '../../store';
import { ApiAuthObject } from '../../api/auth';
import users, { ApiUserObject } from '../../api/users';

interface CustomersEditViewProps extends RouteComponentProps<{ id?: string }> {
    auth?: ApiAuthObject;
}

interface CustomersEditViewState {
    customer: Partial<ApiCustomerObject>,
    resellers: ApiResellerObject[],
    isLoading: boolean,
    errors?: {
        [key: string]: any,
    },
    tab: number,
    hasSupport: boolean,
    statuses: ApiInvoiceStatusObject[],
    files: {
        logo?: any,
    },
    statusToAdd?: number,
    users: ApiUserObject[],
    customers: ApiCustomerObject[],
}

const SortableItem = SortableElement((data: any) => <li style={{ listStyle: 'none', marginTop: 10 }}>
    <Segment
        key={`status-${data.value.id}`}
        style={{ display: 'flex', alignItems: 'center' }}
        size="tiny"
    >
        <span style={{ flex: 1, marginRight: 15 }}>{data.value.name}</span>
        <Input
            type="number"
            style={{ width: 60, marginRight: 5 }}
            // defaultValue={data.value.defaultInterval || 0}
            value={data.value.interval}
            onChange={(e: any, { value }) => data.value.handleStatusInterval(data.value.index, value)}
        />
        <Button type="button" icon="trash" basic size="tiny" onClick={() => data.value.removeFlexFlowStatus(data.value.id)} />
    </Segment>
</li>);

const SortableList = SortableContainer((data: any) => {
  return <ul style={{ padding: 0, margin: 0 }}>{data.children}</ul>;
});

class CustomersEditView extends React.Component<CustomersEditViewProps & WithTranslation, CustomersEditViewState> {
    constructor(props: any) {
        super(props);
    
        this.state = {
            statusToAdd: undefined,
            customer: {
                contracts: [],
                reseller_id: undefined,
                number: 0,
                license_type: 'deb',
                incasso_type: 'costs',
                incasso_value: 0.00,
                name: '',
                street: '',
                house_number: '',
                house_number_suffix: '',
                zipcode: '',
                city: '',
                country: '',
                debit_sender: '',
                debit_subject: '',
                debit_subject_courant: '',
                debit_display_name: '',
                incasso_sender: '',
                incasso_subject: '',
                incasso_subject_courant: '',
                incasso_display_name: '',
                bank_iban: '',
                bank_name: '',
                bank_bic: '',
                contact_name: '',
                contact_telephone_number: '',
                contact_email_address: '',
                support_name: '',
                support_telephone_number: '',
                support_email_address: '',
                daily_sync: false,
                combine_claim_box: false,
                action_interval: 0,
                interest_rate: 0,
                is_active: true,
                statuses: [],
                send_cc: false,
                cc: '',
                currency: '€',
                intervals: {},
                payment_provider: '',
                payment_provider_key: '',
                reset_incasso: false,
                has_flex_template: false,
                type: this.props.auth && [2, 7].includes(this.props.auth.role_id) ? 2 : 1,
            },
            hasSupport: false,
            resellers: [],
            isLoading: false,
            errors: undefined,
            tab: this.props.match.params.id && this.props.auth && [1, 2, 7].includes(this.props.auth.role_id) ? 4 : 0,
            statuses: [],
            users: [],
            files: {
                logo: undefined,
            },
            customers: [],
        };
    }

    /**
     * Mount
     */
    componentDidMount = () => {
        const { auth } = this.props;
        const isSuper = auth && auth.role_id === 1;

        api.listResellers({ page: 1 }).then(({ data }) => {
            this.setState({
                resellers: data.data,
            });

            api.listStatuses(true).then(({ data }) => {
                this.setState({
                    statuses: data,
                })
            });

            if (isSuper) {
                api.listUsers(undefined, undefined, 'cmsi360').then(({ data}) => {
                    this.setState({
                        users: data.data,
                    })
                });

                api.listCustomers().then(({ data }) => {
                    this.setState({
                        customers: data.data,
                    });
                });
            }

            if (this.props.match.params.id) {
                this.fetch();
            } else {
                api.newCustomerNumber().then(({ data }) => {
                    this.setState({
                        customer: {
                            ...this.state.customer,
                            number: data,
                        },
                    });
                });
            }
        });
    }

    /**
     * Fetch
     */
    fetch = () => {
        this.setState({ isLoading: true });

        if (this.props.match.params.id) {
            api.showCustomer(this.props.match.params.id).then(({ data }) => {
                this.setState({
                    customer: data,
                    isLoading: false,
                    hasSupport: data.support_contact_id ? true : false,
                });
            });
        }
    }

    handleStatusInterval = (index: number, value: string) => {
        const { customer } = this.state;

        if (!customer.intervals) {
            customer.intervals = {};
        }

        if (customer && customer.intervals) {
            customer.intervals[index] = parseInt(value);
            this.setState({ customer });
        }
    }

    /**
     * Handle input
     */
    handleInput = (e: any, data: any) => {
        const { customer } = this.state;
        const newCustomer = customer as any;
        newCustomer[data.name] = data.checked === undefined ? data.value : data.checked;

        if (data.name === 'license_type') {
            newCustomer.statuses = [];
        }

        this.setState({
            customer: newCustomer,
        });
    }

    /**
     * Save
     */
    save = (exit?: boolean) => {
        const { customer } = this.state;
        this.setState({ isLoading: true, errors: undefined });

        if (this.props.match.params.id) {
            api.patchCustomer(this.props.match.params.id, customer)
                .then(({ data }) => this.success(data, exit))
                .catch(this.onError);
        } else {
            api.storeCustomer(customer).then(({ data }) => this.success(data, exit)).catch(this.onError);
        }
    }

    /**
     * Success
     */
    success = (data: ApiCustomerObject, exit?: boolean) => {
        const { hasSupport, files } = this.state;
        
        if (files.logo) {
            const fd = new FormData();
            fd.append('has_support', hasSupport ? '1' : '0');
            fd.append('logo', files.logo);
            api.patchCustomer(data.id, fd)
        }

        this.setState({
            customer: data,
            isLoading: false,
        });
        Toastify.success('Klant succesvol opgeslagen');

        if (exit) {
            this.props.history.push(redirect('/customers'));
        } else if (window.location.href.indexOf('create') !== -1) {
            this.props.history.push(redirect(`/customers/${data.id}/edit`));
        }
    }

    /**
     * Error
     */
    onError = (error: any) => {
        Toastify.error('Er ging iets mis');

        this.setState({
            isLoading: false,
            errors: error.response.data.errors,
        });
    }

    /**
     * Handle flex flow
     */
    handleFlex = (status: ApiInvoiceStatusObject, checked: boolean) => {
        let { statuses } = this.state.customer;
        if (!statuses) return;

        if (checked) {
            statuses.push(status.id);
        } else {
            statuses = statuses.filter(o => o !== status.id);
        }

        this.setState({
            customer: {
                ...this.state.customer,
                statuses,
            },
        });
    }

    /**
     * Set media
     */
    setMedia = (e: any, field: 'logo') => {
        const { customer } = this.state;
        const reader = new FileReader();
        e.persist();

        reader.onload = (img: any) => {
            if (img && img.target) {
                customer[field] = img.target.result;

                this.setState({
                    customer,
                    files: {
                        ...this.state.files,
                        [field]: e.target.files[0],
                    }
                });
            }
        }

        reader.readAsDataURL(e.target.files[0]);
    }

    addFlexFlowStatus = () => {
        const { customer, statusToAdd } = this.state;

        if (statusToAdd) {
            if (customer.statuses) {
                customer.statuses.push(statusToAdd);
            } else {
                customer.statuses = [statusToAdd];
            }
        }

        this.setState({ customer });
    }

    removeFlexFlowStatus = (statusId: number) => {
        const { customer } = this.state;

        if (customer.statuses) {
            customer.statuses = customer.statuses.filter(o => o !== statusId);
        }

        this.setState({ customer });
    }

    onSortEnd = (collection: any) => {
        const { customer } = this.state;
        let { statuses } = customer;

        if (statuses) {
            statuses = arrayMove(statuses, collection.oldIndex, collection.newIndex);
        };

        customer.statuses = statuses;
        this.setState({ customer });
    }

    renderAddress = () => {
        const { auth, t } = this.props;
        const { customer, customers } = this.state;
        const isCustomer = auth && ![1, 2, 7, 8].includes(auth.role_id);

        return (<>
            <Segment>
                <Header as="h3">{t('debtors.address')}</Header>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <Form.Input
                            label="Straat"
                            name="street"
                            value={customer.street || ''}
                            onChange={this.handleInput}
                            readOnly={isCustomer}
                        />
                    </div>
                    <Form.Input
                        label="Huisnr."
                        name="house_number"
                        value={customer.house_number || ''}
                        onChange={this.handleInput}
                        style={{ width: 60, margin: '0 5px' }}
                        readOnly={isCustomer}
                    />
                    <Form.Input
                        label={t('customers.suffix')}
                        name="house_number_suffix"
                        value={customer.house_number_suffix || ''}
                        onChange={this.handleInput}
                        style={{ width: 60 }}
                        readOnly={isCustomer}
                    />
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '25%', marginRight: 5 }}>
                        <Form.Input
                            label={t('debtors.zipcode')}
                            name="zipcode"
                            value={customer.zipcode || ''}
                            onChange={this.handleInput}
                            readOnly={isCustomer}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <Form.Input
                            label={t('debtors.city')}
                            name="city"
                            value={customer.city || ''}
                            onChange={this.handleInput}
                            readOnly={isCustomer}
                        />
                    </div>
                </div>
            </Segment>
            <Segment>
                <Header as="h3">{t('customers.contact')}</Header>
                <Form.Input
                    label={t('customers.contact_name')}
                    name="contact_name"
                    value={customer.contact_name || ''}
                    onChange={this.handleInput}
                    readOnly={isCustomer}
                />
                <Form.Input
                    label={t('customers.contact_email')}
                    name="contact_email_address"
                    value={customer.contact_email_address || ''}
                    onChange={this.handleInput}
                    readOnly={isCustomer}
                />
                <Form.Input
                    label={t('customers.contact_phone')}
                    name="contact_telephone_number"
                    value={customer.contact_telephone_number || ''}
                    onChange={this.handleInput}
                    readOnly={isCustomer}
                />
            </Segment>
        </>)
    }

    // render
    render = () => {
        const { auth, t } = this.props;
        const { customer, isLoading, errors, resellers, tab, hasSupport, statuses, users, customers } = this.state;

        if (!resellers || !auth) {
            return null;
        }

        const isCustomer = auth && ![1, 2, 7, 8].includes(auth.role_id);
        const isSuper = auth && auth.role_id === 1;

        const customerType = customer.type || (auth && auth.role_id === 7 ? 2 : 1);

        const userOptions = users.map((u, i) => ({
            key: `user-${i}`,
            value: u.id,
            text: u.full_name,
        }));
        userOptions.unshift({
            key: `user-0`,
            value: 0,
            text: 'Geen',
        });

        return (
            <div className="pageContainer">
                {isCustomer ? (<PageHeader title="" />) : (<PageHeader title={`${customer.id ? 'Klant wijzigen' : 'Nieuwe klant'}`}>
                    <DomainLink to="/customers" primary>{t('general.back')}</DomainLink>
                </PageHeader>)}

                {customer.id && <Menu pointing>
                    {[1, 2, 7].includes(auth.role_id) && (<>
                        <Menu.Item
                            name="Dossier"
                            active={tab === 4}
                            onClick={() => this.setState({ tab: 4 })}
                        />
                    </>)}
                    <Menu.Item
                        name={t('invoices.info')}
                        active={tab === 0}
                        onClick={() => this.setState({ tab: 0 })}
                    />
                    {[1, 2, 7].includes(auth.role_id) && (<>
                        <Menu.Item
                            name="Contactpersonen"
                            active={tab === 1}
                            onClick={() => this.setState({ tab: 1 })}
                        />
                    </>)}
                    {!isCustomer && ![2, 7].includes(auth.role_id) && <Menu.Item
                        name="Templates"
                        active={tab === 2}
                        onClick={() => this.setState({ tab: 2 })} 
                    />}
                    {!isCustomer && ![2, 7].includes(auth.role_id) && customer.has_flex_template && <Menu.Item
                        name="Flex templates"
                        active={tab === 3}
                        onClick={() => this.setState({ tab: 3 })} 
                    />}
                </Menu>}

                {tab === 0 && (
                    <>
                    <Form onSubmit={() => this.save()} loading={isLoading}>
                        <Grid columns="equal">
                            <Grid.Row>
                                <Grid.Column>
                                    <Segment>
                                        <Header as="h3">{t('customers.general')}</Header>
                                        {!isCustomer && <Form.Select
                                            error={errors && errors.reseller_id}
                                            label="Type"
                                            options={[{
                                                key: `prospect`,
                                                value: 2,
                                                text: 'Prospect',
                                            }, {
                                                key: `klant`,
                                                value: 1,
                                                text: 'Klant',
                                            }]}
                                            required
                                            name="type"
                                            selectOnBlur={false}
                                            value={customerType}
                                            onChange={this.handleInput}
                                        />}
                                        {!isCustomer && ![2, 7].includes(auth.role_id) && <Form.Select
                                            error={errors && errors.reseller_id}
                                            label="White Label"
                                            options={resellers.map((r) => ({
                                                key: `reseller-${r.id}`,
                                                value: r.id,
                                                text: r.name,
                                            }))}
                                            required
                                            name="reseller_id"
                                            placeholder={t('customers.select_white_label')}
                                            selectOnBlur={false}
                                            value={customer.reseller_id}
                                            onChange={this.handleInput}
                                        />}
                                        {customerType === 1 && !isCustomer && (<Form.Select
                                            label={t('customers.account_owner')}
                                            options={userOptions}
                                            name="account_owner_id"
                                            selectOnBlur={false}
                                            value={customer.account_owner_id || 0}
                                            onChange={this.handleInput}
                                        />)}
                                        {errors && errors.reseller_id && <p className="formError">{errors.reseller_id}</p>}
                                        {customerType === 1 && !isCustomer && (<Form.Select
                                            label="Standaard taal"
                                            options={[{
                                                key: 'lang-nl',
                                                text: 'Nederlands',
                                                value: 'nl',
                                            }, {
                                                key: 'lang-gb',
                                                text: 'Engels',
                                                value: 'gb',
                                            }, {
                                                key: 'lang-de',
                                                text: 'Duits',
                                                value: 'de',
                                            }]}
                                            name="default_lang"
                                            selectOnBlur={false}
                                            value={customer.default_lang || 'nl'}
                                            onChange={this.handleInput}
                                        />)}
                                        <Form.Input
                                            label={t('customers.number')}
                                            readOnly
                                            name="number"
                                            value={customer.number}
                                        />
                                        <Form.Input
                                            error={errors && errors.name !== undefined}
                                            label={t('customers.company_name')}
                                            required={!isCustomer}
                                            name="name"
                                            value={customer.name}
                                            onChange={this.handleInput}
                                            readOnly={isCustomer}
                                        />
                                        {errors && errors.name && <p className="formError">{errors.name}</p>}
                                    </Segment>
                                    {customerType === 1 && this.renderAddress()}
                                    {customerType === 1 && (<Segment>
                                        <Header as="h3">{t('customers.settings')}</Header>
                                        <Form.Group style={{ marginLeft: 0 }}>
                                            <Checkbox
                                                toggle
                                                name="daily_sync"
                                                label={t('customers.daily_sync')}
                                                checked={customer.daily_sync}
                                                onChange={this.handleInput}
                                                readOnly={isCustomer}
                                            />
                                        </Form.Group>
                                        <Form.Group style={{ marginLeft: 0 }}>
                                            <Checkbox
                                                toggle
                                                name="combine_claim_box"
                                                label={t('customers.collect')}
                                                checked={customer.combine_claim_box}
                                                onChange={this.handleInput}
                                                readOnly={isCustomer}
                                            />
                                        </Form.Group>
                                        <Form.Group style={{ marginLeft: 0 }}>
                                            <Checkbox
                                                toggle
                                                name="reset_incasso"
                                                label={t('customers.reset_incasso')}
                                                checked={customer.reset_incasso}
                                                onChange={this.handleInput}
                                                readOnly={isCustomer}
                                            />
                                        </Form.Group>
                                        <Form.Group style={{ marginLeft: 0 }}>
                                            <Checkbox
                                                toggle
                                                name="has_flex_template"
                                                label={t('customers.use_flex')}
                                                checked={customer.has_flex_template}
                                                onChange={this.handleInput}
                                                readOnly={isCustomer}
                                            />
                                        </Form.Group>
                                        <Form.Input
                                            label={t('customers.actioninterval')}
                                            name="action_interval"
                                            type="number"
                                            value={customer.action_interval}
                                            onChange={this.handleInput}
                                            readOnly={isCustomer}
                                        />
                                        <Form.Input
                                            label="Administratiekosten"
                                            name="admin_costs"
                                            value={customer.admin_costs}
                                            onChange={this.handleInput}
                                            style={{ width: 100 }}
                                            readOnly={isCustomer}
                                        />
                                        <Form.Input
                                            label={t('customers.intresest_perc')}
                                            labelPosition="right"
                                            name="interest_rate"
                                            type="number"
                                            value={customer.interest_rate}
                                            onChange={this.handleInput}
                                            style={{ width: 90 }}
                                            readOnly={isCustomer}
                                        >
                                            <input max="100" min="0" />
                                            <Label basic>%</Label>
                                        </Form.Input>
                                        <div style={{ display: 'flex', marginBottom: 12 }}>
                                        <Form.Select
                                            label={t('customers.cost_type')}
                                            options={[{
                                                key: 'it-costs',
                                                value: 'costs',
                                                text: t('customers.amount')
                                            },{
                                                key: 'it-perc',
                                                value: 'perc',
                                                text: t('customers.percentage')
                                            },{
                                                key: 'it-wik',
                                                value: 'wik',
                                                text: t('customers.wik')
                                            }]}
                                            name="incasso_type"
                                            selectOnBlur={false}
                                            value={customer.incasso_type}
                                            onChange={this.handleInput}
                                            disabled={isCustomer}
                                        />
                                        <Form.Input
                                            label={customer.incasso_type === 'perc' ? t('customers.percentage') : (customer.incasso_type === 'wik' ? t('customers.min_amount') : t('invoices.amount'))}
                                            name="incasso_value"
                                            value={customer.incasso_value}
                                            onChange={this.handleInput}
                                            style={{ width: 90, marginLeft: 10 }}
                                            readOnly={isCustomer}
                                        />
                                        {customer.incasso_type === 'perc' && <>
                                            <Form.Input
                                                label={t('customers.min_amount')}
                                                name="interest_min"
                                                value={customer.interest_min}
                                                onChange={this.handleInput}
                                                style={{ width: 90, marginLeft: 10 }}
                                                readOnly={isCustomer}
                                            />
                                            <Form.Input
                                                label={t('customers.max_amount')}
                                                name="interest_max"
                                                value={customer.interest_max}
                                                onChange={this.handleInput}
                                                style={{ width: 90, marginLeft: 10 }}
                                                readOnly={isCustomer}
                                            />
                                        </>}
                                        </div>
                                        <Form.Select
                                            label={t('customers.license_type')}
                                            options={[{
                                                key: 'lt-deb',
                                                value: 'deb',
                                                text: t('customers.debtoradmin')
                                            },{
                                                key: 'lt-inc',
                                                value: 'inc',
                                                text: t('customers.incassoadmin')
                                            },{
                                                key: 'lt-deb-inc',
                                                value: 'deb-inc',
                                                text: t('customers.debincadmin')
                                            },{
                                                key: 'lt-flex',
                                                value: 'flex',
                                                text: t('customers.flex_workflow')
                                            }]}
                                            name="license_type"
                                            selectOnBlur={false}
                                            value={customer.license_type}
                                            onChange={this.handleInput}
                                            disabled={isCustomer}
                                        />

                                        {customer.license_type === 'flex' && <div style={{ marginBottom: 15 }}>
                                            <div style={{ display: 'flex', alignItems: 'flex-end'  }}>
                                                <div style={{ flex: 1, marginRight: 5 }}>
                                                    <Form.Select
                                                        fluid
                                                        label={t('customers.add_status')}
                                                        options={statuses.map((s) => ({
                                                            key: `fx-status-${s.id}`,
                                                            value: s.id,
                                                            text: s.name,
                                                        }))}
                                                        onChange={(e: any, data: any) => this.setState({ statusToAdd: data.value })}
                                                    />
                                                </div>
                                                <Button basic icon="plus" type="button" onClick={this.addFlexFlowStatus} />
                                            </div>
                                            <SortableList onSortEnd={this.onSortEnd}>
                                                {customer.statuses && customer.statuses.map((s, index) => {
                                                    const status = find(statuses, { id: s });

                                                    if (!status) {
                                                        return null;
                                                    }

                                                    let val = customer.action_interval || 0;
                                                    if (customer.intervals && customer.intervals[index]) {
                                                        val = customer.intervals[index];
                                                    }

                                                    return <SortableItem
                                                        key={`sort-item-${status.id}-${index}`}
                                                        index={index}
                                                        value={{
                                                            ...status,
                                                            index,
                                                            interval: val,
                                                            removeFlexFlowStatus: this.removeFlexFlowStatus,
                                                            handleStatusInterval: this.handleStatusInterval,
                                                        }}
                                                    />
                                            })}
                                            </SortableList>
                                        </div>}

                                        {!isCustomer && <Form.Select
                                            fluid
                                            label="Status"
                                            options={[{
                                                key: `status-1`,
                                                value: true,
                                                text: t('customers.active'),
                                            }, {
                                                key: `status-2`,
                                                value: false,
                                                text: t('customers.inactive'),
                                            }]}
                                            name="is_active"
                                            selectOnBlur={false}
                                            value={customer.is_active}
                                            onChange={this.handleInput}
                                        />}
                                        <Form.Input
                                            label={t('customers.global_valuta')}
                                            name="currency"
                                            value={customer.currency || ''}
                                            onChange={this.handleInput}
                                            readOnly={isCustomer}
                                        />
                                        <Form.Input
                                            label="Twinfield Fax"
                                            name="twinfield_fax"
                                            value={customer.twinfield_fax || ''}
                                            onChange={this.handleInput}
                                            readOnly={isCustomer}
                                        />
                                        <Form.Input
                                            label={t('customers.twinfield_range')}
                                            name="twinfield_range"
                                            value={customer.twinfield_range || ''}
                                            onChange={this.handleInput}
                                            readOnly={isCustomer}
                                        />
                                        
                                        <Form.Group style={{ marginLeft: 0 }}>
                                            <Checkbox
                                                toggle
                                                name="send_cc"
                                                label={t('customers.cc')}
                                                checked={customer.send_cc}
                                                onChange={this.handleInput}
                                                readOnly={isCustomer}
                                            />
                                        </Form.Group>
                                        {customer.send_cc && (<>
                                            <Form.Input
                                                error={errors && errors.cc !== undefined}
                                                label={t('customers.cc_email')}
                                                name="cc"
                                                value={customer.cc || ''}
                                                onChange={this.handleInput}
                                                readOnly={isCustomer}
                                            />
                                            {errors && errors.cc && <p className="formError">{errors.cc}</p>}
                                        </>)}
                                    </Segment>)}
                                </Grid.Column>
                                <Grid.Column>
                                    {customerType === 2 && this.renderAddress()}
                                    {customerType === 1 && (<>
                                        <Segment>
                                            <Header as="h4">Logo</Header>
                                            <div className={styles.editLogo}>
                                                {customer.logo && <img src={customer.logo} alt="" />}
                                            </div>
                                            {!isCustomer && <Form.Input
                                                type="file"
                                                accept="image/*"
                                                fluid
                                                onChange={(e: any) => this.setMedia(e, 'logo')}
                                            />}
                                        </Segment>
                                        <Segment>
                                            <Header as="h3">{t('customers.accountno')}</Header>
                                            <Form.Input
                                                label="IBAN"
                                                name="bank_iban"
                                                value={customer.bank_iban || ''}
                                                onChange={this.handleInput}
                                                readOnly={isCustomer}
                                            />
                                            <Form.Input
                                                label={t('customers.accountname')}
                                                name="bank_name"
                                                value={customer.bank_name || ''}
                                                onChange={this.handleInput}
                                                readOnly={isCustomer}
                                            />
                                            <Form.Input
                                                label="BIC code"
                                                name="bank_bic"
                                                value={customer.bank_bic || ''}
                                                onChange={this.handleInput}
                                                readOnly={isCustomer}
                                            />    
                                        </Segment>
                                        <Segment>
                                            <Header as="h3">{t('customers.debtoradmin_email')}</Header>
                                            <Form.Input
                                                label={t('customers.sender_email')}
                                                name="debit_sender"
                                                value={customer.debit_sender || ''}
                                                onChange={this.handleInput}
                                                readOnly={isCustomer}
                                            />
                                            <Form.Input
                                                label={t('customers.sender_name')}
                                                name="debit_display_name"
                                                value={customer.debit_display_name || ''}
                                                onChange={this.handleInput}
                                                readOnly={isCustomer}
                                            />    
                                            <Form.Input
                                                label={t('templates.subject')}
                                                name="debit_subject"
                                                value={customer.debit_subject || ''}
                                                onChange={this.handleInput}
                                                readOnly={isCustomer}
                                            />
                                            <Form.Input
                                                label={t('customers.subject_rc')}
                                                name="debit_subject_courant"
                                                placeholder={customer.debit_subject || ''}
                                                value={customer.debit_subject_courant || ''}
                                                onChange={this.handleInput}
                                                readOnly={isCustomer}
                                            />
                                        </Segment>
                                        <Segment>
                                            <Header as="h3">{t('customers.incasso_email')}</Header>
                                            <Form.Input
                                                label={t('customers.sender_email')}
                                                name="incasso_sender"
                                                value={customer.incasso_sender || ''}
                                                onChange={this.handleInput}
                                                readOnly={isCustomer}
                                            />
                                            <Form.Input
                                                label={t('customers.sender_name')}
                                                name="incasso_display_name"
                                                value={customer.incasso_display_name || ''}
                                                onChange={this.handleInput}
                                                readOnly={isCustomer}
                                            />    
                                            <Form.Input
                                                label={t('templates.subject')}
                                                name="incasso_subject"
                                                value={customer.incasso_subject || ''}
                                                onChange={this.handleInput}
                                                readOnly={isCustomer}
                                            />
                                            <Form.Input
                                                label={t('customers.subject_rc')}
                                                name="incasso_subject_courant"
                                                placeholder={customer.incasso_subject || ''}
                                                value={customer.incasso_subject_courant || ''}
                                                onChange={this.handleInput}
                                                readOnly={isCustomer}
                                            />
                                        </Segment>
                                        <Segment>
                                            <Header as="h3">Support</Header>
                                            <Form.Group style={{ marginLeft: 0 }}>
                                                <Checkbox
                                                    toggle
                                                    label={t('customers.use_default')}
                                                    checked={!hasSupport}
                                                    onChange={(e: any, data: any) => this.setState({ hasSupport: !data.checked })}
                                                    readOnly={isCustomer}
                                                />
                                            </Form.Group>
                                            {hasSupport && (
                                                <>
                                                <Form.Input
                                                    label={t('customers.contact_name')}
                                                    name="support_name"
                                                    value={customer.support_name || ''}
                                                    onChange={this.handleInput}
                                                    required={!isCustomer}
                                                    readOnly={isCustomer}
                                                />
                                                <Form.Input
                                                    label={t('customers.contact_email')}
                                                    name="support_email_address"
                                                    value={customer.support_email_address || ''}
                                                    onChange={this.handleInput}
                                                    required={!isCustomer}
                                                    readOnly={isCustomer}
                                                />
                                                <Form.Input
                                                    label={t('customers.contact_phone')}
                                                    name="support_telephone_number"
                                                    value={customer.support_telephone_number || ''}
                                                    onChange={this.handleInput}
                                                    required={!isCustomer}
                                                    readOnly={isCustomer}
                                                />
                                                </>
                                            )}
                                        </Segment>
                                        {customer.id && <Segment>
                                            <Header as="h3">{t('customers.api_settings')}</Header>
                                            <Form.Input
                                                label={t('customers.personal_api_key')}
                                                value={auth ? auth.hash_id : ''}
                                                readOnly
                                            />
                                            <Divider />
                                            <Form.Select
                                                fluid
                                                label={t('customers.paymentprovider')}
                                                options={[{
                                                    key: `pp-1`,
                                                    value: '',
                                                    text: t('customers.none'),
                                                }, {
                                                    key: `pp2`,
                                                    value: 'twikey',
                                                    text: 'Twikey',
                                                }]}
                                                name="payment_provider"
                                                selectOnBlur={false}
                                                value={customer.payment_provider || ''}
                                                onChange={this.handleInput}
                                            />
                                            <Form.Input
                                                label={t('customers.paymentprovider_api_key')}
                                                name="payment_provider_key"
                                                value={customer.payment_provider_key || ''}
                                                onChange={this.handleInput}
                                                readOnly={isCustomer}
                                            />
                                        </Segment>}
                                    </>)}
                                    {!isCustomer && <Segment>
                                        <Header as="h3">Gekoppelde klanten</Header>
                                        <Form.Select
                                            error={errors && errors.reseller_id}
                                            label="Klant(en)"
                                            options={customers.filter((o) => customer.id !== o.id).map((c) => ({
                                                key: `cus-${c.id}`,
                                                value: c.id,
                                                text: c.name,
                                            }))}
                                            required
                                            multiple
                                            name="linked"
                                            selectOnBlur={false}
                                            value={customer.linked}
                                            onChange={this.handleInput}
                                        />
                                    </Segment>}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                    {!isCustomer && <FloatingButtons
                        onCancel="/customers"
                        onSave={() => this.save()}
                        onSaveExit={() => this.save(true)}
                    />}
                </>)}
                {/* {tab === 1 && customer.id && <ContractsListView customerId={customer.id} />} */}
                {tab === 1 && customer.id && <ContactsListView customerId={customer.id} />}
                {!isCustomer && tab === 2 && customer.id && <TemplatesListView customerId={customer.id} />}
                {!isCustomer && tab === 3 && customer.id && <TemplatesListView customerId={customer.id} isFlex={true} />}
                {!isCustomer && tab === 4 && customer.id && <DossierView customerId={customer.id} />}
            </div>
        );
    }
}

export default withTranslation('common')(connect((state: AppState) => ({
    auth: state.auth.user,
}))(CustomersEditView));
