import React from 'react';
import { Pagination } from 'semantic-ui-react';

interface DefaultPaginationProps {
    data?: any,
    onChange: (page: number, filter?: any) => void,
}

class DefaultPagination extends React.Component<DefaultPaginationProps> {
    // --- render
    render() {
        const { data, onChange } = this.props;

        if (!data) {
            return null;
        }
        
        return (
            <div>
                <Pagination
                    defaultActivePage={data.current_page}
                    totalPages={data.last_page}
                    siblingRange={2}
                    firstItem={false}
                    lastItem={false}
                    onPageChange={(e: any, data: any) => {
                        if (onChange) {
                            onChange(data.activePage);
                        }
                    }}
                />
                <span style={{ marginLeft: 10 }}>{data.total} item{data.total === 1 ? '' : 's'}</span>
            </div>
        );
    }
}

export default DefaultPagination
