import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import Toastify from 'toastify';
import api from '../../api';
import { ApiAuthObject } from '../../api/auth';
import { ApiContactObject } from '../../api/contacts';
import AdvancedTable, { AdvancedTableAction } from '../../components/AdvancedTable';
import { AppState } from '../../store';
import ContactEditView from './editContact';

interface ContactsListViewProps {
    customerId: number,
    auth?: ApiAuthObject;
}

interface ContactsListViewState {
    edit: boolean | number,
    contacts: ApiContactObject[],
    pagination?: any,
    isLoading: boolean,
}

const headers = [{
    id: 'name',
    title: 'Naam',
}, {
    id: 'telephone_number',
    title: 'Tel.nr.',
}, {
    id: 'email_address',
    title: 'E-mailadres',
}];

class ContactsListView extends React.Component<ContactsListViewProps, ContactsListViewState> {
    constructor(props: any) {
        super(props);
    
        this.state = {
            edit: false,
            contacts: [],
            pagination: undefined,
            isLoading: false,
        };
    }

    /**
     * Mount
     */
    componentDidMount = () => {
        this.fetch();
    }

    /**
     * Delete contact
     */
    deleteContact = (id: number) => {
        api.deleteContact(id).then(() => {
            Toastify.success('Contactpersoon succesvol verwijderd');
            this.fetch();
        });
    }

    /**
     * Fetch
     */
    fetch = (filter?: any) => {
        const { customerId } = this.props;
        const customer = { customer_id: customerId || 0, page: 1 };

        api.listContacts(filter ? { ...filter, ...customer } : customer).then(({ data }) => {
            this.setState({
                contacts: data.data,
            });
        })
    }

    // render
    render = () => {
        const { auth, customerId } = this.props;
        const { pagination, contacts, isLoading, edit } = this.state;

        return (
            <div className="pageContainer">
                {edit ? (
                    <ContactEditView
                        customerId={customerId}
                        contactId={edit === true ? undefined : edit}
                        onExit={() => this.setState({ edit: false }, this.fetch)}
                    />
                ) : (
                    <AdvancedTable
                        name="contact-index"
                        items={contacts}
                        header={headers}
                        renderRow={(item: ApiContactObject) => ({
                            cells: [{
                                header: 'name',
                                render: item.name,
                            }, {
                                header: 'telephone_number',
                                render: item.telephone_number,
                            }, {
                                header: 'email_address',
                                render: item.email_address,
                            }],
                            actions: auth && [1, 2, 7].includes(auth.role_id) ? [
                                <AdvancedTableAction
                                    key={`action-1-${item.id}`}
                                    iconName="pencil"
                                    onClick={() => this.setState({ edit: item.id })}
                                    text="Wijzigen"
                                />,
                                <AdvancedTableAction key={`action-2-${item.id}`} divider />,
                                <AdvancedTableAction 
                                    key={`action-6-${item.id}`}
                                    iconName="trash alternate outline"
                                    text="Verwijderen"
                                    onConfirm={() => this.deleteContact(item.id)}
                                    confirmContent="Weet u zeker dat u deze contactpersoon wilt verwijderen?"
                                />,
                            ] : []
                        })}
                        onFilter={this.fetch}
                        isLoading={isLoading}
                        pagination={pagination}
                    >
                        {auth && [1, 2, 7].includes(auth.role_id) && (
                            <Button
                                onClick={() => this.setState({ edit: true })}
                                primary
                            >
                                Contactpersoon toevoegen
                            </Button>
                        )}
                    </AdvancedTable>
                )}
            </div>
        );
    }
}

export default connect((state: AppState) => ({
   auth: state.auth.user,
}))(ContactsListView);
