import React from 'react';
import { Button } from 'semantic-ui-react';
import Toastify from 'toastify';
import api from '../../api';
import { ApiResellerObject } from '../../api/resellers';
import AdvancedTable, { AdvancedTableAction, FilterObject } from '../../components/AdvancedTable';
import DomainLink from '../../components/DomainLink';
import PageHeader from '../../components/Layout/PageHeader';

interface ResellersListViewState {
    resellers: ApiResellerObject[],
    pagination?: any,
    isLoading: boolean,
}

class ResellersListView extends React.Component<{}, ResellersListViewState> {
    constructor(props: any) {
        super(props);
    
        this.state = {
            resellers: [],
            pagination: undefined,
            isLoading: false,
        };
    }

    /**
     * Fetch
     */
    fetch = (filter?: FilterObject) => {
        this.setState({ isLoading: true });

        api.listResellers(filter).then(({ data }) => {
            this.setState({
                resellers: data.data,
                pagination: data.meta,
                isLoading: false,
            });
        });
    }

    /**
     * Delete reseller
     */
    deleteReseller = (id: number) => {
        api.deleteReseller(id).then(() => {
            Toastify.success('Reseller succesvol verwijderd');
            this.fetch();
        });
    }

    // render
    render = () => {
        const { pagination, resellers, isLoading } = this.state;

        return (
            <div className="pageContainer">
                <PageHeader title="White Labels">
                    <DomainLink to="/resellers/create">
                        <Button primary>White Label toevoegen</Button>
                    </DomainLink>
                </PageHeader>

                <AdvancedTable
                    name="reseller-index"
                    items={resellers}
                    header={[{
                        id: 'name',
                        title: 'Naam',
                        sortable: 'name',
                    }, {
                        id: 'domain',
                        title: 'Domein',
                        sortable: 'domain',
                    }, {
                        id: 'colors',
                        title: 'Kleuren'
                    }]}
                    renderRow={(item: ApiResellerObject) => ({
                        cells: [{
                            header: 'name',
                            render: <DomainLink primary to={`/resellers/${item.id}/edit`}>{item.name}</DomainLink>,
                        }, {
                            header: 'domain',
                            render: item.domain,
                        }, {
                            header: 'colors',
                            cellProps: { collapsing: true },
                            render: (<div>
                                <span style={{ display: 'inline-block', marginRight: 5, height: 15, width: 15, background: item.primary_color, borderRadius: '100%' }} />
                                <span style={{ display: 'inline-block', height: 15, width: 15, background: item.secondary_color, borderRadius: '100%' }} />
                            </div>),
                        }],
                        actions: [
                            <AdvancedTableAction 
                                key={`action-1-${item.id}`}
                                to={`/resellers/${item.id}/edit`}
                                iconName="pencil"
                                text="Wijzigen"
                            />,
                            <AdvancedTableAction key={`action-2-${item.id}`} divider />,
                            <AdvancedTableAction 
                                key={`action-3-${item.id}`}
                                iconName="trash alternate outline"
                                text="Verwijderen"
                                onConfirm={() => this.deleteReseller(item.id)}
                                confirmContent="Weet u zeker dat u dit White Label wilt verwijderen? Alle gekoppelde informatie zal niet meer beschikbaar zijn en gebruikers kunnen niet meer inloggen."
                            />,
                        ]
                    })}
                    onFilter={this.fetch}
                    isLoading={isLoading}
                    pagination={pagination}
                />
            </div>
        );
    }
}

export default ResellersListView;
