import { AxiosPromise } from 'axios';
import { request } from './index';

export interface ApiTemplateObject {
    id: number,
    customer_id: number,
    type: string,
    name: string,
    subject?: string,
    content: string,
    content_en?: string,
    has_header: boolean,
    has_footer: boolean,
    is_flex: boolean,
    flex_type?: string,
    sender?: string,
    sender_name?: string,
}

export default {
    delete: (id: number | string): AxiosPromise => {
        return request.delete(`templates/${id}`);
    },
    list: (customerId?: number | string, isFlex?: boolean): AxiosPromise => {
        return request.get(`templates?customer_id=${customerId || ''}&flex=${isFlex === true ? 1 : 0}`);
    },
    store: (data: any, customerId?: number): AxiosPromise<ApiTemplateObject> => {
        return request.post('templates', {
            ...data,
            customer_id: customerId,
        });
    },
    patch: (id: number | string, data: any, customerId?: number): AxiosPromise<ApiTemplateObject> => {
        return request.patch(`templates/${id}`, {
            ...data,
            customer_id: customerId,
        });
    },
    show: (id: number | string, customerId?: number): AxiosPromise<ApiTemplateObject> => {
        return request.get(`templates/${id}?customer_id=${customerId || ''}`);
    },
};
