import { AxiosPromise } from 'axios';
import { each } from 'lodash';
import { request } from './index';
import { FilterObject } from '../components/AdvancedTable';

export interface ApiContactObject {
    id: number,
    customer_id: number,
    type: string,
    name: string,
    telephone_number: string,
    email_address: string,
}

export default {
    delete: (id: number | string): AxiosPromise => {
        return request.delete(`contacts/${id}`);
    },
    list: (filter?: Partial<FilterObject>, include?: string[]): AxiosPromise => {
        const query: any = [];

        if (filter) {
            each(filter, (v, k) => {
                query.push(`${k}=${v}`);
            });
        }

        return request.get(`contacts?${query.join('&')}${include ? `&with=${include.join(',')}` : ''}`);
    },
    store: (data: any): AxiosPromise<ApiContactObject> => {
        return request.post(`contacts`, data);
    },
    patch: (id: number | string, data: any): AxiosPromise<ApiContactObject> => {
        return request.post(`contacts/${id}`, data);
    },
    show: (id: number | string): AxiosPromise<ApiContactObject> => {
        return request.get(`contacts/${id}`);
    },
};
