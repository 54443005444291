import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, Segment, Icon, SemanticICONS } from 'semantic-ui-react';
import { WithTranslation, withTranslation, WithTranslationProps } from 'react-i18next';
import styles from './styles.module.scss';
import { ApiTimelineObject } from '../../api/invoices';
import { ApiAuthObject } from '../../api/auth';
import { AppState } from '../../store';
import api from '../../api';
import { money } from '../../lib/utils';

interface TimelineProps {
    auth?: ApiAuthObject,
    data: ApiTimelineObject[],
    onDelete: () => void,
}

interface TimelineState {
    confirm: any
}

class Timeline extends React.Component<TimelineProps & WithTranslation, TimelineState> {
    constructor (props: TimelineProps & WithTranslation) {
        super(props);

        this.state = {
            confirm: false,
        };
    }

    /**
     * Delete item
     */
    deleteTimeline = (timeline: ApiTimelineObject) => {
        api.deleteTimeline(timeline.invoice_id, timeline.id).then(() => {
            this.setState({ confirm: false });
            this.props.onDelete()
        });
    }

    // render
    render = () => {
        const { auth, t } = this.props;
        
        return (
            <div className={styles.timeline}>
                <div className={styles.timelineLine}></div>
                {this.props.data.map((timeline, i) => (
                    <Segment className={styles.timelineItem} key={`timeline-${i}`}>
                        <div className={styles.timelineHeader}>
                            <Icon name={timeline.icon as SemanticICONS} style={{ fontSize: 30 }} />
                            <div className={styles.timelineMeta}>
                                {timeline.user.full_name} | {moment(timeline.created_at).format('DD/MM/YYYY - HH.mm')}
                            </div>
                            {timeline.has_file && timeline.download_url && (
                                <a className="ui basic icon button" href={timeline.download_url} target="_blank" style={{ marginTop: -11, position: 'relative' }} rel="noopener noreferrer">
                                    <Icon name="download" />
                                </a>
                            )}
                            {auth && timeline.user_id === auth.id && timeline.type === 'note' && (<>
                                <Button
                                    basic
                                    icon="trash"
                                    style={{ boxShadow: 'none', position: 'relative', top: -4 }}
                                    onClick={() => this.setState({ confirm: timeline })}
                                    type="button"
                                />
                                <Confirm
                                    open={this.state.confirm ? true : false}
                                    header="Weet u het zeker?"
                                    onCancel={() => this.setState({ confirm: false })}
                                    onConfirm={() => this.deleteTimeline(this.state.confirm)}
                                    content="Weet u zeker dat u item wilt verwijderen?"
                                    cancelButton="Annuleren"
                                    confirmButton="Ik weet het zeker"
                                />
                            </>)}
                        </div>
                        {timeline.content && <p>{timeline.content.replace('Status gewijzigd naar', t('status.changed_to')).replace('Handmatig naar de actielijst gezet', t('status.manual_change'))}</p>}
                        {timeline.interest && <div style={{ marginRight: 10 }}>
                            Rente: {money(timeline.interest)}
                        </div>}
                        {timeline.incasso_costs && <div style={{ marginRight: 10 }}>
                            Incassokosten: {money(timeline.incasso_costs)}
                        </div>}
                        {timeline.filename && <span style={{ display: 'block' }}>{timeline.filename}</span>}
                    </Segment>
                ))}
            </div>
        );
    }
}

export default withTranslation('common')(connect((state: AppState) => ({
    auth: state.auth.user,
}))(Timeline));
